<template>
  <div class="card-pontuacao">
    <div
        class="pontuacao"
        :class="points === 2 ? 'pontuacao-azul' : points === 1 ? 'pontuacao-laranja' : points === 0 && !isOverDate ? 'pontuacao-amarelo' : 'pontuacao-vermelho'">
      <div :class="isOverDate ? 'pontuacao-rate' : 'pontuacao-rate-no-points'">
        <span v-if="isOverDate" class="pontuacao-text">Pontuação:</span>
        <span v-if="isOverDate" class="pontuacao-number">{{ points }}</span>
      </div>

      <div
          class="status"
          :class="points === 2 ? 'status-positivo'
          : points === 1
            ? 'status-atrasado'
            : points === 0 && !isOverDate
              ? 'status-progresso'
              : 'status-negativo'"
      >
        <span v-if="points === 2">Atividades concluídas dentro do prazo</span>
        <span v-if="points === 1">Atividade(s) concluída(s) fora do prazo</span>
        <span v-if="points === 0 && !isOverDate">Atividade(s) em progresso</span>
        <span v-if="points === 0 && isOverDate">Atividade(s) em atraso</span>
      </div>
    </div>

    <div class="regras-pontuacao">
      <span>Regras de pontuação:</span>
      <ul>
        <li class="regras-pontos">0 pontos - Há uma ou mais atividades a serem entregues </li>
        <li class="regras-pontos">
          1 ponto - Uma ou mais atividades foram concluídas fora do prazo estipulado
        </li>
        <li class="regras-pontos">
          2 pontos - Todas as atividades conluídas dentro do prazo
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pontuacao",
  props: ["points", "isOverDate"],
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="sass" scoped>
.card-pontuacao
  background-color: #e9e9e9

.pontuacao
  border-radius: 4px
  color: #f9f9f9

.pontuacao-azul
  background-color: #1cabe2

.pontuacao-laranja
  background-color: #f26a21

.pontuacao-vermelho
  background-color: #e2231a

.pontuacao-amarelo
  background-color: #ffc313

.pontuacao-text
  line-height: 2
  margin-top: 8px

.pontuacao-rate
  height: 40px
  line-height: 1.2

.pontuacao-rate-no-points
  height: 13px

.pontuacao-number
  color: #f9f9f9
  font-size: 32px
  margin-left: 8px

.status
  border-radius: 4px
  padding: 10px

.status-positivo
  background-color: #ffffff
  border: 3px solid #1cabe2
  color: #1cabe2

.status-negativo
  background-color: #ffffff
  border: 3px solid #e2231a
  color: #e2231a

.status-progresso // @to-do: Alterar quando for definida a cor correta
  background-color: #ffffff
  border: 3px solid #ffc313
  color: #4A4A4A

.status-atrasado
  background-color: #ffffff
  border: 3px solid #f26a21
  color: #f26a21

.regras-pontuacao
  color: #4a4a4a
  font-size: 11px
  line-height: 1.5
  margin: 15px 10px 0
  padding-bottom: 8px
  text-align: left
  @media screen and (max-width: 1108px)
    display: none
  & ul > li
    list-style: none

.regras-pontos
  margin-top: 8px
</style>
