<template>
  <div class="atividades-relacionadas-page">
    <div class="atividades-relacionadas">
      <h1 class="atividades-relacionadas-title">Atividades relacionadas</h1>

      <div class="cards">
        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="true"
          :checkpointsCardType="false"

          :cardPoints="cardUm.cardPoints"
          :cardTitle="cardUm.cardTitle"
          :cardPrimaryTextFirstBlock="cardUm.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardUm.progressUpdatedIn"
          :cardDate="cardUm.cardDate"
          
          :linkTitleUmCard="cardUm.linkTitleUm"
          :linkUmCard="cardUm.linkUm"

          :linkTitleDoisCard="cardUm.linkTitleDois"
          :linkDoisCard="cardUm.linkDois"

          :linkTitleTresCard="cardUm.linkTitleTres"
          :linkTresCard="cardUm.linkTres"

          :evaluationValueUm="cardUm.evaluationValueUm"
          :evaluationValueQuatro="cardUm.evaluationValueQuatro"

          :evaluationGoalUm="cardUm.evaluationGoalUm"

          :evaluationTextUm="cardUm.evaluationTextUm"

          :evaluationDateLimitUm="cardUm.evaluationDateLimitUm"
        />

        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardDois.cardPoints"
          :cardTitle="cardDois.cardTitle"
          :cardPrimaryTextFirstBlock="cardDois.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardDois.progressUpdatedIn"
          :cardDate="cardDois.cardDate"
          
          :linkTitleUmCard="cardDois.linkTitleUm"
          :linkUmCard="cardDois.linkUm"
        />

        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardTres.cardPoints"
          :cardTitle="cardTres.cardTitle"
          :cardPrimaryTextFirstBlock="cardTres.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardTres.progressUpdatedIn"
          :cardDate="cardTres.cardDate"
          
          :linkTitleUmCard="cardTres.linkTitleUm"
          :linkUmCard="cardTres.linkUm"
        />
      </div>

      <div class="hide-content">
        <DotsLegends />
      </div>
    </div>
  </div>
</template>

<script>
import CardResultadoSistemico from "./resultados-sistemicos/CardResultadoSistemico.vue"
import DotsLegends from "./dots-legends.vue"

export default {
  name: "AtividadesRelacionadasRS05",
  props: ["dataCards"],
  components: { CardResultadoSistemico, DotsLegends },
  data() {
    return {
      dataCard: null,

      cardUm: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,

        linkTitleDois: null,
        linkDois: null,

        linkTitleTres: null,
        linkTres: null,
        
        evaluationValueUm: null,
        evaluationValueQuatro: null,

        evaluationGoalUm: null,

        evaluationTextUm: null,

        evaluationDateLimitUm: null,
      },

      cardDois: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,
      },

      cardTres: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,
      },
    };
  },
  watch: {
    dataCards: {
      immediate: true, 
      handler (value) {
        if (value) {
          this.dataCard = value;
          this.setDataCardUm();
          this.setDataCardDois();
          this.setDataCardTres();
        }
      }
    }
  },
  methods: {
    setDataCardUm() {
      this.cardUm.cardPoints = this.dataCard?.general_data?.health_development.points ?? 0;
      this.cardUm.cardTitle = "Profissionais certificados nos cursos de Saúde Mental";
      this.cardUm.cardPrimaryTextFirstBlock = "Certificar pelo menos 4 profissionais das áreas de Saúde, Assistência Social, Educação e outras áreas capacitados em Saúde Mental em uma abordagem intersetorial.";
      this.cardUm.progressUpdatedIn = "diariamente";
      this.cardUm.cardDate = "30/06/2022";
      
      this.cardUm.linkTitleUm = "Curso 1";
      this.cardUm.linkUm = "https://ava.unicef.org.br/course/view.php?id=12";

      this.cardUm.linkTitleDois = "Curso 2";
      this.cardUm.linkDois = "https://ava.unicef.org.br/course/view.php?id=15";

      this.cardUm.linkTitleTres = "Curso 3";
      this.cardUm.linkTres = "https://asecbrasil-ead.org.br/mod/page/view.php?id=5";

      this.cardUm.evaluationValueUm = this.dataCard?.general_data?.health_development.total ?? 0;
      this.cardUm.evaluationValueQuatro = 0;

      this.cardUm.evaluationGoalUm = 4;

      this.cardUm.evaluationTextUm = "Saúde Mental";

      this.cardUm.evaluationDateLimitUm = "30/06/2022";
    },

    setDataCardDois() {
      this.cardDois.cardPoints = this.dataCard?.general_data?.health_care_course_work.points;
      this.cardDois.cardTitle = "Elaboração do fluxo de atendimento em Saúde Mental";
      this.cardDois.cardPrimaryTextFirstBlock = "Definir intersetorialmente (Saúde, Educação e Assistência Social) o fluxo de atendimento dos casos em Saúde Mental que vão demandar atenção especializada.";
      this.cardDois.progressUpdatedIn = "diariamente";
      this.cardDois.cardDate = "31/08/2022";
      
      this.cardDois.linkTitleUm = "Abrir Atividade";
      this.cardDois.linkUm = "https://classroom.google.com/";
    },

    setDataCardTres() {
      this.cardTres.cardPoints = this.dataCard?.general_data?.psychosocial_course_work.points;
      this.cardTres.cardTitle = "Criação do Serviço de Apoio Psicossocial";
      this.cardTres.cardPrimaryTextFirstBlock = "Implementar um serviço de apoio psicossocial às crianças e aos adolescentes ou referenciar as demandas ao serviço existente no município ou em município próximo, neste caso, facilitando o acesso sempre que necessário.";
      this.cardTres.progressUpdatedIn = "diariamente";
      this.cardTres.cardDate = "13/01/2023";
      
      this.cardTres.linkTitleUm = "Abrir Atividade";
      this.cardTres.linkUm = "https://classroom.google.com/";
    },
  }
};
</script>

<style lang="sass">
.atividades-relacionadas-page
  margin: 3rem auto
  max-width: var(--max-width)

.atividades-relacionadas-title
  border-bottom: 3px solid #1cabe2
  color: #1cabe2
  display: inline-block
  font-size: 20px
  font-weight: 500

.cards
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  margin-top: 32px
  @media screen and (max-width: 1008px)
    display: flex
    justify-content: center
</style>
