<template>
  <header class="">
    <Menu />
  </header>
</template>

<script>
import Menu from "./components/Menu.vue";

export default {
  name: "Header",
  components: { Menu },
  data() {
    return {};
  },
};
</script>

<style lang="sass" scoped></style>
