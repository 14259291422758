<template>
  <div class="resumo-participacao-cidada-page">
    <div class="title">{{ title }}</div>

    <div class="resumo-participacao-cidada-top">
      <div class="resumo-participacao-cidada-card">

        <div v-if="!showPoints">
          <!-- <span class="material-icons md-18">remove_circle_outline</span> -->
          <img src="@/assets/lock.svg" />
          <div class="points hide-points">
            Pontuação geral visível a partir do segundo semestre de 2024
          </div>
          <div class="hide-points-border"></div>
        </div>

        <div v-if="showPoints" class="points">
          <div class="empty"></div>
          <div class="point">{{ points }}</div>
          <div class="text">pontos</div>
        </div>

        <div class="body">
          <div class="body-description">
            O município precisa atingir a meta de {{ pointsMeta }} pontos dentro de um total de {{ pointsTotal }} pontos.
          </div>
          <div class="body-bar" v-if="showPoints">
            <div v-if="type === 1" class="mark-points tipo-um"></div>
            <div v-if="type === 2" class="mark-points tipo-dois"></div>
            <div v-if="type === 3" class="mark-points tipo-tres"></div>
            <span></span>
            <div v-if="type === 1" class="progress" :class="progressStyle" :style="{ width: pointsWidth.blocoUm + '%' }"></div>
            <div v-if="type === 2" class="progress" :class="progressStyle" :style="{ width: pointsWidth.blocoDois + '%' }"></div>
            <div v-if="type === 3" class="progress" :class="progressStyle" :style="{ width: pointsWidth.blocoTres + '%' }"></div>
          </div>
        </div>
      </div>

      <div class="resumo-participacao-cidada-text">{{ text }}</div>
    </div>

    <div class="cards-expand" v-if="type === 1">
      <CardExpandParticipacaoCidada
          class="z-index-card-01"
          :cardName="participacaoCidadaCards.cardUm.cardName"
          :cardUmDate="participacaoCidadaCards.cardUm.cardUmDate"
          :cardPoints="participacaoCidadaCards.cardUm.cardPoints"
          :completeActivities="participacaoCidadaCards.cardUm.completeActivities"
          :amountActivities="participacaoCidadaCards.cardUm.amountActivities"

          :itemUmPoints="participacaoCidadaCards.cardUm.itemUmPoints"
          :itemUmTitle="participacaoCidadaCards.cardUm.itemUmTitle"
          :itemUmStatus="participacaoCidadaCards.cardUm.itemUmStatus"
          :itemUmDate="participacaoCidadaCards.cardUm.itemUmDate"

          :itemDoisPoints="participacaoCidadaCards.cardUm.itemDoisPoints"
          :itemDoisTitle="participacaoCidadaCards.cardUm.itemDoisTitle"
          :itemDoisStatus="participacaoCidadaCards.cardUm.itemDoisStatus"
          :itemDoisDate="participacaoCidadaCards.cardUm.itemDoisDate"

          :itemTresPoints="participacaoCidadaCards.cardUm.itemTresPoints"
          :itemTresTitle="participacaoCidadaCards.cardUm.itemTresTitle"
          :itemTresStatus="participacaoCidadaCards.cardUm.itemTresStatus"
          :itemTresDate="participacaoCidadaCards.cardUm.itemTresDate"
      />

      <CardExpandParticipacaoCidada
          class="z-index-card-02"
          :cardName="participacaoCidadaCards.cardDois.cardName"
          :cardDoisDate="participacaoCidadaCards.cardDois.cardDoisDate"
          :cardPoints="participacaoCidadaCards.cardDois.cardPoints"
          :completeActivities="participacaoCidadaCards.cardDois.completeActivities"
          :amountActivities="participacaoCidadaCards.cardDois.amountActivities"

          :itemUmPoints="participacaoCidadaCards.cardDois.itemUmPoints"
          :itemUmTitle="participacaoCidadaCards.cardDois.itemUmTitle"
          :itemUmStatus="participacaoCidadaCards.cardDois.itemUmStatus"
          :itemUmDate="participacaoCidadaCards.cardDois.itemUmDate"
      />

      <CardExpandParticipacaoCidada
          class="z-index-card-03"
          :cardName="participacaoCidadaCards.cardTres.cardName"
          :cardTresDate="participacaoCidadaCards.cardTres.cardTresDate"
          :cardPoints="participacaoCidadaCards.cardTres.cardPoints"
          :completeActivities="participacaoCidadaCards.cardTres.completeActivities"
          :amountActivities="participacaoCidadaCards.cardTres.amountActivities"

          :itemUmPoints="participacaoCidadaCards.cardTres.itemUmPoints"
          :itemUmTitle="participacaoCidadaCards.cardTres.itemUmTitle"
          :itemUmStatus="participacaoCidadaCards.cardTres.itemUmStatus"
          :itemUmDate="participacaoCidadaCards.cardTres.itemUmDate"

          :itemDoisPoints="participacaoCidadaCards.cardTres.itemDoisPoints"
          :itemDoisTitle="participacaoCidadaCards.cardTres.itemDoisTitle"
          :itemDoisStatus="participacaoCidadaCards.cardTres.itemDoisStatus"
          :itemDoisDate="participacaoCidadaCards.cardTres.itemDoisDate"

          :itemTresPoints="participacaoCidadaCards.cardTres.itemTresPoints"
          :itemTresTitle="participacaoCidadaCards.cardTres.itemTresTitle"
          :itemTresStatus="participacaoCidadaCards.cardTres.itemTresStatus"
          :itemTresDate="participacaoCidadaCards.cardTres.itemTresDate"
      />

      <CardExpandParticipacaoCidada
          class="z-index-card-04"
          :cardName="participacaoCidadaCards.cardQuatro.cardName"
          :cardQuatroDate="participacaoCidadaCards.cardQuatro.cardQuatroDate"
          :cardPoints="participacaoCidadaCards.cardQuatro.cardPoints"
          :completeActivities="participacaoCidadaCards.cardQuatro.completeActivities"
          :amountActivities="participacaoCidadaCards.cardQuatro.amountActivities"

          :itemUmPoints="participacaoCidadaCards.cardQuatro.itemUmPoints"
          :itemUmTitle="participacaoCidadaCards.cardQuatro.itemUmTitle"
          :itemUmStatus="participacaoCidadaCards.cardQuatro.itemUmStatus"
          :itemUmDate="participacaoCidadaCards.cardQuatro.itemUmDate"

          :itemDoisPoints="participacaoCidadaCards.cardQuatro.itemDoisPoints"
          :itemDoisTitle="participacaoCidadaCards.cardQuatro.itemDoisTitle"
          :itemDoisStatus="participacaoCidadaCards.cardQuatro.itemDoisStatus"
          :itemDoisDate="participacaoCidadaCards.cardQuatro.itemDoisDate"
      />
    </div>

    <div class="cards-expand" v-if="type === 2">
      <CardExpandResultadoSistemico
          class="z-index-card-01"
          :type="resultadosSistemicosCards.cardUm.type"
          :cardName="resultadosSistemicosCards.cardUm.cardName"
          :cardPoints="resultadosSistemicosCards.cardUm.cardPoints"
          :completeActivities="resultadosSistemicosCards.cardUm.completeActivities"
          :amountActivities="resultadosSistemicosCards.cardUm.amountActivities"

          :itemUmPoints="resultadosSistemicosCards.cardUm.itemUmPoints"
          :itemUmTitle="resultadosSistemicosCards.cardUm.itemUmTitle"
          :itemUmStatus="resultadosSistemicosCards.cardUm.itemUmStatus"
          :itemUmDate="resultadosSistemicosCards.cardUm.itemUmDate"

          :itemDoisPoints="resultadosSistemicosCards.cardUm.itemDoisPoints"
          :itemDoisTitle="resultadosSistemicosCards.cardUm.itemDoisTitle"
          :itemDoisStatus="resultadosSistemicosCards.cardUm.itemDoisStatus"
          :itemDoisDate="resultadosSistemicosCards.cardUm.itemDoisDate"

          :itemTresPoints="resultadosSistemicosCards.cardUm.itemTresPoints"
          :itemTresTitle="resultadosSistemicosCards.cardUm.itemTresTitle"
          :itemTresStatus="resultadosSistemicosCards.cardUm.itemTresStatus"
          :itemTresDate="resultadosSistemicosCards.cardUm.itemTresDate"

          :itemQuatroPoints="resultadosSistemicosCards.cardUm.itemQuatroPoints"
          :itemQuatroTitle="resultadosSistemicosCards.cardUm.itemQuatroTitle"
          :itemQuatroStatus="resultadosSistemicosCards.cardUm.itemQuatroStatus"
          :itemQuatroDate="resultadosSistemicosCards.cardUm.itemQuatroDate"

          :itemCincoPoints="resultadosSistemicosCards.cardUm.itemCincoPoints"
          :itemCincoTitle="resultadosSistemicosCards.cardUm.itemCincoTitle"
          :itemCincoStatus="resultadosSistemicosCards.cardUm.itemCincoStatus"
          :itemCincoDate="resultadosSistemicosCards.cardUm.itemCincoDate"

          :itemSeisPoints="resultadosSistemicosCards.cardUm.itemSeisPoints"
          :itemSeisTitle="resultadosSistemicosCards.cardUm.itemSeisTitle"
          :itemSeisStatus="resultadosSistemicosCards.cardUm.itemSeisStatus"
          :itemSeisDate="resultadosSistemicosCards.cardUm.itemSeisDate"

          :itemSetePoints="resultadosSistemicosCards.cardUm.itemSetePoints"
          :itemSeteTitle="resultadosSistemicosCards.cardUm.itemSeteTitle"
          :itemSeteStatus="resultadosSistemicosCards.cardUm.itemSeteStatus"
          :itemSeteDate="resultadosSistemicosCards.cardUm.itemSeteDate"

          :itemOitoPoints="resultadosSistemicosCards.cardUm.itemOitoPoints"
          :itemOitoTitle="resultadosSistemicosCards.cardUm.itemOitoTitle"
          :itemOitoStatus="resultadosSistemicosCards.cardUm.itemOitoStatus"
          :itemOitoDate="resultadosSistemicosCards.cardUm.itemOitoDate"

          :itemNovePoints="resultadosSistemicosCards.cardUm.itemNovePoints"
          :itemNoveTitle="resultadosSistemicosCards.cardUm.itemNoveTitle"
          :itemNoveStatus="resultadosSistemicosCards.cardUm.itemNoveStatus"
          :itemNoveDate="resultadosSistemicosCards.cardUm.itemNoveDate"

          :itemDezPoints="resultadosSistemicosCards.cardUm.itemDezPoints"
          :itemDezTitle="resultadosSistemicosCards.cardUm.itemDezTitle"
          :itemDezStatus="resultadosSistemicosCards.cardUm.itemDezStatus"
          :itemDezDate="resultadosSistemicosCards.cardUm.itemDezDate"

          :itemOnzePoints="resultadosSistemicosCards.cardUm.itemOnzePoints"
          :itemOnzeTitle="resultadosSistemicosCards.cardUm.itemOnzeTitle"
          :itemOnzeStatus="resultadosSistemicosCards.cardUm.itemOnzeStatus"
          :itemOnzeDate="resultadosSistemicosCards.cardUm.itemOnzeDate"
      />

      <CardExpandResultadoSistemico
          class="z-index-card-02"
          :type="resultadosSistemicosCards.cardDois.type"
          :cardName="resultadosSistemicosCards.cardDois.cardName"
          :cardPoints="resultadosSistemicosCards.cardDois.cardPoints"
          :completeActivities="resultadosSistemicosCards.cardDois.completeActivities"
          :amountActivities="resultadosSistemicosCards.cardDois.amountActivities"

          :itemUmPoints="resultadosSistemicosCards.cardDois.itemUmPoints"
          :itemUmTitle="resultadosSistemicosCards.cardDois.itemUmTitle"
          :itemUmStatus="resultadosSistemicosCards.cardDois.itemUmStatus"
          :itemUmDate="resultadosSistemicosCards.cardDois.itemUmDate"

          :itemDoisPoints="resultadosSistemicosCards.cardDois.itemDoisPoints"
          :itemDoisTitle="resultadosSistemicosCards.cardDois.itemDoisTitle"
          :itemDoisStatus="resultadosSistemicosCards.cardDois.itemDoisStatus"
          :itemDoisDate="resultadosSistemicosCards.cardDois.itemDoisDate"

          :itemTresPoints="resultadosSistemicosCards.cardDois.itemTresPoints"
          :itemTresTitle="resultadosSistemicosCards.cardDois.itemTresTitle"
          :itemTresStatus="resultadosSistemicosCards.cardDois.itemTresStatus"
          :itemTresDate="resultadosSistemicosCards.cardDois.itemTresDate"

          :itemQuatroPoints="resultadosSistemicosCards.cardDois.itemQuatroPoints"
          :itemQuatroTitle="resultadosSistemicosCards.cardDois.itemQuatroTitle"
          :itemQuatroStatus="resultadosSistemicosCards.cardDois.itemQuatroStatus"
          :itemQuatroDate="resultadosSistemicosCards.cardDois.itemQuatroDate"

          :itemCincoPoints="resultadosSistemicosCards.cardDois.itemCincoPoints"
          :itemCincoTitle="resultadosSistemicosCards.cardDois.itemCincoTitle"
          :itemCincoStatus="resultadosSistemicosCards.cardDois.itemCincoStatus"
          :itemCincoDate="resultadosSistemicosCards.cardDois.itemCincoDate"

          :itemSeisPoints="resultadosSistemicosCards.cardDois.itemSeisPoints"
          :itemSeisTitle="resultadosSistemicosCards.cardDois.itemSeisTitle"
          :itemSeisStatus="resultadosSistemicosCards.cardDois.itemSeisStatus"
          :itemSeisDate="resultadosSistemicosCards.cardDois.itemSeisDate"
      />

      <CardExpandResultadoSistemico
          class="z-index-card-03"
          :type="resultadosSistemicosCards.cardTres.type"
          :cardName="resultadosSistemicosCards.cardTres.cardName"
          :cardPoints="resultadosSistemicosCards.cardTres.cardPoints"
          :completeActivities="resultadosSistemicosCards.cardTres.completeActivities"
          :amountActivities="resultadosSistemicosCards.cardTres.amountActivities"

          :itemUmPoints="resultadosSistemicosCards.cardTres.itemUmPoints"
          :itemUmTitle="resultadosSistemicosCards.cardTres.itemUmTitle"
          :itemUmStatus="resultadosSistemicosCards.cardTres.itemUmStatus"
          :itemUmDate="resultadosSistemicosCards.cardTres.itemUmDate"

          :itemDoisPoints="resultadosSistemicosCards.cardTres.itemDoisPoints"
          :itemDoisTitle="resultadosSistemicosCards.cardTres.itemDoisTitle"
          :itemDoisStatus="resultadosSistemicosCards.cardTres.itemDoisStatus"
          :itemDoisDate="resultadosSistemicosCards.cardTres.itemDoisDate"

          :itemTresPoints="resultadosSistemicosCards.cardTres.itemTresPoints"
          :itemTresTitle="resultadosSistemicosCards.cardTres.itemTresTitle"
          :itemTresStatus="resultadosSistemicosCards.cardTres.itemTresStatus"
          :itemTresDate="resultadosSistemicosCards.cardTres.itemTresDate"

          :itemQuatroPoints="resultadosSistemicosCards.cardTres.itemQuatroPoints"
          :itemQuatroTitle="resultadosSistemicosCards.cardTres.itemQuatroTitle"
          :itemQuatroStatus="resultadosSistemicosCards.cardTres.itemQuatroStatus"
          :itemQuatroDate="resultadosSistemicosCards.cardTres.itemQuatroDate"

          :itemCincoPoints="resultadosSistemicosCards.cardTres.itemCincoPoints"
          :itemCincoTitle="resultadosSistemicosCards.cardTres.itemCincoTitle"
          :itemCincoStatus="resultadosSistemicosCards.cardTres.itemCincoStatus"
          :itemCincoDate="resultadosSistemicosCards.cardTres.itemCincoDate"
      />

      <CardExpandResultadoSistemico
          class="z-index-card-04"
          :type="resultadosSistemicosCards.cardQuatro.type"
          :cardName="resultadosSistemicosCards.cardQuatro.cardName"
          :cardPoints="resultadosSistemicosCards.cardQuatro.cardPoints"
          :completeActivities="resultadosSistemicosCards.cardQuatro.completeActivities"
          :amountActivities="resultadosSistemicosCards.cardQuatro.amountActivities"

          :itemUmPoints="resultadosSistemicosCards.cardQuatro.itemUmPoints"
          :itemUmTitle="resultadosSistemicosCards.cardQuatro.itemUmTitle"
          :itemUmStatus="resultadosSistemicosCards.cardQuatro.itemUmStatus"
          :itemUmDate="resultadosSistemicosCards.cardQuatro.itemUmDate"

          :itemDoisPoints="resultadosSistemicosCards.cardQuatro.itemDoisPoints"
          :itemDoisTitle="resultadosSistemicosCards.cardQuatro.itemDoisTitle"
          :itemDoisStatus="resultadosSistemicosCards.cardQuatro.itemDoisStatus"
          :itemDoisDate="resultadosSistemicosCards.cardQuatro.itemDoisDate"

          :itemTresPoints="resultadosSistemicosCards.cardQuatro.itemTresPoints"
          :itemTresTitle="resultadosSistemicosCards.cardQuatro.itemTresTitle"
          :itemTresStatus="resultadosSistemicosCards.cardQuatro.itemTresStatus"
          :itemTresDate="resultadosSistemicosCards.cardQuatro.itemTresDate"
      />

      <CardExpandResultadoSistemico
          class="z-index-card-05"
          :type="resultadosSistemicosCards.cardCinco.type"
          :cardName="resultadosSistemicosCards.cardCinco.cardName"
          :cardPoints="resultadosSistemicosCards.cardCinco.cardPoints"
          :completeActivities="resultadosSistemicosCards.cardCinco.completeActivities"
          :amountActivities="resultadosSistemicosCards.cardCinco.amountActivities"

          :itemUmPoints="resultadosSistemicosCards.cardCinco.itemUmPoints"
          :itemUmTitle="resultadosSistemicosCards.cardCinco.itemUmTitle"
          :itemUmStatus="resultadosSistemicosCards.cardCinco.itemUmStatus"
          :itemUmDate="resultadosSistemicosCards.cardCinco.itemUmDate"

          :itemDoisPoints="resultadosSistemicosCards.cardCinco.itemDoisPoints"
          :itemDoisTitle="resultadosSistemicosCards.cardCinco.itemDoisTitle"
          :itemDoisStatus="resultadosSistemicosCards.cardCinco.itemDoisStatus"
          :itemDoisDate="resultadosSistemicosCards.cardCinco.itemDoisDate"

          :itemTresPoints="resultadosSistemicosCards.cardCinco.itemTresPoints"
          :itemTresTitle="resultadosSistemicosCards.cardCinco.itemTresTitle"
          :itemTresStatus="resultadosSistemicosCards.cardCinco.itemTresStatus"
          :itemTresDate="resultadosSistemicosCards.cardCinco.itemTresDate"
      />

      <CardExpandResultadoSistemico
          class="z-index-card-06"
          :type="resultadosSistemicosCards.cardSeis.type"
          :cardName="resultadosSistemicosCards.cardSeis.cardName"
          :cardPoints="resultadosSistemicosCards.cardSeis.cardPoints"
          :completeActivities="resultadosSistemicosCards.cardSeis.completeActivities"
          :amountActivities="resultadosSistemicosCards.cardSeis.amountActivities"

          :itemUmPoints="resultadosSistemicosCards.cardSeis.itemUmPoints"
          :itemUmTitle="resultadosSistemicosCards.cardSeis.itemUmTitle"
          :itemUmStatus="resultadosSistemicosCards.cardSeis.itemUmStatus"
          :itemUmDate="resultadosSistemicosCards.cardSeis.itemUmDate"

          :itemDoisPoints="resultadosSistemicosCards.cardSeis.itemDoisPoints"
          :itemDoisTitle="resultadosSistemicosCards.cardSeis.itemDoisTitle"
          :itemDoisStatus="resultadosSistemicosCards.cardSeis.itemDoisStatus"
          :itemDoisDate="resultadosSistemicosCards.cardSeis.itemDoisDate"
      />

      <CardExpandResultadoSistemico
          class="z-index-card-07"
          :type="resultadosSistemicosCards.cardSete.type"
          :cardName="resultadosSistemicosCards.cardSete.cardName"
          :cardPoints="resultadosSistemicosCards.cardSete.cardPoints"
          :completeActivities="resultadosSistemicosCards.cardSete.completeActivities"
          :amountActivities="resultadosSistemicosCards.cardSete.amountActivities"

          :itemUmPoints="resultadosSistemicosCards.cardSete.itemUmPoints"
          :itemUmTitle="resultadosSistemicosCards.cardSete.itemUmTitle"
          :itemUmStatus="resultadosSistemicosCards.cardSete.itemUmStatus"
          :itemUmDate="resultadosSistemicosCards.cardSete.itemUmDate"

          :itemDoisPoints="resultadosSistemicosCards.cardSete.itemDoisPoints"
          :itemDoisTitle="resultadosSistemicosCards.cardSete.itemDoisTitle"
          :itemDoisStatus="resultadosSistemicosCards.cardSete.itemDoisStatus"
          :itemDoisDate="resultadosSistemicosCards.cardSete.itemDoisDate"

          :itemTresPoints="resultadosSistemicosCards.cardSete.itemTresPoints"
          :itemTresTitle="resultadosSistemicosCards.cardSete.itemTresTitle"
          :itemTresStatus="resultadosSistemicosCards.cardSete.itemTresStatus"
          :itemTresDate="resultadosSistemicosCards.cardSete.itemTresDate"
      />
    </div>

    <div class="cards-expand" v-if="type === 3">
      <CardExpandIndicadoresSociais
          :cardName="indicadoresSociaisCards.cardUm.cardName"
          :cardPoints="indicadoresSociaisCards.cardUm.cardPoints"
      />

      <CardExpandIndicadoresSociais
          :cardName="indicadoresSociaisCards.cardDois.cardName"
          :cardPoints="indicadoresSociaisCards.cardDois.cardPoints"
      />

      <CardExpandIndicadoresSociais
          :cardName="indicadoresSociaisCards.cardTres.cardName"
          :cardPoints="indicadoresSociaisCards.cardTres.cardPoints"
      />

      <CardExpandIndicadoresSociais
          :cardName="indicadoresSociaisCards.cardQuatro.cardName"
          :cardPoints="indicadoresSociaisCards.cardQuatro.cardPoints"
      />

      <CardExpandIndicadoresSociais
          :cardName="indicadoresSociaisCards.cardCinco.cardName"
          :cardPoints="indicadoresSociaisCards.cardCinco.cardPoints"
      />

      <CardExpandIndicadoresSociais
          :cardName="indicadoresSociaisCards.cardSeis.cardName"
          :cardPoints="indicadoresSociaisCards.cardSeis.cardPoints"
      />

      <CardExpandIndicadoresSociais
          :cardName="indicadoresSociaisCards.cardSete.cardName"
          :cardPoints="indicadoresSociaisCards.cardSete.cardPoints"
      />
    </div>
  </div>
</template>

<script>
import CardExpandParticipacaoCidada from "./CardExpandParticipacaoCidada.vue"
import CardExpandResultadoSistemico from "./CardExpandResultadoSistemico.vue"
import CardExpandIndicadoresSociais from "./CardExpandIndicadoresSociais.vue"
import GeneralsUtil from "../../utils/generals-util";

export default {
  name: "BlocoResumo",
  props: [
    "resumoData",
    "type",
    "title",
    "points",
    "pointsMeta",
    "pointsTotal",
    "text",
  ],
  components: {
    CardExpandParticipacaoCidada,
    CardExpandResultadoSistemico,
    CardExpandIndicadoresSociais
  },
  data() {
    return {
      progressStyle: null,
      dateShowPoints: '31/12/2024',
      showPoints: false,

      dataCardsParticipacaoCidada: null,
      dataCardsResultadosSistemicos: null,
      dataCardsIndicadoresSociais: null,

      participacaoCidadaCards: {
        cardUm: {
          cardName: null,
          cardUmDate: null,
          cardDoisDate: null,
          cardTresDate: null,
          cardQuatroDate: null,
          cardPoints: null,
          completeActivities: null,
          amountActivities: null,

          itemUmPoints: null,
          itemUmTitle: null,
          itemUmStatus: null,
          itemUmDate: null,

          itemDoisPoints: null,
          itemDoisTitle: null,
          itemDoisStatus: null,
          itemDoisDate: null,

          itemTresPoints: null,
          itemTresTitle: null,
          itemTresStatus: null,
          itemTresDate: null,
        },
        cardDois: {
          cardName: null,
          cardDoisDate: null,
          cardPoints: null,
          completeActivities: null,
          amountActivities: null,

          itemUmPoints: null,
          itemUmTitle: null,
          itemUmStatus: null,
          itemUmDate: null,
        },
        cardTres: {
          cardName: null,
          cardTresDate: null,
          cardPoints: null,
          completeActivities: null,
          amountActivities: null,

          itemUmPoints: null,
          itemUmTitle: null,
          itemUmStatus: null,
          itemUmDate: null,

          itemDoisPoints: null,
          itemDoisTitle: null,
          itemDoisStatus: null,
          itemDoisDate: null,

          itemTresPoints: null,
          itemTresTitle: null,
          itemTresStatus: null,
          itemTresDate: null,
        },
        cardQuatro: {
          cardName: null,
          cardQuatroDate: null,
          cardPoints: null,
          completeActivities: null,
          amountActivities: null,

          itemUmPoints: null,
          itemUmTitle: null,
          itemUmStatus: null,
          itemUmDate: null,

          itemDoisPoints: null,
          itemDoisTitle: null,
          itemDoisStatus: null,
          itemDoisDate: null,
        }
      },
      resultadosSistemicosCards: {
        cardUm: {
          type: null,
          cardName: null,
          cardPoints: null,
          completeActivities: null,
          amountActivities: null,

          itemUmPoints: null,
          itemUmTitle: null,
          itemUmStatus: null,
          itemUmDate: null,

          itemDoisPoints: null,
          itemDoisTitle: null,
          itemDoisStatus: null,
          itemDoisDate: null,

          itemTresPoints: null,
          itemTresTitle: null,
          itemTresStatus: null,
          itemTresDate: null,

          itemQuatroPoints: null,
          itemQuatroTitle: null,
          itemQuatroStatus: null,
          itemQuatroDate: null,

          itemCincoPoints: null,
          itemCincoTitle: null,
          itemCincoStatus: null,
          itemCincoDate: null,

          itemSeisPoints: null,
          itemSeisTitle: null,
          itemSeisStatus: null,
          itemSeisDate: null,

          itemSetePoints: null,
          itemSeteTitle: null,
          itemSeteStatus: null,
          itemSeteDate: null,

          itemOitoPoints: null,
          itemOitoTitle: null,
          itemOitoStatus: null,
          itemOitoDate: null,

          itemNovePoints: null,
          itemNoveTitle: null,
          itemNoveStatus: null,
          itemNoveDate: null,

          itemDezPoints: null,
          itemDezTitle: null,
          itemDezStatus: null,
          itemDezDate: null,

          itemOnzePoints: null,
          itemOnzeTitle: null,
          itemOnzeStatus: null,
          itemOnzeDate: null,
        },
        cardDois: {
          type: null,
          cardName: null,
          cardPoints: null,
          completeActivities: null,
          amountActivities: null,

          itemUmPoints: null,
          itemUmTitle: null,
          itemUmStatus: null,
          itemUmDate: null,

          itemDoisPoints: null,
          itemDoisTitle: null,
          itemDoisStatus: null,
          itemDoisDate: null,

          itemTresPoints: null,
          itemTresTitle: null,
          itemTresStatus: null,
          itemTresDate: null,

          itemQuatroPoints: null,
          itemQuatroTitle: null,
          itemQuatroStatus: null,
          itemQuatroDate: null,

          itemCincoPoints: null,
          itemCincoTitle: null,
          itemCincoStatus: null,
          itemCincoDate: null,

          itemSeisPoints: null,
          itemSeisTitle: null,
          itemSeisStatus: null,
          itemSeisDate: null,
        },
        cardTres: {
          type: null,
          cardName: null,
          cardPoints: null,
          completeActivities: null,
          amountActivities: null,

          itemUmPoints: null,
          itemUmTitle: null,
          itemUmStatus: null,
          itemUmDate: null,

          itemDoisPoints: null,
          itemDoisTitle: null,
          itemDoisStatus: null,
          itemDoisDate: null,

          itemTresPoints: null,
          itemTresTitle: null,
          itemTresStatus: null,
          itemTresDate: null,

          itemQuatroPoints: null,
          itemQuatroTitle: null,
          itemQuatroStatus: null,
          itemQuatroDate: null,

          itemCincoPoints: null,
          itemCincoTitle: null,
          itemCincoStatus: null,
          itemCincoDate: null,
        },
        cardQuatro: {
          type: null,
          cardName: null,
          cardPoints: null,
          completeActivities: null,
          amountActivities: null,

          itemUmPoints: null,
          itemUmTitle: null,
          itemUmStatus: null,
          itemUmDate: null,

          itemDoisPoints: null,
          itemDoisTitle: null,
          itemDoisStatus: null,
          itemDoisDate: null,

          itemTresPoints: null,
          itemTresTitle: null,
          itemTresStatus: null,
          itemTresDate: null,
        },
        cardCinco: {
          type: null,
          cardName: null,
          cardPoints: null,
          completeActivities: null,
          amountActivities: null,

          itemUmPoints: null,
          itemUmTitle: null,
          itemUmStatus: null,
          itemUmDate: null,

          itemDoisPoints: null,
          itemDoisTitle: null,
          itemDoisStatus: null,
          itemDoisDate: null,

          itemTresPoints: null,
          itemTresTitle: null,
          itemTresStatus: null,
          itemTresDate: null,
        },
        cardSeis: {
          type: null,
          cardName: null,
          cardPoints: null,
          completeActivities: null,
          amountActivities: null,

          itemUmPoints: null,
          itemUmTitle: null,
          itemUmStatus: null,
          itemUmDate: null,

          itemDoisPoints: null,
          itemDoisTitle: null,
          itemDoisStatus: null,
          itemDoisDate: null,

          itemTresPoints: null,
          itemTresTitle: null,
          itemTresStatus: null,
          itemTresDate: null,
        },
        cardSete: {
          type: null,
          cardName: null,
          cardPoints: null,
          completeActivities: null,
          amountActivities: null,

          itemUmPoints: null,
          itemUmTitle: null,
          itemUmStatus: null,
          itemUmDate: null,

          itemDoisPoints: null,
          itemDoisTitle: null,
          itemDoisStatus: null,
          itemDoisDate: null,

          itemTresPoints: null,
          itemTresTitle: null,
          itemTresStatus: null,
          itemTresDate: null,
        }
      },
      indicadoresSociaisCards: {
        cardUm: {
          cardName: null,
          cardPoints: null,
        },
        cardDois: {
          cardName: null,
          cardPoints: null,
        },
        cardTres: {
          cardName: null,
          cardPoints: null,
        },
        cardQuatro: {
          cardName: null,
          cardPoints: null,
        },
        cardCinco: {
          cardName: null,
          cardPoints: null,
        },
        cardSeis: {
          cardName: null,
          cardPoints: null,
        },
        cardSete: {
          cardName: null,
          cardPoints: null,
        },
      },

      datas: {
        itemUm: null,
        itemDois: null,
        itemTres: null,
        itemQuatro: null,
        itemCinco: null,
      },
      pointsWidth: {
        blocoUm: 0,
        blocoDois: 0,
        blocoTres: 0,
      },

      itemsSystemicResults: {
        desenvolvimentoInfantil: null,
        educacaoQualidade: null,
        habitosHigiene: null,
        oportunidadesEducacao: null,
        desenvolvimentoIntegral: null,
        prevencaoResposta: null,
        protecaoSocial: null
      }
    };
  },
  watch: {
    resumoData: {
      handler(data) {
        if (data) {
          this.requestData();
          this.calcProgressBar();
          this.setProgressBar();
        }
      },
      immediate: true,
    }
  },
  mounted() {
    this.showPoints = GeneralsUtil.isDateOver(this.dateShowPoints);
  },
  methods: {
    requestData() {
      this.dataCardsParticipacaoCidada = this.resumoData.citizen_participation;
      this.dataCardsResultadosSistemicos = this.resumoData.systemic_results;
      this.dataCardsIndicadoresSociais = null;

      this.setDataSystemicResultsObj(this.dataCardsResultadosSistemicos);

      this.populaDadosparticipacaoCidadaCards();
      this.populaDadosresultadosSistemicosCards();
      this.populaDadosindicadoresSociaisCards();

      this.setPointsRS03();
      this.setPointsRS04();
      this.setPointsRS05();
      this.setPointsRS06();
      this.setPointsRS07();
    },
    calcProgressBar() {
      if (this.type === 1) {
        if (this.points === 0) this.pointsWidth.blocoUm = 0;
        if (this.points === 1) this.pointsWidth.blocoUm = 12.5;
        if (this.points === 2) this.pointsWidth.blocoUm = 25;
        if (this.points === 3) this.pointsWidth.blocoUm = 37.5;
        if (this.points === 4) this.pointsWidth.blocoUm = 50;
        if (this.points === 5) this.pointsWidth.blocoUm = 62.5;
        if (this.points === 6) this.pointsWidth.blocoUm = 75;
        if (this.points === 7) this.pointsWidth.blocoUm = 87.5;
        if (this.points === 8) this.pointsWidth.blocoUm = 100;
      }

      if (this.type === 2 || this.type === 3) {
        if (this.points === 0) {
          this.pointsWidth.blocoDois = 0;
          this.pointsWidth.blocoTres = 0;
        }

        if (this.points === 1) {
          this.pointsWidth.blocoDois = 7;
          this.pointsWidth.blocoTres = 7;
        }

        if (this.points === 2) {
          this.pointsWidth.blocoDois = 14;
          this.pointsWidth.blocoTres = 14;
        }

        if (this.points === 3) {
          this.pointsWidth.blocoDois = 21.5;
          this.pointsWidth.blocoTres = 21.5;
        }

        if (this.points === 4) {
          this.pointsWidth.blocoDois = 28.5;
          this.pointsWidth.blocoTres = 28.5;
        }

        if (this.points === 5) {
          this.pointsWidth.blocoDois = 36;
          this.pointsWidth.blocoTres = 36;
        }

        if (this.points === 6) {
          this.pointsWidth.blocoDois = 43;
          this.pointsWidth.blocoTres = 43;
        }

        if (this.points === 7) {
          this.pointsWidth.blocoDois = 50;
          this.pointsWidth.blocoTres = 50;
        }

        if (this.points === 8) {
          this.pointsWidth.blocoDois = 57;
          this.pointsWidth.blocoTres = 57;
        }

        if (this.points === 9) {
          this.pointsWidth.blocoDois = 64;
          this.pointsWidth.blocoTres = 64;
        }

        if (this.points === 10) {
          this.pointsWidth.blocoDois = 72;
          this.pointsWidth.blocoTres = 72;
        }

        if (this.points === 11) {
          this.pointsWidth.blocoDois = 78.5;
          this.pointsWidth.blocoTres = 78.5;
        }

        if (this.points === 12) {
          this.pointsWidth.blocoDois = 85.5;
          this.pointsWidth.blocoTres = 85.5;
        }

        if (this.points === 13) {
          this.pointsWidth.blocoDois = 93;
          this.pointsWidth.blocoTres = 93;
        }

        if (this.points === 14) {
          this.pointsWidth.blocoDois = 100;
          this.pointsWidth.blocoTres = 100;
        }
      }
    },
    setProgressBar() {
      if (this.type === 1) this.progressStyle = this.points < 6 ? "progress-orange" : "progress-blue";
      if (this.type === 2) this.progressStyle = this.points < 11 ? "progress-orange" : "progress-blue";
      if (this.type === 3) this.progressStyle = this.points < 3 ? "progress-orange" : "progress-blue";
    },
    populaDadosparticipacaoCidadaCards() {
      this.participacaoCidadaCards = {
        cardUm: {
          cardName: "Atuação do Conselho Municipal dos Direitos da Criança e do Adolescente (CMDCA)",
          cardPoints: this.dataCardsParticipacaoCidada.general_data.cmdca.points,
          completeActivities: "0",
          amountActivities: "3",
          cardUmDate: "30/06/2024",

          itemUmPoints: this.dataCardsParticipacaoCidada.general_data.cmdca.first.points,
          itemUmTitle: "1ª Reunião do CMDCA - 2022",
          itemUmStatus: "Em atraso",
          itemUmDate: "31/03/2023",

          itemDoisPoints: this.dataCardsParticipacaoCidada.general_data.cmdca.second.points,
          itemDoisTitle: "2ª Reunião do CMDCA - 2023",
          itemDoisStatus: "Em atraso",
          itemDoisDate: "31/03/2024",

          itemTresPoints: this.dataCardsParticipacaoCidada.general_data.cmdca.regular_functioning.points,
          itemTresTitle: "Funcionamento Regular do CMDCA",
          itemTresStatus: "Em atraso",
          itemTresDate: "30/06/2024",
        },
        cardDois: {
          cardName: "Atuação do Conselho Tutelar",
          cardPoints: this.dataCardsParticipacaoCidada.general_data.council_regular_functioning.points,
          completeActivities: "0",
          amountActivities: "1",
          cardDoisDate: "30/06/2024",

          itemUmPoints: this.dataCardsParticipacaoCidada.general_data.council_regular_functioning.points,
          itemUmTitle: "Atuação do Conselho Tutelar",
          itemUmStatus: "Em atraso",
          itemUmDate: "30/06/2024",
        },
        cardTres: {
          cardName: "Criação e funcionamento do Núcleo de Cidadania de Adolescentes (NUCA)",
          cardPoints: this.dataCardsParticipacaoCidada.general_data.creation_and_functioning_of_nuca.points,
          completeActivities: "0",
          amountActivities: "3",
          cardTresDate: "30/06/2024",

          itemUmPoints: this.dataCardsParticipacaoCidada.general_data.creation_and_functioning_of_nuca.nuca_creation.points,
          itemUmTitle: "Criar o NUCA com pelo menos 16 adolescentes (sendo 8 meninas e 8 meninos e/ou outros gêneros)",
          itemUmStatus: "Em atraso",
          itemUmDate: "30/06/2022",

          itemDoisPoints: this.dataCardsParticipacaoCidada.general_data.creation_and_functioning_of_nuca.add_nuca_to_municipal_plan.points,
          itemDoisTitle: "Garantir que as atividades do Plano de Participação Cidadã de Adolescentes estejam contempladas no Plano de Ação Municipal",
          itemDoisStatus: "Em atraso",
          itemDoisDate: "30/06/2022",

          itemTresPoints: this.dataCardsParticipacaoCidada.general_data.creation_and_functioning_of_nuca.monitor_nuca_activities.points,
          itemTresTitle: "Implementar as 04 atividades obrigatórias do NUCA previstas no Plano de Participação Cidadã de Adolescentes",
          itemTresStatus: "Em atraso",
          itemTresDate: "30/06/2024",
        },
        cardQuatro: {
          cardName: "Realizar os Fóruns Comunitários",
          cardPoints: this.dataCardsParticipacaoCidada.general_data.accomplish_community_forums.points,
          completeActivities: "0",
          amountActivities: "2",
          cardQuatroDate: "30/06/2024",

          itemUmPoints: this.dataCardsParticipacaoCidada.general_data.accomplish_community_forums.first_community_forum.points,
          itemUmTitle: "1º Fórum Comunitário (2022) - Elaboração do Plano de Ação Municipal",
          itemUmStatus: "Em atraso",
          itemUmDate: "30/06/2022",

          itemDoisPoints: this.dataCardsParticipacaoCidada.general_data.accomplish_community_forums.second_community_forum.points,
          itemDoisTitle: "2º Fórum Comunitário (2024) - Acompanhamento da implementação do Plano de Ação Municipal",
          itemDoisStatus: "Em atraso",
          itemDoisDate: "30/06/2024",
        }
      };
    },
    populaDadosresultadosSistemicosCards() {
      this.resultadosSistemicosCards = {
        cardUm: {
          type: "desenvolvimentoInfantil",
          cardName: "Desenvolvimento infantil na primeira infância",
          cardPoints: this.itemsSystemicResults.desenvolvimentoInfantil.points,
          completeActivities: "0",
          amountActivities: "9",

          itemUmPoints: this.itemsSystemicResults.desenvolvimentoInfantil.general_data.join_bav.points,
          itemUmTitle: "Adesão à estratégia Busca Ativa Vacinal (BAV)",
          itemUmStatus: "Em atraso",
          itemUmDate: "30/06/2024",

          itemDoisPoints: this.itemsSystemicResults.desenvolvimentoInfantil.general_data.first_step.points,
          itemDoisTitle: "Unidades de Educação Infantil ofertando práticas educacionais de qualidade (1ª medição - 2023)",
          itemDoisStatus: "Em atraso",
          itemDoisDate: "31/05/2023",

          itemTresPoints: this.itemsSystemicResults.desenvolvimentoInfantil.general_data.cras_assistance_services.points,
          itemTresTitle: "CRAS ofertando serviços de atenção à Primeira Infância (Censo SUAS)",
          itemTresStatus: "Em atraso",
          itemTresDate: "12/01/2024",

          itemQuatroPoints: this.itemsSystemicResults.desenvolvimentoInfantil.general_data.certified_professionals.points,
          itemQuatroTitle: "Profissionais certificados em pelo menos 1 dos 4 cursos sobre Educação Infantil",
          itemQuatroStatus: "Em atraso",
          itemQuatroDate: "30/06/2024",

          itemCincoPoints: this.itemsSystemicResults.desenvolvimentoInfantil.general_data.second_step.points,
          itemCincoTitle: "Unidades de Educação Infantil ofertando práticas educacionais de qualidade (2ª medição - 2024)",
          itemCincoStatus: "Em atraso",
          itemCincoDate: "30/06/2024",

          itemSeisPoints: this.itemsSystemicResults.desenvolvimentoInfantil.general_data.first_indicate.points,
          itemSeisTitle: "INDIQUE: autoavaliação da qualidade das Unidades de Educação Infantil (1ª medição - 2023)",
          itemSeisStatus: "Em atraso",
          itemSeisDate: "30/06/2023",

          itemSetePoints: this.itemsSystemicResults.desenvolvimentoInfantil.general_data.second_indicate.points,
          itemSeteTitle: "INDIQUE: autoavaliação da qualidade das Unidades de Educação Infantil (2ª medição - 2024)",
          itemSeteStatus: "Em atraso",
          itemSeteDate: "30/06/2024",

          itemOitoPoints: this.itemsSystemicResults.desenvolvimentoInfantil.general_data.municipal_plan_for_early_childhood.points,
          itemOitoTitle: "Elaboração do Plano Municipal pela Primeira Infância - (PMPI)",
          itemOitoStatus: "Em atraso",
          itemOitoDate: "31/03/2023",

          itemNovePoints: this.itemsSystemicResults.desenvolvimentoInfantil.general_data.implemented_pmpi.points,
          itemNoveTitle: "Elaboração do Plano Municipal pela Primeira Infância - (PMPI) implementado",
          itemNoveStatus: "Em atraso",
          itemNoveDate: "31/03/2024",
        },
        cardDois: {
          type: "educacaoQualidade",
          cardName: "Educação de qualidade para todos",
          cardPoints: this.itemsSystemicResults.educacaoQualidade.points,
          completeActivities: "0",
          amountActivities: "5",

          itemUmPoints: this.itemsSystemicResults.educacaoQualidade.general_data.census_2019.points,
          itemUmTitle: "Alcance das metas de rematrícula por meio da estratégia Busca Ativa Escolar (BAE) (1ª medição - 2023)",
          itemUmStatus: "Em atraso",
          itemUmDate: "06/06/2023",

          itemDoisPoints: this.itemsSystemicResults.educacaoQualidade.general_data.first_step.points,
          itemDoisTitle: "Realização de práticas de enfrentamento à cultura do fracasso escolar no Ensino Fundamental (1ª medição - 2023)",
          itemDoisStatus: "Em atraso",
          itemDoisDate: "30/06/2023",

          itemTresPoints: this.itemsSystemicResults.educacaoQualidade.general_data.census_2022.points,
          itemTresTitle: "Alcance das metas de rematrícula por meio da estratégia Busca Ativa Escolar (BAE) (2ª medição - 2024)",
          itemTresStatus: "Em atraso",
          itemTresDate: "30/06/2024",

          itemQuatroPoints: this.itemsSystemicResults.educacaoQualidade.general_data.second_step.points,
          itemQuatroTitle: "Realização de práticas de enfrentamento à cultura do fracasso escolar no Ensino Fundamental (2ª medição - 2024)",
          itemQuatroStatus: "Em atraso",
          itemQuatroDate: "30/06/2024",

          itemCincoPoints: this.itemsSystemicResults.educacaoQualidade.general_data.certified_professionals.points,
          itemCincoTitle: "Profissionais certificados em pelo menos 1 dos 5 cursos sobre Ensino Fundamental",
          itemCincoStatus: "Em atraso",
          itemCincoDate: "30/06/2024",
        },
        cardTres: {
          type: "habitosHigiene",
          cardName: "Hábitos de higiene e acesso a água e saneamento assegurados para crianças e adolescentes nas escolas",
          cardPoints: this.itemsSystemicResults.habitosHigiene.points,
          completeActivities: "0",
          amountActivities: "5",

          itemUmPoints: "0",
          itemUmTitle: "Escolas realizando autoavaliação das condições de água, saneamento e práticas de higiene",
          itemUmStatus: "Em atraso",
          itemUmDate: "30/06/2022",

          itemDoisPoints: "0",
          itemDoisTitle: "Profissionais certificados em cursos de água, saneamento e promoção de higiene nas escolas e unidades de saúde",
          itemDoisStatus: "Em atraso",
          itemDoisDate: "30/06/2022",

          itemTresPoints: "0",
          itemTresTitle: "Escolas viabilizando condições de biossegurança por meio de protocolos de enfrentamento à COVID19",
          itemTresStatus: "Em atraso",
          itemTresDate: "30/06/2022",

          itemQuatroPoints: "0",
          itemQuatroTitle: "Implantação do Programa de Lavagem de Mãos nas escolas",
          itemQuatroStatus: "Em atraso",
          itemQuatroDate: "31/10/2023",

          itemCincoPoints: "0",
          itemCincoTitle: "Profissionais certificados no curso de Direito Humano, Aspectos Legais, Dignidade Menstrual e Igualdade de Gênero",
          itemCincoStatus: "Em atraso",
          itemCincoDate: "13/01/2023",
        },
        cardQuatro: {
          type: "oportunidadesEducacao",
          cardName: "Oportunidades de educação, trabalho e formação profissional para adolescentes e jovens",
          cardPoints: this.itemsSystemicResults.oportunidadesEducacao.points,
          completeActivities: "0",
          amountActivities: "3",

          itemUmPoints: "0",
          itemUmTitle: "Adesão à estratégia 1 Milhão de Oportunidades (1MiO)",
          itemUmStatus: "Em atraso",
          itemUmDate: "31/05/2022",

          itemDoisPoints: "0",
          itemDoisTitle: "Profissionais certificados no curso Oportunidades de Educação, Trabalho e Formação Profissional",
          itemDoisStatus: "Em atraso",
          itemDoisDate: "13/01/2023",

          itemTresPoints: "0",
          itemTresTitle: "Número de oportunidades em estágio, aprendizagem e trabalho geradas para adolescentes e jovens",
          itemTresStatus: "Em atraso",
          itemTresDate: "30/06/2024",
        },
        cardCinco: {
          type: "desenvolvimentoIntegral",
          cardName: "Desenvolvimento integral, saúde mental, e bem-estar de crianças e adolescentes na segunda década de vida",
          cardPoints: this.itemsSystemicResults.desenvolvimentoIntegral.points,
          completeActivities: "0",
          amountActivities: "3",

          itemUmPoints: "0",
          itemUmTitle: "Profissionais certificados nos cursos de Saúde Mental",
          itemUmStatus: "Em atraso",
          itemUmDate: "30/06/2022",

          itemDoisPoints: "0",
          itemDoisTitle: "Elaboração do fluxo de atendimento em Saúde Mental",
          itemDoisStatus: "Em atraso",
          itemDoisDate: "31/08/2022",

          itemTresPoints: "0",
          itemTresTitle: "Criação do Serviço de Apoio Psicossocial",
          itemTresStatus: "Em atraso",
          itemTresDate: "13/01/2023",
        },
        cardSeis: {
          type: "prevencaoResposta",
          cardName: "Prevenção e resposta às violências contra criança e adolescentes",
          cardPoints: this.itemsSystemicResults.prevencaoResposta.points,
          completeActivities: "0",
          amountActivities: "2",

          itemUmPoints: "0",
          itemUmTitle: "Implementação de ações e/ou campanhas de prevenção e resposta às violências",
          itemUmStatus: "Em atraso",
          itemUmDate: "12/01/2024",

          itemDoisPoints: "0",
          itemDoisTitle: "Implementação da Lei da Escuta Protegida (Lei nº 13.431/2017)",
          itemDoisStatus: "Em atraso",
          itemDoisDate: "31/03/2024",
        },
        cardSete: this.getSeventhCardData(),
      };
    },
    getSeventhCardData() {
      const firstCardTitle = this.itemsSystemicResults?.protecaoSocial?.general_data?.cras_education_health?.cras_total_count <= 5
        ? "Centro de Referência da Assistência Social (CRAS) em articulação com os serviços de Educação e Saúde (Censo SUAS)"
        : "Centro de Referência da Assistência Social (CRAS) em articulação com os serviços de Educação, Saúde e Proteção Especial (Censo SUAS)";
      return {
        type: "protecaoSocial",
        cardName: "Proteção social e atenção integral para famílias vulneráveis via serviços intersetoriais",
        cardPoints:  this.itemsSystemicResults.protecaoSocial.points,
        completeActivities: "0",
        amountActivities: "2",

        itemUmPoints: "0",
        itemUmTitle: firstCardTitle,
        itemUmStatus: "Em atraso",
        itemUmDate: "30/06/2024",

        itemDoisPoints: "0",
        itemDoisTitle: "Lei de Diretrizes Orçamentárias (LDO) Estabelecendo Prioridade ao SUAS",
        itemDoisStatus: "Em atraso",
        itemDoisDate: "30/06/2024",
      }
    },
    populaDadosindicadoresSociaisCards() {
      this.indicadoresSociaisCards = {
        cardUm: {
          cardName: "Meta de 90% de crianças de 1 ano vacinadas com a vacina tríplice viral (D2) e tetra viral",
          cardPoints: "0",
        },
        cardDois: {
          cardName: "Meta de 90% de alunos da rede pública do ensino fundamental que abandonaram a escola",
          cardPoints: "0",
        },
        cardTres: {
          cardName: "Meta de 90% de escolas da rede municipal com acesso adequado à água e saneamento",
          cardPoints: "0",
        },
        cardQuatro: {
          cardName: "Meta de 90% de adolescentes entre 15 e 17 anos que estão matriculados no ensino médio",
          cardPoints: "0",
        },
        cardCinco: {
          cardName: "Meta de 90% de nascidos vivos de gestantes com idade entre 10 e 19 anos",
          cardPoints: "0",
        },
        cardSeis: {
          cardName: "Metal de 90% de registros de casos de violações de direitos contra crianças e adolescentes completos",
          cardPoints: "0",
        },
        cardSete: {
          cardName: "Meta dde 90% de famílias cadastradas no cadastro único em acompanhamento pelo PAIF",
          cardPoints: "0",
        },
      };
    },
    setDataSystemicResultsObj(dataSystemicResults) {
      dataSystemicResults.forEach(data => {
        if (data.type === "CHILD_DEVELOPMENT") this.itemsSystemicResults.desenvolvimentoInfantil = data;
        if (data.type === "HYGIENE_HABITS") this.itemsSystemicResults.habitosHigiene = data;
        if (data.type === "EDUCATION_OPPORTUNITIES") this.itemsSystemicResults.oportunidadesEducacao = data;
        if (data.type === "INTEGRAL_DEVELOPMENT") this.itemsSystemicResults.desenvolvimentoIntegral = data;
        if (data.type === "VIOLENCE_PREVENTION") this.itemsSystemicResults.prevencaoResposta = data;
        if (data.type === "SOCIAL_PROTECTION") this.itemsSystemicResults.protecaoSocial = data;
        if (data.type === "QUALITY_EDUCATION") this.itemsSystemicResults.educacaoQualidade = data;
      });
    },

    setPointsRS03() {
      this.calcPointsRS03AvaliacaoSanitaria();
      this.calcPointsRS03CapacidadeAgua();
      this.calcPointsRS03ProtocolosCovid19();
      this.calcPointsRS03LavagemMaos();
      this.calcPointsRS03CapacidadeDireitos();
    },
    calcPointsRS03AvaliacaoSanitaria() {
      this.resultadosSistemicosCards.cardTres.itemUmPoints =
          this.itemsSystemicResults.habitosHigiene.general_data.wash?.points ?? 0;
    },
    calcPointsRS03CapacidadeAgua() {
      this.resultadosSistemicosCards.cardTres.itemDoisPoints = this.itemsSystemicResults.habitosHigiene.general_data?.formation?.points ?? 0;
    },
    calcPointsRS03ProtocolosCovid19() {
      this.resultadosSistemicosCards.cardTres.itemTresPoints =
        this.itemsSystemicResults.habitosHigiene.general_data.checklist?.points ?? 0;
    },
    calcPointsRS03LavagemMaos() {
      this.resultadosSistemicosCards.cardTres.itemQuatroPoints = this.itemsSystemicResults.habitosHigiene.general_data.hands_washing_program.points;
    },
    calcPointsRS03CapacidadeDireitos() {
      this.resultadosSistemicosCards.cardTres.itemCincoPoints = this.itemsSystemicResults.habitosHigiene.general_data?.second_formation?.points ?? 0;
    },

    setPointsRS04() {
      this.calcPointsRS04UmMilhaoOportunidades();
      this.calcPointsRS04CapacitarAgentesPublicos();
      this.calcPointsRS04EstagioAprendizadoTrabalho();
    },
    calcPointsRS04UmMilhaoOportunidades() {
      this.resultadosSistemicosCards.cardQuatro.itemUmPoints = this.itemsSystemicResults.oportunidadesEducacao.general_data.subscribed;
    },
    calcPointsRS04CapacitarAgentesPublicos() {
      this.resultadosSistemicosCards.cardQuatro.itemDoisPoints = this.itemsSystemicResults.oportunidadesEducacao.general_data.train_public_agents.points;
    },
    calcPointsRS04EstagioAprendizadoTrabalho() {
      this.resultadosSistemicosCards.cardQuatro.itemTresPoints = this.itemsSystemicResults.oportunidadesEducacao.general_data.opportunities;
    },

    setPointsRS05() {
      this.calcPointsRS05CapacitacaoSaudeMental();
      this.calcPointsRS05FluxoAtendimento();
      this.calcPointsRS05ApoioPsicossocial();
    },
    calcPointsRS05CapacitacaoSaudeMental() {
      if (this.itemsSystemicResults.desenvolvimentoIntegral.general_data.health_development_on_time >= 4) {
        this.resultadosSistemicosCards.cardCinco.itemUmPoints = 2;
        return;
      }

      if ((
          this.itemsSystemicResults.desenvolvimentoIntegral.general_data.health_development_outside_time +
          this.itemsSystemicResults.desenvolvimentoIntegral.general_data.health_development_on_time
      ) >= 4) {
        this.resultadosSistemicosCards.cardCinco.itemUmPoints = 1;
        return;
      }

      this.resultadosSistemicosCards.cardCinco.itemUmPoints = 0;
    },
    calcPointsRS05FluxoAtendimento() {
      this.resultadosSistemicosCards.cardCinco.itemDoisPoints = this.itemsSystemicResults.desenvolvimentoIntegral.general_data.health_care_course_work.points;
    },
    calcPointsRS05ApoioPsicossocial() {
      this.resultadosSistemicosCards.cardCinco.itemTresPoints = this.itemsSystemicResults.desenvolvimentoIntegral.general_data.psychosocial_course_work.points;
    },

    setPointsRS06() {
      this.calcPointsRS06PrevencaoContraViolencia();
      this.calcPointsRS06ImplementarLei();
    },
    calcPointsRS06PrevencaoContraViolencia() {
      this.resultadosSistemicosCards.cardSeis.itemUmPoints = this.itemsSystemicResults.prevencaoResposta.general_data.violence_prevention_campaign.points;
    },
    calcPointsRS06ImplementarLei() {
      this.resultadosSistemicosCards.cardSeis.itemDoisPoints = this.itemsSystemicResults.prevencaoResposta.general_data.coordination_mechanism_course_work.points;
    },

    setPointsRS07() {
      this.calcPointsRS07ArticulacaoCras1();
      this.calcPointsRS07ApresentarLei();
    },
    calcPointsRS07ArticulacaoCras1() {
      this.resultadosSistemicosCards.cardSete.itemUmPoints = this.itemsSystemicResults.protecaoSocial.general_data.cras_education_health.points;
    },
    calcPointsRS07ApresentarLei() {
      this.resultadosSistemicosCards.cardSete.itemDoisPoints = this.itemsSystemicResults.protecaoSocial.general_data.ldo_course_work.points;
    },
  }
};
</script>

<style lang="sass" scoped>
.resumo-participacao-cidada-page
  margin: 42px auto 0
  max-width: var(--max-width)

.resumo-participacao-cidada-top
  display: flex
  gap: 20px
  margin-bottom: 20px
  @media screen and (max-width: 682px)
    flex-direction: column

.resumo-participacao-cidada-card
  border-radius: 4px
  box-shadow: 0px 3px 6px #00000029
  height: 232px
  line-height: 1
  min-width: 320px
  padding: 15px
  text-align: center

.title
  border-bottom: 2px solid #1cabe2
  color: #1cabe2
  display: inline-block
  font-size: 20px
  margin-bottom: 26px
  padding-bottom: 4px
  @media screen and (max-width: 682px)
    display: block

.material-icons
  color: lightgrey
  font-size: 100px

.hide-points
  font-size: 16px
  line-height: 1.2

.hide-points-border
  border-bottom: 1px solid lightgrey
  margin-bottom: 8px

.points
  align-items: center
  display: flex
  justify-content: center
  margin: 12px 0

.empty
  width: 74px

.point
  font-size: 80px
  margin: 0 18px

.text
  font-size: 24px
  width: 74px

.body-description
  color: #4a4a4a
  font-size: 15px
  font-weight: 500
  margin-bottom: 20px

.body-bar
  background-color: #bbB9b9
  border-radius: 17px
  height: 33px
  overflow: hidden
  padding-bottom: 5px

.body-bar > span
  color: #4a4a4a
  display: block
  margin-top: 20px

.progress
  background-color: red
  height: 33px
  margin-top: -25px

.progress
  background-color: red
  height: 33px
  margin-top: -25px

.progress-orange
  background-color: #fdc00d

.progress-blue
  background-color: #1cabe2

.mark-points
  background-color: #ffffff
  height: 5px
  position: relative
  top: 15px
  transform: rotate(90deg)
  width: 40px
  z-index: 2

.tipo-um
  left: calc(100% / 1.45)
  @media screen and (max-width: 682px)
    left: calc(100% / 1.4)
  @media screen and (max-width: 535px)
    left: calc(100% / 1.41)
  @media screen and (max-width: 465px)
    left: calc(100% / 1.42)
  @media screen and (max-width: 425px)
    left: calc(100% / 1.43)
  @media screen and (max-width: 380px)
    left: calc(100% / 1.44)

.tipo-dois
  left: calc(100% / 1.38)
  @media screen and (max-width: 682px)
    left: calc(100% / 1.33)
  @media screen and (max-width: 535px)
    left: calc(100% / 1.35)
  @media screen and (max-width: 465px)
    left: calc(100% / 1.36)
  @media screen and (max-width: 425px)
    left: calc(100% / 1.38)
  @media screen and (max-width: 380px)
    left: calc(100% / 1.4)

.tipo-tres
  left: calc(100% / 2)
  @media screen and (max-width: 682px)
    left: calc(100% / 1.87)
  @media screen and (max-width: 535px)
    left: calc(100% / 1.92)
  @media screen and (max-width: 425px)
    left: calc(100% / 1.98)

.resumo-participacao-cidada-text
  color: #4a4a4a
  font-size: 14px

.cards-expand
  display: flex
  flex-wrap: wrap
  gap: 20px
  @media screen and (max-width: 1015px)
    justify-content: center

.z-index-card-01
  z-index: 9
.z-index-card-02
  z-index: 8
.z-index-card-03
  z-index: 7
.z-index-card-04
  z-index: 6
.z-index-card-05
  z-index: 5
.z-index-card-06
  z-index: 4
.z-index-card-07
  z-index: 3
</style>
