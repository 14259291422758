<template>
  <div class="atividades-relacionadas-page">
    <div class="atividades-relacionadas">
      <h1 class="atividades-relacionadas-title">Atividades relacionadas</h1>

      <div class="cards">
        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardUm.cardPoints"
          :cardTitle="cardUm.cardTitle"
          :cardPrimaryTextFirstBlock="cardUm.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardUm.progressUpdatedIn"
          :cardDate="cardUm.cardDate"
          
          :linkTitleUmCard="cardUm.linkTitleUm"
          :linkUmCard="cardUm.linkUm"

          :linkTitleDoisCard="cardDois.linkTitleDois"
          :linkDoisCard="cardDois.linkDois"
        />

        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="true"
          :checkpointsCardType="false"

          :cardPoints="cardQuatro.cardPoints"
          :cardTitle="cardQuatro.cardTitle"
          :cardPrimaryTextFirstBlock="cardQuatro.cardPrimaryTextFirstBlock"
          :cardPrimaryTextSecondBlock="cardQuatro.cardPrimaryTextSecondBlock"
          :cardPrimaryTextThirdBlock="cardQuatro.cardPrimaryTextThirdBlock"
          :progressUpdatedIn="cardQuatro.progressUpdatedIn"
          :cardDate="cardQuatro.cardDate"
          
          :linkTitleUmCard="cardQuatro.linkTitleUm"
          :linkUmCard="cardQuatro.linkUm"

          :linkTitleDoisCard="cardQuatro.linkTitleDois"
          :linkDoisCard="cardQuatro.linkDois"

          :evaluationValueUm="cardQuatro.evaluationValueUm"
          :evaluationValueQuatro="cardQuatro.evaluationValueQuatro"

          :evaluationGoalUm="cardQuatro.evaluationGoalUm"

          :evaluationTextUm="cardQuatro.evaluationTextUm"

          :evaluationDateLimitUm="cardQuatro.evaluationDateLimitUm"
        />

        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardTres.cardPoints"
          :cardTitle="cardTres.cardTitle"
          :cardPrimaryTextFirstBlock="cardTres.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardTres.progressUpdatedIn"
          :cardDate="cardTres.cardDate"
          
          :linkTitleUmCard="cardTres.linkTitleUm"
          :linkUmCard="cardTres.linkUm"
        />

        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="true"

          :cardPoints="cardDois.cardPoints"
          :cardTitle="cardDois.cardTitle"
          :cardPrimaryTextFirstBlock="cardDois.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardDois.progressUpdatedIn"
          :cardDate="cardDois.cardDate"
          
          :linkTitleUmCard="cardDois.linkTitleUm"
          :linkUmCard="cardDois.linkUm"

          :checkpointPointsDotUm="cardDois.checkpointPointsDotUm"
          :checkpointPointsDotDois="cardDois.checkpointPointsDotDois"

          :checkpointTextDotUm="cardDois.checkpointTextDotUm"
          :checkpointTextDotDois="cardDois.checkpointTextDotDois"

          :checkpointDateLimitDotUm="cardDois.checkpointDateLimitDotUm"
          :checkpointDateLimitDotDois="cardDois.checkpointDateLimitDotDois"
        />

        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="true"
          :checkpointsCardType="false"

          :cardPoints="cardCinco.cardPoints"
          :cardTitle="cardCinco.cardTitle"
          :cardPrimaryTextFirstBlock="cardCinco.cardPrimaryTextFirstBlock"
          :cardPrimaryTextSecondBlock="cardCinco.cardPrimaryTextSecondBlock"
          :cardPrimaryTextThirdBlock="cardCinco.cardPrimaryTextThirdBlock"
          :progressUpdatedIn="cardCinco.progressUpdatedIn"
          :cardDate="cardCinco.cardDate"
          
          :linkTitleUmCard="cardCinco.linkTitleUm"
          :linkUmCard="cardCinco.linkUm"

          :evaluationValueUm="cardCinco.evaluationValueUm"
          :evaluationValueQuatro="cardCinco.evaluationValueQuatro"

          :evaluationGoalUm="cardCinco.evaluationGoalUm"

          :evaluationTextUm="cardCinco.evaluationTextUm"

          :evaluationDateLimitUm="cardCinco.evaluationDateLimitUm"
        />
      </div>

      <div class="hide-content">
        <DotsLegends />
      </div>
    </div>
  </div>
</template>

<script>
import CardResultadoSistemico from "./resultados-sistemicos/CardResultadoSistemico.vue"
import DotsLegends from "./dots-legends.vue"

export default {
  name: "AtividadesRelacionadasRS03",
  props: ["dataCards"],
  components: { CardResultadoSistemico, DotsLegends },
  data() {
    return {
      dataCard: null,

      cardUm: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,

        percentageValue: null,
        percentageScoreCurrent: null,
        percentageGoalProps: null,
        percentageScoreGoals: null,
        percentageScoreItem: null,
      },

      cardDois: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,

        linkTitleDois: null,
        linkDois: null,

        checkpointPointsDotUm: null,
        checkpointPointsDotDois: null,

        checkpointTextDotUm: null,
        checkpointTextDotDois: null,

        checkpointDateLimitDotUm: null,
        checkpointDateLimitDotDois: null,
      },

      cardTres: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,

        percentageValue: null,
        percentageScoreCurrent: null,
        percentageGoalProps: null,
        percentageScoreGoals: null,
        percentageScoreItem: null,
      },

      cardQuatro: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        cardPrimaryTextSecondBlock: null,
        cardPrimaryTextThirdBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,
        linkTitleDois: null,
        linkDois: null,

        evaluationValueUm: null,
        evaluationValueQuatro: null,

        evaluationGoalUm: null,

        evaluationTextUm: null,

        evaluationDateLimitUm: null,
      },

      cardCinco: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        cardPrimaryTextSecondBlock: null,
        cardPrimaryTextThirdBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,

        evaluationValueUm: null,
        evaluationValueQuatro: null,

        evaluationGoalUm: null,

        evaluationTextUm: null,

        evaluationDateLimitUm: null,
      },
    };
  },
  watch: {
    dataCards: {
      immediate: true, 
      handler (value) {
        if (value) {
          this.dataCard = value;
          this.setDataCardUm();
          this.setDataCardDois();
          this.setDataCardTres();
          this.setDataCardQuatro();
          this.setDataCardCinco();
        }
      }
    }
  },
  methods: {
    setDataCardUm() {
      this.cardUm.cardPoints = this.dataCard?.general_data?.wash?.points ?? 0;
      this.cardUm.cardTitle = "Escolas realizando autoavaliação das condições de água, saneamento e práticas de higiene";
      this.cardUm.cardPrimaryTextFirstBlock = "Avaliar as condições de água, saneamento e higiene em pelo menos 30% das escolas públicas, identificando necessidades de melhorias sobre práticas de higiene, lavagem de mãos, higiene menstrual e acesso adequado aos serviços de água e saneamento.";
      this.cardUm.progressUpdatedIn = "diariamente";
      this.cardUm.cardDate = "30/06/2022";
      
      this.cardUm.linkTitleUm = "Abrir Atividade";
      this.cardUm.linkUm = "https://pesquisas.buscaativaescolar.org.br/";
    },

    setDataCardDois() {
      this.cardDois.cardPoints = this.dataCard?.general_data?.hands_washing_program.points;
      this.cardDois.cardTitle = "Implantação do Programa de Lavagem de Mãos nas escolas";
      this.cardDois.cardPrimaryTextFirstBlock = "Estabelecer o Programa de Lavagem de Mãos, por meio de portaria municipal, e implementar em pelo menos 10% das escolas municipais (Pré-escola e Anos Iniciais do Ensino Fundamental). O programa tem como objetivo promover a prática de higiene das mãos com o uso de pias, água e sabão.";
      this.cardDois.progressUpdatedIn = "diariamente";
      this.cardDois.cardDate = "31/10/2023";
      
      this.cardDois.linkTitleUm = "Abrir Atividade";
      this.cardDois.linkUm = "https://pesquisas.buscaativaescolar.org.br/";

      this.cardDois.linkTitleDois = "PCJ";
      this.cardDois.linkDois = "https://classroom.google.com/";

      this.cardDois.checkpointPointsDotUm = this.dataCard?.general_data?.hands_washing_program.pcj.points;
      this.cardDois.checkpointPointsDotDois = this.dataCard?.general_data?.hands_washing_program.bae.points;

      this.cardDois.checkpointTextDotUm = "Portaria postada na PCJ implantando o Programa de Lavagem de Mãos.";
      this.cardDois.checkpointTextDotDois = "10% das escolas do município implantando o Programa de Lavagem de Mãos em escolas da rede Municipal na área urbana e rural.";

      this.cardDois.checkpointDateLimitDotUm = "31/10/2023";
      this.cardDois.checkpointDateLimitDotDois = "31/10/2023";
    },

    setDataCardTres() {
      this.cardTres.cardPoints = this.dataCard?.general_data?.checklist?.points ?? 0;
      this.cardTres.cardTitle = "Escolas viabilizando condições de biossegurança por meio de protocolos de enfrentamento à COVID19";
      this.cardTres.cardPrimaryTextFirstBlock = "Aplicar protocolos de prevenção à Covid-19 em pelo menos 30% das Escolas Municipais.";
      this.cardTres.progressUpdatedIn = "diariamente";
      this.cardTres.cardDate = "30/06/2022";
      
      this.cardTres.linkTitleUm = "Abrir Atividade";
      this.cardTres.linkUm = "https://pesquisas.buscaativaescolar.org.br/";
    },

    setDataCardQuatro() {
      this.cardQuatro.cardPoints = this.dataCard?.general_data?.formation?.points ?? 0;
      this.cardQuatro.cardTitle = "Profissionais certificados em cursos de água, saneamento e promoção de higiene nas escolas e unidades de saúde";
      this.cardQuatro.cardPrimaryTextFirstBlock = "Certificar pelo menos 3 servidores(as) públicos(as) das áreas de Educação, Saúde e Assistência Social em água, saneamento e promoção de higiene nas escolas e unidades de saúde para a prevenção e controle da Covid-19.";
      this.cardQuatro.progressUpdatedIn = "diariamente";
      this.cardQuatro.cardDate = "30/06/2022";
      
      this.cardQuatro.linkTitleUm = "Curso 1";
      this.cardQuatro.linkUm = "https://ava.unicef.org.br/course/view.php?id=11";
      this.cardQuatro.linkTitleDois = "Curso 2";
      this.cardQuatro.linkDois = "https://ava.unicef.org.br/course/view.php?id=10";

      this.cardQuatro.evaluationValueUm = this.dataCard?.general_data?.formation?.count ?? 0;
      this.cardQuatro.evaluationValueQuatro = 0; // @to-do: Aplicar quando receber do endpoint.

      this.cardQuatro.evaluationGoalUm = 3;

      this.cardQuatro.evaluationTextUm = "Certificados";

      this.cardQuatro.evaluationDateLimitUm = "30/06/2022";
    },

    setDataCardCinco() {
      this.cardCinco.cardPoints = this.dataCard?.general_data?.second_formation?.points ?? 0;
      this.cardCinco.cardTitle = "Profissionais certificados no curso de Direito Humano, Aspectos Legais, Dignidade Menstrual e Igualdade de Gênero";
      this.cardCinco.cardPrimaryTextFirstBlock = "Certificar pelo menos 3 servidores(as) públicos(as) das áreas de Educação, Saúde e Assistência Social e, sempre que possível, uma pessoa responsável pela prestação do serviço de água e esgoto no município.";
      this.cardCinco.progressUpdatedIn = 'diariamente';
      this.cardCinco.cardDate = "13/01/2023";
      
      this.cardCinco.linkTitleUm = "Abrir Atividade";
      this.cardCinco.linkUm = "https://ava.unicef.org.br/course/view.php?id=27";

      this.cardCinco.evaluationValueUm = this.dataCard?.general_data?.second_formation?.count ?? 0;
      this.cardCinco.evaluationValueQuatro = 0; // @to-do: Aplicar quando receber do endpoint.

      this.cardCinco.evaluationGoalUm = 3;

      this.cardCinco.evaluationTextUm = "Certificados";

      this.cardCinco.evaluationDateLimitUm = "13/01/2023";
    },
  }
};
</script>

<style lang="sass">
.atividades-relacionadas-page
  margin: 3rem auto
  max-width: var(--max-width)

.atividades-relacionadas-title
  border-bottom: 3px solid #1cabe2
  color: #1cabe2
  display: inline-block
  font-size: 20px
  font-weight: 500

.cards
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  margin-top: 32px
  @media screen and (max-width: 1008px)
    display: flex
    justify-content: center
</style>
