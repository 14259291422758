<template>
  <div class="atividades-relacionadas-page">
    <div class="atividades-relacionadas">
      <h1 class="atividades-relacionadas-title">Atividades relacionadas</h1>

      <div class="cards">
        <CardResultadoSistemico
            :percentCardType="false"
            :evaluationsCardType="false"
            :checkpointsCardType="false"

            :cardPoints="cardUm.cardPoints"
            :cardTitle="cardUm.cardTitle"
            :cardPrimaryTextFirstBlock="cardUm.cardPrimaryTextFirstBlock"
            :progressUpdatedIn="cardUm.progressUpdatedIn"
            :cardDate="cardUm.cardDate"
            
            :linkTitleUmCard="cardUm.linkTitleUm"
            :linkUmCard="cardUm.linkUm"
        />

        <CardResultadoSistemico
            :percentCardType="false"
            :evaluationsCardType="true"
            :checkpointsCardType="false"

            :cardPoints="cardDois.cardPoints"
            :cardTitle="cardDois.cardTitle"
            :cardPrimaryTextFirstBlock="cardDois.cardPrimaryTextFirstBlock"
            :progressUpdatedIn="cardDois.progressUpdatedIn"
            :cardDate="cardDois.cardDate"
            
            :linkTitleUmCard="cardDois.linkTitleUm"
            :linkUmCard="cardDois.linkUm"

            :evaluationValueUm="cardDois.evaluationValueUm"
            :evaluationValueQuatro="cardDois.evaluationValueQuatro"

            :evaluationGoalUm="cardDois.evaluationGoalUm"

            :evaluationTextUm="cardDois.evaluationTextUm"

            :evaluationDateLimitUm="cardDois.evaluationDateLimitUm"
        />

        <CardResultadoSistemico
            :percentCardType="false"
            :evaluationsCardType="false"
            :checkpointsCardType="false"

            :cardPoints="cardTres.cardPoints"
            :cardTitle="cardTres.cardTitle"
            :cardPrimaryTextFirstBlock="cardTres.cardPrimaryTextFirstBlock"
            :progressUpdatedIn="cardTres.progressUpdatedIn"
            :cardDate="cardTres.cardDate"
            
            :linkTitleUmCard="cardTres.linkTitleUm"
            :linkUmCard="cardTres.linkUm"
        />
      </div>

      <div class="hide-content">
        <DotsLegends />
      </div>
    </div>
  </div>
</template>

<script>
import CardResultadoSistemico from "./resultados-sistemicos/CardResultadoSistemico.vue"
import DotsLegends from "./dots-legends.vue"

export default {
  name: "AtividadesRelacionadasRS04",
  props: ["dataCards"],
  components: { CardResultadoSistemico, DotsLegends },
  data() {
    return {
      dataCard: null,

      cardUm: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,
      },

      cardDois: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,

        evaluationValueUm: null,
        evaluationValueQuatro: null,

        evaluationGoalUm: null,

        evaluationTextUm: null,

        evaluationDateLimitUm: null,
      },

      cardTres: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,
      },
    };
  },
  watch: {
    dataCards: {
      immediate: true, 
      handler (value) {
        if (value) {
          this.dataCard = value;
          this.setDataCardUm();
          this.setDataCardDois();
          this.setDataCardTres();
        }
      }
    }
  },
  methods: {
    setDataCardUm() {
      this.cardUm.cardPoints = this.dataCard?.general_data?.subscribed;
      this.cardUm.cardTitle = "Adesão à estratégia 1 Milhão de Oportunidades (1MiO)";
      this.cardUm.cardPrimaryTextFirstBlock = "Aderir formalmente à iniciativa 1 Milhão de Oportunidades (1MiO).";
      this.cardUm.progressUpdatedIn = "diariamente";
      this.cardUm.cardDate = "31/05/2022";

      this.cardUm.linkTitleUm = "Abrir Atividade";
      this.cardUm.linkUm = "https://1mio.com.br/register/city-manager";
    },

    setDataCardDois() {
      this.cardDois.cardPoints = this.dataCard?.general_data?.train_public_agents.points;
      this.cardDois.cardTitle = "Profissionais certificados no curso Oportunidades de Educação, Trabalho e Formação Profissional";
      this.cardDois.cardPrimaryTextFirstBlock = "Certificar 4 agentes públicos municipais, incluindo o(a) mobilizador(a) de adolescentes, no curso autoinstrucional Mobilização para Criar Oportunidades de Trabalho Decente, Aprendizagem, Educação e Profissionalização.";
      this.cardDois.progressUpdatedIn = "diariamente";
      this.cardDois.cardDate = "13/01/2023";

      this.cardDois.linkTitleUm = "Abrir Atividade";
      this.cardDois.linkUm = "https://ava.unicef.org.br/course/view.php?id=28";

      this.cardDois.evaluationValueUm = this.dataCard?.general_data?.train_public_agents.total;
      this.cardDois.evaluationValueQuatro = 0; // @to-do: Aplicar quando receber do endpoint. Não vai usar

      this.cardDois.evaluationGoalUm = 4;

      this.cardDois.evaluationTextUm = "Agentes Publicos";

      this.cardDois.evaluationDateLimitUm = "13/01/2023";
    },

    setDataCardTres() {
      this.cardTres.cardPoints = this.dataCard?.general_data?.opportunities;
      this.cardTres.cardTitle = "Número de oportunidades em estágio, aprendizagem e trabalho geradas para adolescentes e jovens";
      this.cardTres.cardPrimaryTextFirstBlock = "Gerar oportunidades de estágio, aprendizagem pela Lei do Aprendiz e trabalho decente para adolescentes e jovens vulneráveis, na faixa etária de 14 a 24 anos, na estrutura administrativa da gestão municipal, de acordo com a legislação vigente.";
      this.cardTres.progressUpdatedIn = "diariamente";
      this.cardTres.cardDate = "30/06/2024";

      this.cardTres.linkTitleUm = "Abrir Atividade";
      this.cardTres.linkUm = "https://1mio.com.br/user/login";
    },
  }
};
</script>

<style lang="sass">
.atividades-relacionadas-page
  margin: 3rem auto
  max-width: var(--max-width)

.atividades-relacionadas-title
  border-bottom: 3px solid #1cabe2
  color: #1cabe2
  display: inline-block
  font-size: 20px
  font-weight: 500

.cards
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  margin-top: 32px
  @media screen and (max-width: 1008px)
    display: flex
    justify-content: center
</style>
