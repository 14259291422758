const Regioes = Object.freeze({
  AMAZONIA_LEGAL: "Amazônia Legal",
  SEMIARIDO: "Semiárido",
});

const Estados = Object.freeze({
  AMAZONIA_LEGAL: {
    ACRE: "Acre (AC)",
    AMAZONAS: "Amazonas (AM)",
    AMAPÁ: "Amapá (AP)",
    MARANHÃO: "Maranhão (MA)",
    MATO_GROSSO: "Mato Grosso (MT)",
    PARÁ: "Pará (PA)",
    RONDÔNIA: "Rondônia (RO)",
    RORAIMA: "Roraima (RR)",
    TOCANTINS: "Tocantins (TO)"
  },
  SEMIARIDO: {
    ALAGOAS: "Alagoas (AL)",
    BAHIA: "Bahia (BA)",
    CEARA: "Ceará (CE)",
    MINAS_GERAIS: "Minas Gerais (MG)",
    PARAÍBA: "Paraíba (PB)",
    PERNAMBUCO: "Pernambuco (PE)",
    PIAUÍ: "Piauí (PI)",
    RIO_GRANDE_DO_NORTE: "Rio Grande do Norte (RN)",
    SERGIPE: "Sergipe (SE)"
  }
});

const PrimeiraOpcao = Object.freeze({
  REGIAO: "Região",
  ESTADO: "Estado",
  MUNICIPIO: "Município"
})

export default {
  Regioes,
  Estados,
  PrimeiraOpcao
};
