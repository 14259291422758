<template>
  <div class="content" :class="contentStyle">
    <div class="number">{{ points }}</div>

    <div class="text">
      O município precisa atingir a meta de 6 pontos dentro de um total de 8 pontos.
    </div>

    <div class="bar-points">
      <span :class="progressTextStyle">{{ points }} pontos</span>
      <div class="progress" :class="progressStyle" :style="{ width: pointsWidth + '%' }"></div>
    </div>
  </div>
</template>

<script>
import GeneralsUtil from "../utils/generals-util";

export default {
  name: "PontuacaoParticipacaoCidada",
  props: ["points", "isOverDate"],
  components: {},
  data() {
    return {
      pointsWidth: 0,
      progressStyle: null,
      contentStyle: null,
      progressTextStyle: null,
      dateLimit: "30/06/2024",
    };
  },
  watch: {
    points: {
      immediate: true, 
      handler (value) {
        if (value) {
          this.hasCardOverDate();
          this.calcProgressBar();
          this.setProgressBar();
        }
      }
    }
  },
  mounted() {
    this.hasCardOverDate();
    this.calcProgressBar();
    this.setProgressBar();
  },
  methods: {
    calcProgressBar() {
      switch (this.points) {
        case 1: this.pointsWidth = 12.5
          break;
        case 2: this.pointsWidth = 25
          break;
        case 3: this.pointsWidth = 37.5
          break;
        case 4: this.pointsWidth = 50
          break;
        case 5: this.pointsWidth = 62.5
          break;
        case 6: this.pointsWidth = 75
          break;
        case 7: this.pointsWidth = 87.5
          break;
        case 8: this.pointsWidth = 100
          break;

        default:
          break;
      }
    },
    setProgressBar() {
      if (this.points < 6 && this.hasCardOverDate()) {
        this.contentStyle = "content-red";
        this.progressStyle = "progress-red";
        this.progressTextStyle = "progress-text-white";
      }

      if (this.points < 6 && !this.hasCardOverDate()) {
        this.contentStyle = "content-yellow";
        this.progressStyle = "progress-yellow";
      }

      if (this.points >= 6) {
        this.contentStyle = "content-blue";
        this.progressStyle = "progress-blue";
        this.progressTextStyle = "progress-text-white";
      }
    },
    hasCardOverDate() {
      if (
          GeneralsUtil.isDateOver(this.dateLimit)
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.content
  align-items: center
  border-radius: 5px
  box-shadow: 0px 3px 6px #00000029
  display: flex
  flex-direction: column
  height: 235px
  justify-content: center

.number
  font-size: 80px
  margin-bottom: 30px

.text
  color: #4a4a4a
  font-size: 14px
  margin: 0 20px
  margin-bottom: 16px
  text-align: left
  font-weight: 500

.bar-points
  background-color: #bbB9b9
  border-radius: 17px
  height: 33px
  overflow: hidden
  width: 202px
  @media screen and (max-width: 1108px)
    width: 500px
  @media screen and (max-width: 585px)
    width: 300px

.bar-points > span
  color: #4a4a4a
  display: block
  margin-top: 5px

.progress
  background-color: red
  height: 33px
  margin-top: -25px

.content-red
  border: 3px solid #e2231a
  color: #e2231a

.content-yellow
  border: 3px solid #ffc20e
  color: #ffc20e

.content-blue
  border: 3px solid #1cabe2
  color: #1cabe2

.progress-red
  background-color: #e2231a

.progress-yellow
  background-color: #ffc20e

.progress-blue
  background-color: #1cabe2

.progress-text-white
  color: #ffffff !important
</style>
