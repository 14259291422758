<template>
  <div class="card-expand" :class="cardExpandStyle">
    <div class="expand-content" :class="expandContentStyle">
      <div class="expand-text">{{ cardName }}</div>
      <div class="expand-numbers">
        <div class="expand-numbers-number">{{ cardPoints }}</div>
        <div class="expand-numbers-text">pontos</div>
      </div>
    </div>

    <div class="footer" :class="footerStyle">
      <div>
        <span v-if="cardPoints == 0">Indicador abaixo da meta</span>
        <span v-if="cardPoints == 2">Desenvolvimento está positivo</span>
        <span v-if="cardPoints == 1">Indicador está piorando</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardExpandIndicadoresSociais",
  props: [
    "cardName",
    "cardPoints",
  ],
  components: {},
  data() {
    return {
      expanded: false,
      cardExpandStyle: null,
      expandContentStyle: null,
      footerStyle: null,
    };
  },
  mounted() {
    this.setCardStyles();
  },
  methods: {
    setCardStyles() {
      if (this.cardPoints == 2) {
        this.cardExpandStyle = "card-expand-meta-positivo";
        this.expandContentStyle = "expand-content-meta-positivo";
        this.footerStyle = "footer-meta-positivo";
      }

      if (this.cardPoints == 1) {
        this.cardExpandStyle = "card-expand-meta-piorando";
        this.expandContentStyle = "expand-content-meta-piorando";
        this.footerStyle = "footer-meta-piorando";
      }

      if (this.cardPoints == 0) {
        this.cardExpandStyle = "card-expand-meta-abaixo";
        this.expandContentStyle = "expand-content-meta-abaixo";
        this.footerStyle = "footer-meta-abaixo";
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.card-expand
  border-radius: 4px
  box-shadow: 0px 3px 6px #00000029
  height: 100%
  width: 308px

.expand-content
  align-items: center
  border-radius: 4px
  color: #ffffff
  display: flex
  height: 100px
  justify-content: space-between
  padding: 10px
  user-select: none
  -moz-user-select: none
  -khtml-user-select: none
  -webkit-user-select: none
  -o-user-select: none

.card-expand-meta-positivo
  background-color: #1cabe2 !important
  color: #1cabe2 !important

.card-expand-meta-piorando
  background-color: #f26a21 !important
  color: #f26a21 !important

.card-expand-meta-abaixo
  background-color: #ffc20e !important
  color: #ffc20e !important

.expand-content-meta-positivo
  background-color: #1cabe2 !important
  border: 1px solid #1cabe2 !important

.expand-content-meta-piorando
  background-color: #f26a21 !important
  border: 1px solid #f26a21 !important

.expand-content-meta-abaixo
  background-color: #ffc20e !important
  border: 1px solid #ffc20e !important
  color: #000000

.footer-meta-positivo
  border: 2px solid #1cabe2 !important

.footer-meta-piorando
  border: 2px solid #f26a21 !important

.footer-meta-abaixo
  border: 2px solid #ffc20e !important

.expand-content-on
  border: 1px solid #ffffff !important

.expand-text
  font-size: 16px
  max-width: 220px

.expand-numbers
  margin-top: 10px
  text-align: right

.expand-numbers-number
  font-size: 50px
  margin-bottom: 8px

.expand-numbers-text
  font-size: 12px
  line-height: 2
  text-transform: uppercase

.footer
  align-items: center
  background-color: #ffffff
  border-radius: 4px
  color: #4a4a4a
  display: flex
  height: 40px
  justify-content: center
</style>
