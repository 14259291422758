<template>
  <div class="atividades-relacionadas-page">
    <div class="atividades-relacionadas">
      <h1 class="atividades-relacionadas-title">Atividades relacionadas</h1>

      <div class="cards">
        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardDois.cardPoints"
          :cardTitle="cardDois.cardTitle"
          :cardPrimaryTextFirstBlock="cardDois.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardDois.progressUpdatedIn"
          :cardDate="cardDois.cardDate"
          
          :linkTitleUmCard="cardDois.linkTitleUm"
          :linkUmCard="cardDois.linkUm"
        />

        <CardResultadoSistemico v-if="citySize === 1"
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="true"

          :cardPoints="cardUm.cardPoints"
          :cardTitle="cardUm.cardTitle"
          :cardPrimaryTextFirstBlock="cardUm.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardUm.progressUpdatedIn"
          :cardDate="cardUm.cardDate"
          
          :linkTitleUmCard="cardUm.linkTitleUm"
          :linkUmCard="cardUm.linkUm"

          :checkpointPointsDotUm="cardUm.checkpointPointsDotUm"
          :checkpointPointsDotDois="cardUm.checkpointPointsDotDois"

          :checkpointTextDotUm="cardUm.checkpointTextDotUm"
          :checkpointTextDotDois="cardUm.checkpointTextDotDois"

          :checkpointDateLimitDotUm="cardUm.checkpointDateLimitDotUm"
          :checkpointDateLimitDotDois="cardUm.checkpointDateLimitDotDois"
        />

        <CardResultadoSistemico v-if="citySize === 2"
          :percentCardType="true"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardTres.cardPoints"
          :cardTitle="cardTres.cardTitle"
          :cardPrimaryTextFirstBlock="cardTres.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardTres.progressUpdatedIn"
          :cardDate="cardTres.cardDate"
          
          :linkTitleUmCard="cardTres.linkTitleUm"
          :linkUmCard="cardTres.linkUm"

          :percentageValue="cardTres.percentageValue"
          :percentageScoreCurrent="cardTres.percentageScoreCurrent"
          :percentageGoalProps="cardTres.percentageGoalProps"
          :percentageScoreGoals="cardTres.percentageScoreGoals"
          :percentageScoreItem="cardTres.percentageScoreItem"
        />
      </div>

    </div>
  </div>
</template>

<script>
import CardResultadoSistemico from "./resultados-sistemicos/CardResultadoSistemico.vue"

export default {
  name: "AtividadesRelacionadasRS07",
  props: ["dataCards"],
  components: { CardResultadoSistemico },
  data() {
    return {
      dataCard: null,
      citySize: null,

      cardUm: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,

        checkpointPointsDotUm: null,
        checkpointPointsDotDois: null,

        checkpointTextDotUm: null,
        checkpointTextDotDois: null,

        checkpointDateLimitDotUm: null,
        checkpointDateLimitDotDois: null,
      },

      cardDois: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,
      },

      cardTres: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,

        percentageValue: null,
        percentageScoreCurrent: null,
        percentageGoalProps: null,
        percentageScoreGoals: null,
        percentageScoreItem: null,
      },
    };
  },
  watch: {
    dataCards: {
      immediate: true, 
      handler (value) {
        if (value) {
          this.dataCard = value;
          this.citySize = this.dataCard?.general_data?.cras_education_health.is_small_city ? 1 : 2;
          this.setDataCardUm();
          this.setDataCardDois();
          this.setDataCardTres();
        }
      }
    }
  },
  methods: {
    setDataCardUm() {
      this.cardUm.cardPoints = this.dataCard?.general_data?.cras_education_health.points;
      this.cardUm.cardTitle = "Centro de Referência da Assistência Social (CRAS) em articulação com os serviços de Educação e Saúde (Censo SUAS)";
      this.cardUm.cardPrimaryTextFirstBlock = "Garantir que pelo menos 1 CRAS esteja em forte articulação com os serviços de Educação e Saúde.";
      this.cardUm.progressUpdatedIn = 'diariamente';
      this.cardUm.cardDate = "30/06/2024";
      
      this.cardUm.linkTitleUm = "Abrir Atividade";
      this.cardUm.linkUm = "https://aplicacoes.mds.gov.br/sagirmps/censocidadania/index.php";

      this.cardUm.checkpointDateLimitDotUm = "30/06/2024";
      this.cardUm.checkpointDateLimitDotDois = "30/06/2024";
    },

    setDataCardDois() {
      this.cardDois.cardPoints = this.dataCard?.general_data?.ldo_course_work.points;
      this.cardDois.cardTitle = "Lei de Diretrizes Orçamentárias (LDO) Estabelecendo Prioridade ao SUAS";
      this.cardDois.cardPrimaryTextFirstBlock = "Apresentar ao poder legislativo pelo menos uma Lei de Diretrizes Orçamentárias (LDO) municipal no período de 2022 a 2024, estabelecendo prioridade ao Sistema Único de Assistência Social (SUAS) no corpo da lei e no Anexo de prioridades e Metas.";
      this.cardDois.progressUpdatedIn = "diariamente";
      this.cardDois.cardDate = "30/06/2024";
      
      this.cardDois.linkTitleUm = "Abrir Atividade";
      this.cardDois.linkUm = "https://classroom.google.com/";
    },

    setDataCardTres() {
      this.cardTres.cardPoints = this.dataCard?.general_data?.cras_education_health?.points;
      this.cardTres.cardTitle = "Centro de Referência da Assistência Social (CRAS) em articulação com os serviços de Educação, Saúde e Proteção Especial (Censo SUAS)";
      this.cardTres.cardPrimaryTextFirstBlock = "CRAS com forte articulação com educação, saúde e proteção especial";
      this.cardTres.progressUpdatedIn = null;
      this.cardTres.cardDate = "30/06/2024";
      
      this.cardTres.linkTitleUm = "Abrir Atividade";
      this.cardTres.linkUm = "https://aplicacoes.mds.gov.br/sagirmps/censocidadania/index.php";

      const percentage = this.dataCard?.general_data?.cras_education_health?.percentage ?? 0;
      this.cardTres.percentageValue = percentage.toFixed(0);
      this.cardTres.percentageScoreCurrent = null;
      this.cardTres.percentageGoalProps = 20;
      this.cardTres.percentageScoreGoals = null;
      this.cardTres.percentageScoreItem = "escolas";
    },
  }
};
</script>

<style lang="sass">
.atividades-relacionadas-page
  margin: 3rem auto
  max-width: var(--max-width)

.atividades-relacionadas-title
  border-bottom: 3px solid #1cabe2
  color: #1cabe2
  display: inline-block
  font-size: 20px
  font-weight: 500

.cards
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  margin-top: 32px
  @media screen and (max-width: 1008px)
    display: flex
    justify-content: center
</style>