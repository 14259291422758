<template>
  <div class="atividades-relacionadas-page">
    <div class="atividades-relacionadas">
      <h1 class="atividades-relacionadas-title">Atividades relacionadas</h1>

      <div class="cards">
        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="true"

          :cardPoints="cardUm.cardPoints"
          :cardTitle="cardUm.cardTitle"
          :cardPrimaryTextFirstBlock="cardUm.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardUm.progressUpdatedIn"
          :cardDate="cardUm.cardDate"
          
          :linkTitleUmCard="cardUm.linkTitleUm"
          :linkUmCard="cardUm.linkUm"

          :checkpointPointsDotUm="cardUm.checkpointPointsDotUm"
          :checkpointPointsDotDois="cardUm.checkpointPointsDotDois"

          :checkpointTextDotUm="cardUm.checkpointTextDotUm"
          :checkpointTextDotDois="cardUm.checkpointTextDotDois"

          :checkpointDateLimitDotUm="cardUm.checkpointDateLimitDotUm"
          :checkpointDateLimitDotDois="cardUm.checkpointDateLimitDotDois"
        />

        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardDois.cardPoints"
          :cardTitle="cardDois.cardTitle"
          :cardPrimaryTextFirstBlock="cardDois.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardDois.progressUpdatedIn"
          :cardDate="cardDois.cardDate"
          
          :linkTitleUmCard="cardDois.linkTitleUm"
          :linkUmCard="cardDois.linkUm"
        />
      </div>

      <div>
        <DotsLegends />
      </div>
    </div>
  </div>
</template>

<script>
import CardResultadoSistemico from "./resultados-sistemicos/CardResultadoSistemico.vue"
import DotsLegends from "./dots-legends.vue"

export default {
  name: "AtividadesRelacionadasRS06",
  props: ["dataCards"],
  components: { CardResultadoSistemico, DotsLegends },
  data() {
    return {
      dataCard: null,

      cardUm: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,

        checkpointPointsDotUm: null,
        checkpointPointsDotDois: null,

        checkpointTextDotUm: null,
        checkpointTextDotDois: null,

        checkpointDateLimitDotUm: null,
        checkpointDateLimitDotDois: null,
      },

      cardDois: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,
      },
    };
  },
  watch: {
    dataCards: {
      immediate: true, 
      handler (value) {
        if (value) {
          this.dataCard = value;
          this.setDataCardUm();
          this.setDataCardDois();
        }
      }
    }
  },
  methods: {
    setDataCardUm() {
      this.cardUm.cardPoints = this.dataCard?.general_data?.violence_prevention_campaign.points;
      this.cardUm.cardTitle = "Implementação de ações e/ou campanhas de prevenção e resposta às violências";
      this.cardUm.cardPrimaryTextFirstBlock = "Realizar, em 2022 e 2023, 5 ações e/ou campanhas de prevenção da violência contra crianças e adolescentes (ex: Maio Laranja, Trabalho Infantil, Menino Bernardo, Enfrentamento ao Racismo e outras).";
      this.cardUm.progressUpdatedIn = "diariamente";
      this.cardUm.cardDate = "12/01/2024";
      
      this.cardUm.linkTitleUm = "Abrir Atividade";
      this.cardUm.linkUm = "https://classroom.google.com/";

      this.cardUm.checkpointPointsDotUm = this.dataCard?.general_data?.violence_prevention_campaign.first.points;
      this.cardUm.checkpointPointsDotDois = this.dataCard?.general_data?.violence_prevention_campaign.second.points;

      this.cardUm.checkpointTextDotUm = "Realizar 5 Ações em 2022";
      this.cardUm.checkpointTextDotDois = "Realizar 5 Ações em 2023";

      this.cardUm.checkpointDateLimitDotUm = "13/01/2022";
      this.cardUm.checkpointDateLimitDotDois = "13/01/2023";
    },

    setDataCardDois() {
      this.cardDois.cardPoints = this.dataCard?.general_data?.coordination_mechanism_course_work.points;
      this.cardDois.cardTitle = "Implementação da Lei da Escuta Protegida (Lei nº 13.431/2017)";
      this.cardDois.cardPrimaryTextFirstBlock = "Implementar a lei nº 13.431/2017 e o decreto nº 9603/2018 por meio da criação do mecanismo de coordenação, elaboração dos fluxos de atendimento e elaboração do protocolo único para crianças e adolescentes vítimas ou testemunhas de violência.";
      this.cardDois.progressUpdatedIn = "diariamente";
      this.cardDois.cardDate = "31/03/2024";

      this.cardDois.linkTitleUm = "Abrir Atividade";
      this.cardDois.linkUm = "https://pesquisas.unicef.org.br/pesquisa/34";
    },
  }
};
</script>

<style lang="sass">
.atividades-relacionadas-page
  margin: 3rem auto
  max-width: var(--max-width)

.atividades-relacionadas-title
  border-bottom: 3px solid #1cabe2
  color: #1cabe2
  display: inline-block
  font-size: 20px
  font-weight: 500

.cards
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  margin-top: 32px
  @media screen and (max-width: 1008px)
    display: flex
    justify-content: center
</style>
