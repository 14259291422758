<template>
  <div class="card-expand" :class="cardExpandStyle">
    <div
        class="expand-content"
        :class="[expandContentOn, expandContentStyle]"
        v-on:click="expandRestrict()"
    >
      <div class="expand-text">{{ cardName }}</div>
      <div class="expand-numbers">
        <div class="expand-numbers-number">{{ cardPoints }}</div>
        <div class="expand-numbers-text" v-if="cardPoints == 1">ponto</div>
        <div class="expand-numbers-text" v-if="cardPoints != 1">pontos</div>
      </div>
    </div>

    <div
        v-if="!expanded"
        class="restricted-footer"
        :class="[restrictFooter, restrictedFooterStyle]"
    >
      <span v-if="amountActivities > 1" class="empty"></span>
      <span v-if="amountActivities > 0">
        {{ completeActivitiesCard }}/{{ amountActivities }} atividades foram realizadas
      </span>
      <span
          v-if="amountActivities > 0"
          class="material-icons md-18"
          v-on:click="expandRestrict()"
      >
        keyboard_arrow_down
      </span>

      <div>
        <span v-if="amountActivities < 1">Indicador abaixo da meta</span>
      </div>
    </div>
    <div v-if="expanded" class="expanded-footer">
      <div v-if="itemUmDate" class="item-um">
        <div class="item-title">{{ itemUmTitle }}</div>
        <div class="item-data">
          <div class="item-data-message" :class="labelStatusStyle.itemUm">{{ labelStatus.itemUm }}</div>
          <div class="item-data-prazo">Prazo: {{ datas.itemUm }}</div>
        </div>
      </div>

      <div v-if="itemDoisDate" class="item-dois">
        <div class="item-title">{{ itemDoisTitle }}</div>
        <div class="item-data">
          <div class="item-data-message" :class="labelStatusStyle.itemDois">{{ labelStatus.itemDois }}</div>
          <div class="item-data-prazo">Prazo: {{ datas.itemDois }}</div>
        </div>
      </div>

      <div v-if="itemTresDate" class="item-tres">
        <div class="item-title">{{ itemTresTitle }}</div>
        <div class="item-data">
          <div class="item-data-message" :class="labelStatusStyle.itemTres">{{ labelStatus.itemTres }}</div>
          <div class="item-data-prazo">Prazo: {{ datas.itemTres }}</div>
        </div>
      </div>

      <div v-if="itemQuatroDate" class="item-quatro">
        <div class="item-title">{{ itemQuatroTitle }}</div>
        <div class="item-data">
          <div class="item-data-message" :class="labelStatusStyle.itemQuatro">{{ labelStatus.itemQuatro }}</div>
          <div class="item-data-prazo">Prazo: {{ datas.itemQuatro }}</div>
        </div>
      </div>

      <div v-if="itemCincoDate" class="item-cinco">
        <div class="item-title">{{ itemCincoTitle }}</div>
        <div class="item-data">
          <div class="item-data-message" :class="labelStatusStyle.itemCinco">{{ labelStatus.itemCinco }}</div>
          <div class="item-data-prazo">Prazo: {{ datas.itemCinco }}</div>
        </div>
      </div>

      <div class="arrow-restrict" v-on:click="expandRestrict()">
        <span class="material-icons md-18">expand_less</span>
      </div>
    </div>

    <div class="bg-expanded" v-if="expanded"></div>
  </div>
</template>

<script>
import GeneralsUtil from "./../../utils/generals-util"

export default {
  name: "CardExpandParticipacaoCidada",
  props: [
    "cardName", "cardPoints", "completeActivities", "amountActivities", "cardUmDate", "cardDoisDate", "cardTresDate",
    "cardQuatroDate",

    "itemUmPoints", "itemUmTitle", "itemUmStatus", "itemUmDate",
    "itemDoisPoints", "itemDoisTitle", "itemDoisStatus", "itemDoisDate",
    "itemTresPoints", "itemTresTitle", "itemTresStatus", "itemTresDate",
    "itemQuatroPoints", "itemQuatroTitle", "itemQuatroStatus", "itemQuatroDate",
    "itemCincoPoints", "itemCincoTitle", "itemCincoStatus", "itemCincoDate",
  ],
  components: {},
  data() {
    return {
      expanded: false,
      restrictFooter: null,
      expandContentOn: null,
      pointNumber: 0,

      cardExpandStyle: null,
      expandContentStyle: null,
      restrictedFooterStyle: null,
      completeActivitiesCard: 0,

      datas: {
        itemUm: null,
        itemDois: null,
        itemTres: null,
        itemQuatro: null,
        itemCinco: null,
      },

      labelDatas: {
        itemUm: null,
        itemDois: null,
        itemTres: null,
        itemQuatro: null,
        itemCinco: null,
      },

      labelStatusStyle: {
        itemUm: null,
        itemDois: null,
        itemTres: null,
        itemQuatro: null,
        itemCinco: null,
      },

      labelStatus: {
        itemUm: null,
        itemDois: null,
        itemTres: null,
        itemQuatro: null,
        itemCinco: null,
      },
    };
  },
  mounted() {
    this.pointNumber = this.cardPoints == null ? 0 : this.cardPoints;
    this.datasPorExtenso();
    this.setStyleCards();
    this.setLabelDataStyle();
    this.setAmountComplete();
  },
  methods: {
    expandRestrict() {
      if (this.amountActivities < 1) return;

      this.expanded = !this.expanded;

      if (this.expanded) {
        this.restrictFooter = "restricted-footer-hide";
        this.expandContentOn = "expand-content-on";
      }

      if (!this.expanded) {
        this.restrictFooter = "";
        this.expandContentOn = "";
      }
    },
    datasPorExtenso() {
      this.datas.itemUm = this.itemUmDate ? GeneralsUtil.monthFull(this.itemUmDate) : null;
      this.datas.itemDois = this.itemDoisDate ? GeneralsUtil.monthFull(this.itemDoisDate) : null;
      this.datas.itemTres = this.itemTresDate ? GeneralsUtil.monthFull(this.itemTresDate) : null;
      this.datas.itemQuatro = this.itemQuatroDate ? GeneralsUtil.monthFull(this.itemQuatroDate) : null;
      this.datas.itemCinco = this.itemCincoDate ? GeneralsUtil.monthFull(this.itemCincoDate) : null;
    },
    setStyleCards() {
      const itemUmOverDate = this.cardUmDate ? GeneralsUtil.isDateOver(this.cardUmDate) : false;
      const itemDoisOverDate = this.cardDoisDate ? GeneralsUtil.isDateOver(this.cardDoisDate) : false;
      const itemTresOverDate = this.cardTresDate ? GeneralsUtil.isDateOver(this.cardTresDate) : false;
      const itemQuatroOverDate = this.cardQuatroDate ? GeneralsUtil.isDateOver(this.cardQuatroDate) : false;

      if(this.pointNumber == 2) {
        this.cardExpandStyle = "card-expand-concluido-no-prazo";
        this.expandContentStyle = "expand-content-concluido-no-prazo";
        this.restrictedFooterStyle = "restricted-footer-concluido-no-prazo";
      }

      if(this.pointNumber == 1) {
        this.cardExpandStyle = "card-expand-concluido-fora-do-prazo";
        this.expandContentStyle = "expand-content-concluido-fora-do-prazo";
        this.restrictedFooterStyle = "restricted-footer-concluido-fora-do-prazo";
      }

      if(
          this.pointNumber == 0 &&
          (itemUmOverDate === false && itemDoisOverDate === false && itemTresOverDate === false && itemQuatroOverDate === false)
      ) {
        this.cardExpandStyle = "card-expand-em-progresso";
        this.expandContentStyle = "expand-content-em-progresso";
        this.restrictedFooterStyle = "restricted-footer-em-progresso";
      }

      if(
          this.pointNumber == 0 &&
          (itemUmOverDate === true || itemDoisOverDate === true || itemTresOverDate === true || itemQuatroOverDate === true)
      ) {
        this.cardExpandStyle = "card-expand-em-atraso";
        this.expandContentStyle = "expand-content-em-atraso";
        this.restrictedFooterStyle = "restricted-footer-em-atraso";
      }
    },
    setLabelDataStyle() {
      // UM
      if (this.itemUmPoints == 2) {
        this.labelStatusStyle.itemUm = "item-data-message-sucesso";
        this.labelStatus.itemUm = "Concluído com sucesso";
      }

      if (this.itemUmPoints == 1) {
        this.labelStatusStyle.itemUm = "item-data-message-concluido-fora-prazo";
        this.labelStatus.itemUm = "Realizado fora do prazo";
      }

      if (this.itemUmPoints == 0 && !GeneralsUtil.isDateOver(this.itemUmDate)) {
        this.labelStatusStyle.itemUm = "item-data-message-progresso";
        this.labelStatus.itemUm = "Em progresso";
      }

      if (this.itemUmPoints == 0 && GeneralsUtil.isDateOver(this.itemUmDate)) {
        this.labelStatusStyle.itemUm = "item-data-message-atraso";
        this.labelStatus.itemUm = "Em atraso";
      }

      // DOIS
      if (this.itemDoisPoints == 2) {
        this.labelStatusStyle.itemDois = "item-data-message-sucesso";
        this.labelStatus.itemDois = "Concluído com sucesso";
      }

      if (this.itemDoisPoints == 1) {
        this.labelStatusStyle.itemDois = "item-data-message-concluido-fora-prazo";
        this.labelStatus.itemDois = "Realizado fora do prazo";
      }

      if (this.itemDoisPoints == 0 && !GeneralsUtil.isDateOver(this.itemDoisDate)) {
        this.labelStatusStyle.itemDois = "item-data-message-progresso";
        this.labelStatus.itemDois = "Em progresso";
      }

      if (this.itemDoisPoints == 0 && GeneralsUtil.isDateOver(this.itemDoisDate)) {
        this.labelStatusStyle.itemDois = "item-data-message-atraso";
        this.labelStatus.itemDois = "Em atraso";
      }

      // TRES
      if (this.itemTresPoints == 2) {
        this.labelStatusStyle.itemTres = "item-data-message-sucesso";
        this.labelStatus.itemTres = "Concluído com sucesso";
      }

      if (this.itemTresPoints == 1) {
        this.labelStatusStyle.itemTres = "item-data-message-concluido-fora-prazo";
        this.labelStatus.itemTres = "Realizado fora do prazo";
      }

      if (this.itemTresPoints == 0 && !GeneralsUtil.isDateOver(this.itemTresDate)) {
        this.labelStatusStyle.itemTres = "item-data-message-progresso";
        this.labelStatus.itemTres = "Em progresso";
      }

      if (this.itemTresPoints == 0 && GeneralsUtil.isDateOver(this.itemTresDate)) {
        this.labelStatusStyle.itemTres = "item-data-message-atraso";
        this.labelStatus.itemTres = "Em atraso";
      }

      // QUATRO
      if (this.itemQuatroPoints == 2) {
        this.labelStatusStyle.itemQuatro = "item-data-message-sucesso";
        this.labelStatus.itemQuatro = "Concluído com sucesso";
      }

      if (this.itemQuatroPoints == 1) {
        this.labelStatusStyle.itemQuatro = "item-data-message-concluido-fora-prazo";
        this.labelStatus.itemQuatro = "Realizado fora do prazo";
      }

      if (this.itemQuatroPoints == 0 && !GeneralsUtil.isDateOver(this.itemQuatroDate)) {
        this.labelStatusStyle.itemQuatro = "item-data-message-progresso";
        this.labelStatus.itemQuatro = "Em progresso";
      }

      if (this.itemQuatroPoints == 0 && GeneralsUtil.isDateOver(this.itemQuatroDate)) {
        this.labelStatusStyle.itemQuatro = "item-data-message-atraso";
        this.labelStatus.itemQuatro = "Em atraso";
      }

      // CINCO
      if (this.itemCincoPoints == 2) {
        this.labelStatusStyle.itemCinco = "item-data-message-sucesso";
        this.labelStatus.itemCinco = "Concluído com sucesso";
      }

      if (this.itemCincoPoints == 1) {
        this.labelStatusStyle.itemCinco = "item-data-message-concluido-fora-prazo";
        this.labelStatus.itemCinco = "Realizado fora do prazo";
      }

      if (this.itemCincoPoints == 0 && !GeneralsUtil.isDateOver(this.itemCincoDate)) {
        this.labelStatusStyle.itemCinco = "item-data-message-progresso";
        this.labelStatus.itemCinco = "Em progresso";
      }

      if (this.itemCincoPoints == 0 && GeneralsUtil.isDateOver(this.itemCincoDate)) {
        this.labelStatusStyle.itemCinco = "item-data-message-atraso";
        this.labelStatus.itemCinco = "Em atraso";
      }
    },
    setAmountComplete() {
      Object.values(this.labelStatus).forEach(status => {
        if (status == "Concluído com sucesso" || status == "Realizado fora do prazo") {
          this.completeActivitiesCard = ++this.completeActivitiesCard;
        }
      });
    }
  }
};
</script>

<style lang="sass" scoped>
.card-expand
  background-color: #1cabe2
  border-radius: 4px
  box-shadow: 0px 3px 6px #00000029
  color: #1cabe2
  height: 100%
  width: 308px

.expand-content
  align-items: center
  background-color: #1cabe2
  border: 1px solid #1cabe2
  border-radius: 4px
  color: #ffffff
  cursor: pointer
  display: flex
  height: 100px
  justify-content: space-between
  padding: 10px
  user-select: none
  -moz-user-select: none
  -khtml-user-select: none
  -webkit-user-select: none
  -o-user-select: none

.expand-content-on
  border: 1px solid #ffffff

.expand-text
  font-size: 16px
  max-width: 220px

.expand-numbers
  margin-top: 10px
  text-align: right

.expand-numbers-number
  font-size: 50px
  margin-bottom: 8px

.expand-numbers-text
  font-size: 12px
  line-height: 2
  text-transform: uppercase

.restricted-footer
  align-items: center
  background-color: #ffffff
  border: 2px solid #1cabe2
  border-radius: 4px
  color: #4a4a4a
  display: flex
  height: 40px
  justify-content: center

.restricted-footer-hide
  display: none

.empty,
.material-icons
  cursor: pointer
  font-weight: 600
  width: 27px

.expanded-footer
  align-items: center
  background-color: #9c9c9c
  border-radius: 0 0 4px 4px
  display: flex
  flex-direction: column
  padding: 10px 10px 0 10px
  position: absolute
  width: 308px

.item-um,
.item-dois,
.item-tres,
.item-quatro,
.item-cinco
  background-color: #ffffff
  border-radius: 4px
  color: #4a4a4a
  display: flex
  flex-direction: column
  min-height: 70px
  justify-content: space-between
  margin-bottom: 10px
  width: 100%

.item-title
  align-items: center
  color: #4a4a4a
  display: flex
  font-size: 12px
  font-weight: 500
  justify-content: center
  min-height: 45px
  padding: 0 9px
  text-align: center
  width: 100%

.item-data
  display: flex
  height: 26px

.item-data-message
  align-items: center
  background-color: #f26a21
  border: 1px solid #ffffff
  border-radius: 4px
  color: #ffffff
  display: flex
  font-size: 9px
  justify-content: center
  width: 50%

.item-data-message-sucesso
  background-color: #1cabe2

.item-data-message-progresso
  background-color: #ffc20e
  color: #000000

.item-data-message-concluido-fora-prazo
  background-color: #f26a21

.item-data-message-atraso
  background-color: #e2231a

.item-data-prazo
  align-items: center
  display: flex
  font-size: 9px
  justify-content: center
  width: 50%

.arrow-restrict
  align-items: center
  background-color: #ffffff
  border-radius: 50%
  box-shadow: 0px 3px 6px #00000029
  color: #4a4a4a
  cursor: pointer
  display: flex
  height: 37px
  justify-content: center
  position: relative
  top: 15px
  width: 37px

.arrow-restrict .material-icons
  align-items: center
  display: flex
  font-size: 35px
  font-weight: 400
  justify-content: center

.bg-expanded
  padding-bottom: 40px

// ESTILO POR PONTUAÇÃO/DATA

.card-expand-concluido-no-prazo
  background-color: #1cabe2
  color: #1cabe2

.expand-content-concluido-no-prazo
  background-color: #1cabe2
  border: 1px solid #1cabe2

.restricted-footer-concluido-no-prazo
  border: 2px solid #1cabe2

.card-expand-concluido-fora-do-prazo
  background-color: #f26a21
  color: #f26a21

.expand-content-concluido-fora-do-prazo
  background-color: #f26a21
  border: 1px solid #f26a21

.restricted-footer-concluido-fora-do-prazo
  border: 2px solid #f26a21

.card-expand-em-progresso
  background-color: #ffc20e
  color: #ffc20e

.expand-content-em-progresso
  background-color: #ffc20e
  border: 1px solid #ffc20e
  color: #000000

.restricted-footer-em-progresso
  border: 2px solid #ffc20e

.card-expand-em-atraso
  background-color: #e2231a
  color: #e2231a

.expand-content-em-atraso
  background-color: #e2231a
  border: 1px solid #e2231a

.restricted-footer-em-atraso
  border: 2px solid #e2231a
</style>
