import Vue from "vue";
import Router from "vue-router";

import Mapa from "../views/Mapa.vue";
import Resumo from "../views/Resumo.vue";
import ParticipacaoCidada from "../views/ParticipacaoCidada.vue";
import IndicadoresSociais from "../views/IndicadoresSociais.vue";
import PaginaNaoEncontrada from "../views/PaginaNaoEncontrada.vue";

import ResultadosSistemicos01 from "../views/resultados-sistemicos/ResultadosSistemicos01.vue";
import ResultadosSistemicos02 from "../views/resultados-sistemicos/ResultadosSistemicos02.vue";
import ResultadosSistemicos03 from "../views/resultados-sistemicos/ResultadosSistemicos03.vue";
import ResultadosSistemicos04 from "../views/resultados-sistemicos/ResultadosSistemicos04.vue";
import ResultadosSistemicos05 from "../views/resultados-sistemicos/ResultadosSistemicos05.vue";
import ResultadosSistemicos06 from "../views/resultados-sistemicos/ResultadosSistemicos06.vue";
import ResultadosSistemicos07 from "../views/resultados-sistemicos/ResultadosSistemicos07.vue";

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "Mapa",
    component: Mapa,
  },
  {
    path: "/resumo",
    name: "Resumo",
    component: Resumo,
  },
  {
    path: "/participacao-cidada",
    name: "ParticipacaoCidada",
    component: ParticipacaoCidada,
  },
  {
    path: "/indicadores-sociais",
    name: "IndicadoresSociais",
    component: IndicadoresSociais,
  },

  {
    path: "/resultados-sistemicos-01",
    name: "ResultadosSistemicos01",
    component: ResultadosSistemicos01,
  },
  {
    path: "/resultados-sistemicos-02",
    name: "ResultadosSistemicos02",
    component: ResultadosSistemicos02,
  },
  {
    path: "/resultados-sistemicos-03",
    name: "ResultadosSistemicos03",
    component: ResultadosSistemicos03,
  },
  {
    path: "/resultados-sistemicos-04",
    name: "ResultadosSistemicos04",
    component: ResultadosSistemicos04,
  },
  {
    path: "/resultados-sistemicos-05",
    name: "ResultadosSistemicos05",
    component: ResultadosSistemicos05,
  },
  {
    path: "/resultados-sistemicos-06",
    name: "ResultadosSistemicos06",
    component: ResultadosSistemicos06,
  },
  {
    path: "/resultados-sistemicos-07",
    name: "ResultadosSistemicos07",
    component: ResultadosSistemicos07,
  },

  {
    path: "/:pathMatch(.*)*",
    name: "Página não encontrada",
    component: PaginaNaoEncontrada,
  },
];

const router = new Router({
  mode: "history",
  routes
})

export default router
