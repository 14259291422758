<template>
  <div>
    <Header />
    <router-view />
  </div>
</template>

<script>
import Header from "./components/header/Header.vue";

export default {
  name: "App",
  components: {
    Header,
  },
};
</script>

<style lang="css">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css?family=Material+Icons");

:root {
  --max-width: 1000px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  background-color: #f9f9f9;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.unselectable {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.hide-content {
  display: none;
}
</style>
