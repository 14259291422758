import Vue from 'vue'
import App from './App.vue'
import router from '@/router/'

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import VueMeta from 'vue-meta'

Vue.use(VueMaterial)
Vue.use(VueMeta)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
