import Mapa from "./../enums/mapa.enum";
import RegioesImagens from "../enums/mapa-imagens/regioesImagens.enum";
import AmazoniaLegalImagens from "../enums/mapa-imagens/amazoniaLegalImagens.enum";
import SemiaridoImagens from "../enums/mapa-imagens/semiaridoImagens.enum";

export default class GeneralsUtil {
  static date = new Date();

  static day = GeneralsUtil.noSingleNumber(GeneralsUtil.date.getDate()).toString();
  static month = GeneralsUtil.noSingleNumber(GeneralsUtil.date.getMonth() + 1);
  static year = GeneralsUtil.date.getFullYear().toString();
  static completeDate = `${GeneralsUtil.day}/${GeneralsUtil.month}/${GeneralsUtil.year}`;

  static monthFull(date) {
    date = date.split("/");

    let day = date[0];
    let month = date[1];
    let year = date[2];

    switch (month) {
      case "01":
        month = "Janeiro"
        break;
      case "02":
        month = "Fevereiro"
        break;
      case "03":
        month = "Março"
        break;
      case "04":
        month = "Abril"
        break;
      case "05":
        month = "Maio"
        break;
      case "06":
        month = "Junho"
        break;
      case "07":
        month = "Julho"
        break;
      case "08":
        month = "Agosto"
        break;
      case "09":
        month = "Setembro"
        break;
      case "10":
        month = "Outubro"
        break;
      case "11":
        month = "Novembro"
        break;
      case "12":
        month = "Dezembro"
        break;
    }

    return `${day} de ${month} de ${year}`;
  }

  static noSingleNumber(number) {
    number = number.toString().length === 1 ? `0${number}` : number;
    return number;
  }

  static valueToNumber(value) {
    return value === true ? 2 : value === false ? 0 : value;
  }

  static isDateOver(dateLimitCard) {
    const dateCurrent = parseInt((`${GeneralsUtil.year}${GeneralsUtil.month}${GeneralsUtil.day}`));
    const dateLimit = parseInt(dateLimitCard.split("/").reverse().join().replaceAll(",", ""));

    return dateCurrent > dateLimit;
  }

  static lessThan30Days(dateLimitCard) {
    const dateCurrent = parseInt((`${GeneralsUtil.year}${GeneralsUtil.month}${GeneralsUtil.day}`));
    const dateLimit = parseInt(dateLimitCard.split("/").reverse().join().replaceAll(",", ""));

    return ((dateLimit - dateCurrent) < 30) && (dateLimit - dateCurrent) > 0;
  }

  static changeMapRegiao(regiao) {
    if (regiao === Mapa.Regioes.AMAZONIA_LEGAL) {
      return RegioesImagens.AMAZONIA_LEGAL;
    }

    if (regiao === Mapa.Regioes.SEMIARIDO) {
      return RegioesImagens.SEMIARIDO;
    }
  }

  static changeMapEstado(estado) {
    const estado2Characthers = estado.substring(estado.length - 3).slice(0, -1)
    let mapaDoEstado = null;

    switch (estado2Characthers) {
      case "AC":
        mapaDoEstado = AmazoniaLegalImagens.ac
        break;
      case "RR":
        mapaDoEstado = AmazoniaLegalImagens.rr
        break;
      case "AM":
        mapaDoEstado = AmazoniaLegalImagens.am
        break;
      case "RO":
        mapaDoEstado = AmazoniaLegalImagens.ro
        break;
      case "AP":
        mapaDoEstado = AmazoniaLegalImagens.ap
        break;
      case "PA":
        mapaDoEstado = AmazoniaLegalImagens.pa
        break;
      case "MT":
        mapaDoEstado = AmazoniaLegalImagens.mt
        break;
      case "MA":
        mapaDoEstado = AmazoniaLegalImagens.ma
        break;
      case "TO":
        mapaDoEstado = AmazoniaLegalImagens.to
        break;
      case "PI":
        mapaDoEstado = SemiaridoImagens.pi
        break;
      case "CE":
        mapaDoEstado = SemiaridoImagens.ce
        break;
      case "RN":
        mapaDoEstado = SemiaridoImagens.rn
        break;
      case "PB":
        mapaDoEstado = SemiaridoImagens.pb
        break;
      case "PE":
        mapaDoEstado = SemiaridoImagens.pe
        break;
      case "AL":
        mapaDoEstado = SemiaridoImagens.al
        break;
      case "SE":
        mapaDoEstado = SemiaridoImagens.se
        break;
      case "BA":
        mapaDoEstado = SemiaridoImagens.ba
        break;
      case "MG":
        mapaDoEstado = SemiaridoImagens.mg
        break;
    }

    return mapaDoEstado;
  }
}
