<template>
  <div>
    <div class="scroll-map" :class="showScrollMap === true ? 'show-content' : 'hide-content'">
      <div class="scroll-map-select-items">
        <div class="scroll-map-select-label">Selecione a região:</div>

        <div class="scroll-map-selects">
          <span v-if="required.regiao" class="required">Região deve ser selecionada</span>
          <select v-model="campos.regiao" id="regiao" name="regiao" @change="onChangeRegiao($event)">
            <option disabled value="Região">Região</option>
            <option
                v-for="(regiao, index) in regioes"
                :key="index"
                v-bind:value="regiao"
            >
              {{ regiao }}
            </option>
          </select>

          <span v-if="required.estado" class="required">Estado deve ser selecionado</span>
          <select v-model="campos.estado" id="estado" name="estado" @change="onChangeEstado($event)">
            <option disabled value="Estado">Estado</option>
            <option
                v-for="(estado, index) in estados"
                :key="index"
                v-bind:value="estado"
            >
              {{ estado }}
            </option>
          </select>

          <span v-if="required.municipio" class="required">Município deve ser selecionado</span>
          <select v-model="campos.municipio" id="municipio" name="municipio" @change="onChangeMunicipio($event)">
            <option disabled value="Município">Município</option>
            <option
                v-for="(municipio, index) in municipios"
                :key="index"
                v-bind:value="[municipio.id, municipio.name, municipio.uf]"
            >
              {{ municipio.name }}
            </option>
          </select>
        </div>

        <div v-if="loading" class="load-content">
          <div class="loader"></div>
        </div>
      </div>
    </div>

    <div class="map">
      <div class="map-data">
        <div class="map-data-text">
          <div class="map-data-title">Painel do Selo UNICEF 2021-2024</div>
          <div class="map-data-subtext">Promovendo os direitos de crianças e adolescentes e contribuindo para alcançar
            os Objetivos do Desenvolvimento Sustentável
          </div>
        </div>

        <div class="map-data-edition">Quem participa da edição 2021-2024 do Selo UNICEF?</div>
        <div class="map-data-numbers">
          <div class="map-data-estados">
            <div class="map-data-number">18</div>
            <div class="map-data-label-estados">estados</div>
          </div>
          <div class="map-data-municipios">
            <div class="map-data-number">2.023</div>
            <div class="map-data-label-municipios">municípios</div>
          </div>
          <div class="map-data-amazonia-legal">
            <div class="map-data-number">676</div>
            <div class="map-data-label-amazonia-legal">Amazônia legal</div>
          </div>
          <div class="map-data-seminario">
            <div class="map-data-number">1.347</div>
            <div class="map-data-label-seminario">Semiárido</div>
          </div>
        </div>
      </div>

      <div class="map-select">
        <div class="map-select-content">
          <div class="map-select-items">
            <div class="map-select-label">Selecione a região:</div>

            <span v-if="required.regiao" class="required">Região deve ser selecionada</span>
            <select v-model="campos.regiao" id="regiao" name="regiao" @change="onChangeRegiao($event)">
              <option disabled value="Região">Região</option>
              <option
                  v-for="(regiao, index) in regioes"
                  :key="index"
                  v-bind:value="regiao"
              >
                {{ regiao }}
              </option>
            </select>

            <span v-if="required.estado" class="required">Estado deve ser selecionado</span>
            <select v-model="campos.estado" id="estado" name="estado" @change="onChangeEstado($event)">
              <option disabled value="Estado">Estado</option>
              <option
                  v-for="(estado, index) in estados"
                  :key="index"
                  v-bind:value="estado"
              >
                {{ estado }}
              </option>
            </select>

            <span v-if="required.municipio" class="required">Município deve ser selecionado</span>
            <select v-model="campos.municipio" id="municipio" name="municipio" @change="onChangeMunicipio($event)">
              <option disabled value="Município">Município</option>
              <option
                  v-for="(municipio, index) in municipios"
                  :key="index"
                  v-bind:value="[municipio.id, municipio.name, municipio.uf]"
              >
                {{ municipio.name }}
              </option>
            </select>

            <div v-if="loading" class="load-content">
              <div class="loader"></div>
            </div>
          </div>

          <div v-if="showCount" class="count">
            <div class="count-title">
              {{ blocoCount.title }}
            </div>
            <div class="count-box">
              <div class="count-text">
                {{ blocoCount.text }}
              </div>
              <div class="count-number">
                {{ blocoCount.number }}
              </div>
            </div>
          </div>
        </div>

        <div class="map-select-map">
          <img :src="mapa"/>

          <div class="uf-ac-conect" :class="marcadorAmazoniaLegal.ac"></div>
          <div class="amazonia-legal uf uf-ac" :class="marcadorAmazoniaLegal.ac">
            <div>AC</div>
            <div>21</div>
          </div>
          <div class="amazonia-legal uf uf-rr" :class="marcadorAmazoniaLegal.rr">
            <div>RR</div>
            <div>15</div>
          </div>
          <div class="amazonia-legal uf uf-am" :class="marcadorAmazoniaLegal.am">
            <div>AM</div>
            <div>55</div>
          </div>
          <div class="amazonia-legal uf uf-ro" :class="marcadorAmazoniaLegal.ro">
            <div>RO</div>
            <div>52</div>
          </div>
          <div class="amazonia-legal uf uf-ap" :class="marcadorAmazoniaLegal.ap">
            <div>AP</div>
            <div>16</div>
          </div>
          <div class="amazonia-legal uf uf-pa" :class="marcadorAmazoniaLegal.pa">
            <div>PA</div>
            <div>124</div>
          </div>
          <div class="amazonia-legal uf uf-mt" :class="marcadorAmazoniaLegal.mt">
            <div>MT</div>
            <div>78</div>
          </div>
          <div class="amazonia-legal uf uf-ma" :class="marcadorAmazoniaLegal.ma">
            <div>MA</div>
            <div>216</div>
          </div>
          <div class="amazonia-legal uf uf-to" :class="marcadorAmazoniaLegal.to">
            <div>TO</div>
            <div>99</div>
          </div>
          <div class="uf-conect uf-pi-conect" :class="marcadorSemiarido.pi"></div>
          <div class="semiarido uf uf-pi" :class="marcadorSemiarido.pi">
            <div>PI</div>
            <div>183</div>
          </div>
          <div class="uf-conect uf-ce-conect" :class="marcadorSemiarido.ce"></div>
          <div class="semiarido uf uf-ce" :class="marcadorSemiarido.ce">
            <div>CE</div>
            <div>183</div>
          </div>
          <div class="uf-conect uf-rn-conect" :class="marcadorSemiarido.rn"></div>
          <div class="semiarido uf uf-rn" :class="marcadorSemiarido.rn">
            <div>RN</div>
            <div>156</div>
          </div>
          <div class="uf-conect uf-pb-conect" :class="marcadorSemiarido.pb"></div>
          <div class="semiarido uf uf-pb" :class="marcadorSemiarido.pb">
            <div>PB</div>
            <div>207</div>
          </div>
          <div class="uf-conect uf-pe-conect" :class="marcadorSemiarido.pe"></div>
          <div class="semiarido uf uf-pe" :class="marcadorSemiarido.pe">
            <div>PE</div>
            <div>140</div>
          </div>
          <div class="uf-conect uf-al-conect" :class="marcadorSemiarido.al"></div>
          <div class="semiarido uf uf-al" :class="marcadorSemiarido.al">
            <div>AL</div>
            <div>81</div>
          </div>
          <div class="uf-conect uf-se-conect" :class="marcadorSemiarido.se"></div>
          <div class="semiarido uf uf-se" :class="marcadorSemiarido.se">
            <div>SE</div>
            <div>56</div>
          </div>
          <div class="semiarido uf uf-ba" :class="marcadorSemiarido.ba">
            <div>BA</div>
            <div>236</div>
          </div>
          <div class="semiarido uf uf-mg" :class="marcadorSemiarido.mg">
            <div>MG</div>
            <div>105</div>
          </div>
        </div>
      </div>

      <div v-if="showCount">
        <!-- <BlocoMunicipios
            :title="municipiosAtual.title"

            :blocoUmData="municipiosAtual.blocoUmData"
            :blocoUmText="municipiosAtual.blocoUmText"
            :blocoUmNumber="municipiosAtual.blocoUmNumber"
            :blocoUmPercentage="municipiosAtual.blocoUmPercentage"

            :blocoDoisData="municipiosAtual.blocoDoisData"
            :blocoDoisText="municipiosAtual.blocoDoisText"
            :blocoDoisNumber="municipiosAtual.blocoDoisNumber"
            :blocoDoisPercentage="municipiosAtual.blocoDoisPercentage"

            :blocoTresData="municipiosAtual.blocoTresData"
            :blocoTresText="municipiosAtual.blocoTresText"
            :blocoTresNumber="municipiosAtual.blocoTresNumber"
            :blocoTresPercentage="municipiosAtual.blocoTresPercentage"
        /> -->

        <!-- <BlocoMunicipios
          :title="municipiosSemestre.title"

          :blocoUmData="municipiosSemestre.blocoUmData"
          :blocoUmText="municipiosSemestre.blocoUmText"
          :blocoUmNumber="municipiosSemestre.blocoUmNumber"
          :blocoUmPercentage="municipiosSemestre.blocoUmPercentage"

          :blocoDoisData="municipiosSemestre.blocoDoisData"
          :blocoDoisText="municipiosSemestre.blocoDoisText"
          :blocoDoisNumber="municipiosSemestre.blocoDoisNumber"
          :blocoDoisPercentage="municipiosSemestre.blocoDoisPercentage"

          :blocoTresData="municipiosSemestre.blocoTresData"
          :blocoTresText="municipiosSemestre.blocoTresText"
          :blocoTresNumber="municipiosSemestre.blocoTresNumber"
          :blocoTresPercentage="municipiosSemestre.blocoTresPercentage"
        />-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Endpoints from "../enums/endpoints.enum"
import Mapa from "./../enums/mapa.enum";
import RegioesImagens from "../enums/mapa-imagens/regioesImagens.enum";
import GeneralsUtil from "../utils/generals-util";

export default {
  name: "Mapa",
  data() {
    return {
      regioes: null,
      estados: null,
      municipios: null,
      mapa: null,
      loading: false,
      showScrollMap: false,
      scrollTop: false,
      widthMap: false,
      showCount: false,
      required: {
        regiao: false,
        estado: false,
        municipio: false
      },
      campos: {
        regiao: "Região",
        estado: "Estado",
        municipio: "Município"
      },
      accessDisabled: true,
      municipioSelecionado: {
        municipioId: null,
        municipioName: null,
        municipioUf: null,
      },
      marcadorAmazoniaLegal: {
        ac: "hide-content",
        rr: "hide-content",
        am: "hide-content",
        ro: "hide-content",
        ap: "hide-content",
        pa: "hide-content",
        mt: "hide-content",
        ma: "hide-content",
        to: "hide-content",
      },
      marcadorSemiarido: {
        pi: "hide-content",
        ce: "hide-content",
        rn: "hide-content",
        pb: "hide-content",
        pe: "hide-content",
        al: "hide-content",
        se: "hide-content",
        ba: "hide-content",
        mg: "hide-content",
      },
      blocoCount: {
        title: null,
        text: null,
        number: null
      },
      municipiosAtual: {
        title: null,
        blocoUmData: null,
        blocoUmText: null,
        blocoUmNumber: null,
        blocoUmPercentage: null,
        blocoDoisData: null,
        blocoDoisText: null,
        blocoDoisNumber: null,
        blocoDoisPercentage: null,
        blocoTresData: null,
        blocoTresText: null,
        blocoTresNumber: null,
        blocoTresPercentage: null,
      },
      municipiosSemestre: {
        title: null,
        blocoUmData: null,
        blocoUmText: null,
        blocoUmNumber: null,
        blocoUmPercentage: null,
        blocoDoisData: null,
        blocoDoisText: null,
        blocoDoisNumber: null,
        blocoDoisPercentage: null,
        blocoTresData: null,
        blocoTresText: null,
        blocoTresNumber: null,
        blocoTresPercentage: null,
      },
    };
  },
  mounted() {
    this.regioes = Mapa.Regioes;
    this.mapa = RegioesImagens.BRASIL;

    localStorage.removeItem("municipioId");
    localStorage.removeItem("municipioName");
    localStorage.removeItem("municipioUf");

    this.getScrollMap();
    this.getWidthMap();

    window.addEventListener("scroll", this.getScrollMap);
    window.addEventListener("resize", this.getWidthMap);

    setTimeout(() => {
      this.marcadorAmazoniaLegal = {
        ac: null,
        rr: null,
        am: null,
        ro: null,
        ap: null,
        pa: null,
        mt: null,
        ma: null,
        to: null,
      };

      this.marcadorSemiarido = {
        pi: null,
        ce: null,
        rn: null,
        pb: null,
        pe: null,
        al: null,
        se: null,
        ba: null,
        mg: null,
      };
    }, 250);
  },
  methods: {
    getScrollMap() {
      this.scrollTop = window.scrollY;
      this.setScrollMapState();
    },
    getWidthMap() {
      this.widthMap = Math.max(
          document.body.scrollWidth,
          document.documentElement.scrollWidth,
          document.body.offsetWidth,
          document.documentElement.offsetWidth,
          document.documentElement.clientWidth
      );

      this.setScrollMapState();
    },
    setScrollMapState() {
      if (this.widthMap >= 972 && this.scrollTop < 845) {
        this.showScrollMap = false;
      }

      if (this.widthMap >= 972 && this.scrollTop > 845) {
        this.showScrollMap = true;
      }

      if (this.widthMap < 972 && this.scrollTop < 1480) {
        this.showScrollMap = false;
      }

      if (this.widthMap < 972 && this.scrollTop > 1580) {
        this.showScrollMap = true;
      }

      if (this.widthMap < 547 && this.scrollTop > 1125) {
        this.showScrollMap = true;
      }
    },
    onChangeRegiao(event) {
      const regiao = event.target.value.split(",").toString();
      this.populaBlocoCount(regiao);

      let parameterRegion = null;

      if (regiao == "Amazônia Legal") {
        parameterRegion = "amazon";
      }

      if (regiao == "Semiárido") {
        parameterRegion = "semiarid";
      }

      this.populaBlocos(parameterRegion);

      if (regiao === Mapa.PrimeiraOpcao.REGIAO) {
        this.estados = null;
        this.mapa = RegioesImagens.BRASIL;
        this.required.regiao = true;
        this.showCount = false;

        this.marcadorAmazoniaLegal = {
          ac: null,
          rr: null,
          am: null,
          ro: null,
          ap: null,
          pa: null,
          mt: null,
          ma: null,
          to: null,
        };

        this.marcadorSemiarido = {
          pi: null,
          ce: null,
          rn: null,
          pb: null,
          pe: null,
          al: null,
          se: null,
          ba: null,
          mg: null,
        };
      }

      if (regiao !== Mapa.PrimeiraOpcao.REGIAO) {
        this.showCount = true;
      }

      if (regiao === Mapa.Regioes.AMAZONIA_LEGAL) {
        GeneralsUtil.changeMapRegiao(regiao);
        this.estados = Mapa.Estados.AMAZONIA_LEGAL;
        this.mapa = GeneralsUtil.changeMapRegiao(regiao);
        this.required.regiao = false;

        this.marcadorAmazoniaLegal = {
          ac: null,
          rr: null,
          am: null,
          ro: null,
          ap: null,
          pa: null,
          mt: null,
          ma: null,
          to: null,
        };

        this.marcadorSemiarido = {
          pi: "hide-content",
          ce: "hide-content",
          rn: "hide-content",
          pb: "hide-content",
          pe: "hide-content",
          al: "hide-content",
          se: "hide-content",
          ba: "hide-content",
          mg: "hide-content",
        };
      }

      if (regiao === Mapa.Regioes.SEMIARIDO) {
        GeneralsUtil.changeMapRegiao(regiao);
        this.estados = Mapa.Estados.SEMIARIDO;
        this.mapa = GeneralsUtil.changeMapRegiao(regiao);
        this.required.regiao = false;

        this.marcadorAmazoniaLegal = {
          ac: "hide-content",
          rr: "hide-content",
          am: "hide-content",
          ro: "hide-content",
          ap: "hide-content",
          pa: "hide-content",
          mt: "hide-content",
          ma: "hide-content",
          to: "hide-content",
        };

        this.marcadorSemiarido = {
          pi: null,
          ce: null,
          rn: null,
          pb: null,
          pe: null,
          al: null,
          se: null,
          ba: null,
          mg: null,
        };
      }
    },
    onChangeEstado(event) {
      const estado = event.target.value.split(",").toString();
      const uf = estado.substring(estado.length - 3).slice(0, -1);
      this.populaBlocoCount(estado);

      let parameterRegion = uf.toLowerCase();

      this.populaBlocos(parameterRegion);

      if (estado !== Mapa.PrimeiraOpcao.ESTADO) {
        this.showCount = true;
      }

      if (estado === Mapa.PrimeiraOpcao.ESTADO) {
        this.municipios = null;
        this.required.estado = true;
      }

      if (estado !== Mapa.PrimeiraOpcao.ESTADO) {
        this.getMunicipios(uf);
        GeneralsUtil.changeMapEstado(estado);
        this.mapa = GeneralsUtil.changeMapEstado(estado);
        this.required.estado = false;

        this.marcadorAmazoniaLegal = {
          ac: uf.toLowerCase() === "ac" ? null : "hide-content",
          rr: uf.toLowerCase() === "rr" ? null : "hide-content",
          am: uf.toLowerCase() === "am" ? null : "hide-content",
          ro: uf.toLowerCase() === "ro" ? null : "hide-content",
          ap: uf.toLowerCase() === "ap" ? null : "hide-content",
          pa: uf.toLowerCase() === "pa" ? null : "hide-content",
          mt: uf.toLowerCase() === "mt" ? null : "hide-content",
          ma: uf.toLowerCase() === "ma" ? null : "hide-content",
          to: uf.toLowerCase() === "to" ? null : "hide-content",
        };

        this.marcadorSemiarido = {
          pi: uf.toLowerCase() === "pi" ? null : "hide-content",
          ce: uf.toLowerCase() === "ce" ? null : "hide-content",
          rn: uf.toLowerCase() === "rn" ? null : "hide-content",
          pb: uf.toLowerCase() === "pb" ? null : "hide-content",
          pe: uf.toLowerCase() === "pe" ? null : "hide-content",
          al: uf.toLowerCase() === "al" ? null : "hide-content",
          se: uf.toLowerCase() === "se" ? null : "hide-content",
          ba: uf.toLowerCase() === "ba" ? null : "hide-content",
          mg: uf.toLowerCase() === "mg" ? null : "hide-content",
        };
      }
    },
    onChangeMunicipio(event) {
      const municipio = event.target.value.split(",");

      if (municipio.toString() === Mapa.PrimeiraOpcao.MUNICIPIO) {
        this.required.municipio = true;
      }

      if (municipio.toString() !== Mapa.PrimeiraOpcao.MUNICIPIO) {
        this.required.municipio = false;

        this.municipioSelecionado = {
          municipioId: municipio[0],
          municipioName: municipio[1],
          municipioUf: municipio[2],
        }

        this.accessSystemicResults();
      }

    },
    getMunicipios(uf) {
      const url = `${Endpoints.MUNICIPIOS}?uf=${uf}`;

      axios.get(url).then((response) => {
        this.municipios = Object.values(response.data.data);
      });
    },
    accessSystemicResults() {
      if (this.campos.regiao === Mapa.PrimeiraOpcao.REGIAO) {
        this.required.regiao = true;
      }

      if (this.campos.estado === Mapa.PrimeiraOpcao.ESTADO) {
        this.required.estado = true;
      }

      if (this.campos.municipio === Mapa.PrimeiraOpcao.MUNICIPIO) {
        this.required.municipio = true;
      }

      if (this.municipioSelecionado.municipioUf !== null) {
        this.loading = true;

        this.municipioId = this.municipioSelecionado.municipioId;
        this.municipioName = this.municipioSelecionado.municipioName.toLowerCase().replaceAll(' ', '-').normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        this.municipioUf = this.municipioSelecionado.municipioUf.toLowerCase();

        localStorage.setItem("municipioId", this.municipioId);
        localStorage.setItem("municipioName", this.municipioName);
        localStorage.setItem("municipioUf", this.municipioUf);

        this.accessDisabled = (this.required.regiao || this.required.estado || this.required.municipio) ? true : false;
        window.location.href = `/resumo?uf=${this.municipioUf}&municipio=${this.municipioName}`;
      }
    },
    populaBlocoCount(localidade) {
      this.blocoCount.text = "Municípios participantes do Selo UNICEF";

      if (localidade === "Amazônia Legal") {
        this.blocoCount.title = localidade;
        this.blocoCount.number = 676;
        return;
      }

      if (localidade === "Semiárido") {
        this.blocoCount.title = localidade;
        this.blocoCount.number = 1347;
        return;
      }

      const url = `${Endpoints.MUNICIPIOS}?uf=${localidade.substring(localidade.length - 3).slice(0, -1)}`;

      axios.get(url).then((response) => {
        this.blocoCount.title = localidade.slice(0, -5);
        this.blocoCount.number = response.data.data.length;
      });
    },
    populaBlocos(parameter) {
      let url = null;
      url = parameter.length > 2 ? `${Endpoints.MAPA}?region=${parameter}` : `${Endpoints.MAPA}?uf=${parameter}`;

      axios.get(url).then((response) => {
        this.municipiosAtual = {
          title: "Situação atual dos municípios",

          blocoUmData: GeneralsUtil.monthFull(response.data.data.current.blue.date),
          blocoUmText: "Municípios que realizaram mais de 70% das atividades cujo prazo era",
          blocoUmNumber: response.data.data.current.blue.total,
          blocoUmPercentage: (response.data.data.current.blue.percentage).toFixed(0),

          blocoDoisData: GeneralsUtil.monthFull(response.data.data.current.yellow.date),
          blocoDoisText: "Municípios que realizaram entre 30% e 70% das atividades cujo prazo era",
          blocoDoisNumber: response.data.data.current.yellow.total,
          blocoDoisPercentage: (response.data.data.current.yellow.percentage).toFixed(0),

          blocoTresData: GeneralsUtil.monthFull(response.data.data.current.red.date),
          blocoTresText: "Municípios que realizaram menos de 30% das atividades cujo prazo era",
          blocoTresNumber: response.data.data.current.red.total,
          blocoTresPercentage: (response.data.data.current.red.percentage).toFixed(0),
        };

        this.municipiosSemestre = {
          title: "Situação do semestre dos municípios",

          blocoUmData: response.data.data.semester.blue.date
              ? GeneralsUtil.monthFull(response.data.data.semester.blue.date)
              : "04 de Maio de 2022",
          blocoUmText: "Municípios que realizaram mais de 70% das atividades cujo prazo era",
          blocoUmNumber: response.data.data.semester.blue.total,
          blocoUmPercentage: (response.data.data.semester.blue.percentage).toFixed(0),

          blocoDoisData: response.data.data.semester.yellow.date
              ? GeneralsUtil.monthFull(response.data.data.semester.yellow.date)
              : "05 de Maio de 2022",
          blocoDoisText: "Municípios que realizaram entre 30% e 70% das atividades cujo prazo era",
          blocoDoisNumber: response.data.data.semester.yellow.total,
          blocoDoisPercentage: (response.data.data.semester.yellow.percentage).toFixed(0),

          blocoTresData: response.data.data.semester.red.date
              ? GeneralsUtil.monthFull(response.data.data.semester.red.date)
              : "06 de Maio de 2022",
          blocoTresText: "Municípios que realizaram menos de 30% das atividades cujo prazo era",
          blocoTresNumber: response.data.data.semester.red.total,
          blocoTresPercentage: (response.data.data.semester.red.percentage).toFixed(0),
        };
      });
    }
  }
};
</script>

<style lang="sass" scoped>
.scroll-map
  position: fixed
  top: 0
  z-index: 2
  width: 100%

.scroll-map-select-items
  align-items: center
  background-color: #1cabe2
  color: #ffffff
  display: flex
  flex-wrap: wrap
  font-size: 18px
  gap: 16px
  justify-content: center
  min-height: 60px
  padding: 8px 16px
  @media screen and (max-width: 971px)
    flex-direction: column
    padding-bottom: 12px
  @media screen and (max-width: 768px)
    gap: 8px

.scroll-map-selects
  align-items: center
  display: flex
  flex-wrap: wrap
  gap: 19px
  justify-content: center
  @media screen and (max-width: 971px)
    width: 240px
  @media screen and (max-width: 768px)
    gap: 8px

.scroll-map-select-items select
  border: 0px
  border-radius: 4px
  height: 36px
  min-width: 240px
  outline: 0px
  padding: 4px

.scroll-map-select-items option
  background-color: #ffffff
  color: #4a4a4a

.hide-content
  display: none

.show-content
  display: block

.map
  color: #4a4a4a
  margin: auto
  max-width: var(--max-width)
  padding: 0 16px

.map-data
  align-items: center
  display: flex
  flex-direction: column
  margin: 120px auto 36px
  text-align: center

.map-data-text
  display: flex
  flex-direction: column
  margin-bottom: 38px
  max-width: 546px
  text-align: center

.map-data-title
  font-size: 24px
  font-weight: 500
  margin-bottom: 48px
  text-align: center

.map-data-subtext
  font-size: 18px
  font-weight: 500

.map-data-edition
  background-color: #2fabe2
  border-bottom-left-radius: 4px
  border-top-right-radius: 4px
  color: #ffffff
  font-size: 26px
  line-height: 1.2
  margin-bottom: 30px
  max-width: 700px
  padding: 6px 10px

.map-data-numbers
  display: flex
  flex-wrap: wrap
  gap: 16px
  justify-content: center
  max-width: 800px

.map-data-estados,
.map-data-municipios,
.map-data-amazonia-legal,
.map-data-seminario
  border-radius: 4px
  box-shadow: 0px 3px 6px #00000029
  display: flex
  flex-direction: column
  height: 84px
  justify-content: space-between
  text-align: center
  width: 166px

.map-data-amazonia-legal
  border: 2px solid #80bd41

.map-data-seminario
  border: 2px solid #fce61f

.map-data-number
  color: #4a4a4a
  font-size: 38px
  font-weight: 500
  margin-top: 24px

.map-data-label-estados,
.map-data-label-municipios,
.map-data-label-amazonia-legal,
.map-data-label-seminario
  color: #4a4a4a
  font-size: 18px
  padding: 2px

.map-data-label-amazonia-legal
  background-color: #80bd41
  color: #ffffff

.map-data-label-seminario
  background-color: #fce61f
  color: #4a4a4a

.map-select
  display: flex
  justify-content: space-between
  flex-wrap: wrap-reverse

.map-select-content
  display: flex
  flex-direction: column
  justify-content: space-between
  margin: 0 auto 32px
  margin-bottom: 10rem
  width: 240px
  @media screen and (max-width: 986px)
    align-items: center
    flex-direction: row
    flex-wrap: wrap
    gap: 32px
    justify-content: space-around
    margin-bottom: 50px
    text-align: center
    width: 100%
  @media screen and (max-width: 800px)
    justify-content: space-between
    padding: 16px
  @media screen and (max-width: 572px)
    justify-content: center

.map-select-items
  display: flex
  flex-direction: column
  margin: 0 auto 32px
  width: 240px
  @media screen and (max-width: 986px)
    margin: 0

.map-select-items select
  background-color: #1cabe2
  border: 0px
  border-radius: 4px
  color: #ffffff
  height: 36px
  margin-bottom: 20px
  outline: 0px
  padding: 4px

.map-select-items option
  background-color: #ffffff
  color: #4a4a4a

.map-select-label
  color: #4a4a4a
  font-size: 18px
  margin-bottom: 20px

.map-select-map
  height: 36.6rem
  margin: 0 auto 32px
  padding: 0 65px
  width: 700px
  @media screen and (max-width: 746px)
    height: auto
  @media screen and (max-width: 561px)
    display: none

.required
  color: #ff0000
  font-size: 12px

// Estilo das UFS do MAPA

.uf
  align-items: center
  border-radius: 50px
  display: flex
  flex-wrap: wrap
  font-size: 12px
  font-weight: 500
  height: 42px
  justify-content: center
  padding: 5px
  position: relative
  width: 42px

.uf > div
  line-height: 1
  text-align: center
  width: 100%

.amazonia-legal
  background-color: #80bd41
  color: #ffffff

.semiarido
  background-color: #fce61f
  color: #4a4a4a

// Amazônia Legal
.uf-ac
  left: -60px
  top: -395px

.uf-rr
  background-color: transparent
  left: 162px
  top: -595px

.uf-am
  left: 115px
  top: -555px

.uf-ro
  left: 135px
  top: -495px

.uf-ap
  background-color: transparent
  left: 300px
  top: -715px

.uf-pa
  left: 285px
  top: -670px

.uf-mt
  left: 240px
  top: -585px

.uf-ma
  left: 395px
  top: -750px

.uf-to
  left: 350px
  top: -705px

// Semiárido
.uf-pi
  left: 445px
  top: -910px

.uf-ce
  left: 510px
  top: -935px

.uf-rn
  left: 570px
  top: -960px

.uf-pb
  left: 600px
  top: -955px

.uf-pe
  left: 600px
  top: -945px

.uf-al
  left: 575px
  top: -940px

.uf-se
  left: 535px
  top: -945px

.uf-ba
  left: 445px
  top: -1030px

.uf-mg
  left: 410px
  top: -985px

.uf-conect
  background-color: #fce61F
  height: 3px
  position: relative
  width: 50px
  z-index: -1

.uf-ac-conect
  background-color: #80bd41
  height: 3px
  left: -30px
  position: relative
  top: -374px
  width: 50px

.uf-pi-conect
  left: 442px
  top: -865px
  transform: rotate(80deg)

.uf-ce-conect
  left: 498px
  top: -900px
  transform: rotate(-45deg)

.uf-rn-conect
  left: 545px
  top: -915px
  transform: rotate(-45deg)

.uf-pb-conect
  left: 568px
  top: -917px
  transform: rotate(-10deg)

.uf-pe-conect
  left: 565px
  top: -930px
  transform: rotate(20deg)

.uf-al-conect
  left: 545px
  top: -950px
  transform: rotate(50deg)

.uf-se-conect
  left: 510px
  top: -965px
  transform: rotate(75deg)
  width: 70px

.hide-content
  background-color: transparent
  color: transparent

.uf-ac,
.uf-rr,
.uf-am,
.uf-ro,
.uf-ap,
.uf-pa,
.uf-mt,
.uf-ma,
.uf-to,
.uf-pi,
.uf-ce,
.uf-rn,
.uf-pb,
.uf-pe,
.uf-al,
.uf-se,
.uf-ba,
.uf-mg,
.uf-conect,
.uf-ac-conect,
.uf-pi-conect,
.uf-ce-conect,
.uf-rn-conect,
.uf-pb-conect,
.uf-pe-conect,
.uf-al-conect,
.uf-se-conect
  @media screen and (max-width: 746px)
    display: none

.load-content
  display: flex
  justify-content: center

.loader
  animation: spin 2s linear infinite
  border: 4px solid lightgray
  border-radius: 50%
  border-top: 4px solid #1cabe2
  height: 40px
  width: 40px
  -webkit-animation: spin 2s linear infinite

@-webkit-keyframes spin
  0%
    -webkit-transform: rotate(0deg)
  100%
    -webkit-transform: rotate(360deg)

@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

.count-title
  color: #4a4a4a
  font-size: 22px
  font-weight: 500
  margin-bottom: 21px

.count-box
  align-items: center
  border-radius: 4px
  box-shadow: 0px 3px 6px #00000029
  display: flex
  font-weight: 500
  height: 70px
  justify-content: space-between
  padding: 18px 10px

.count-text
  font-size: 12px
  height: 35px
  text-align: left
  width: 150px

.count-number
  color: #1cabe2
  font-size: 30px
</style>
