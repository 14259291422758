<template>
  <div class="subtitle">
    <div class="subtitle-title">Legenda</div>
    <div class="subtitle-body">
      <div class="subtitle-content">
        <div class="subtitle-content-item">
          <div class="subtitle-content-ball subtitle-content-ball-grey"></div>
          <div class="subtitle-content-text">Em progresso/no prazo.</div>
        </div>
        <div class="subtitle-content-item">
          <div class="subtitle-content-ball subtitle-content-ball-blue"></div>
          <div class="subtitle-content-text">Atividade realizado ou concluida dentro do prazo.</div>
        </div>
        <div class="subtitle-content-item">
          <div class="subtitle-content-ball subtitle-content-ball-orange"></div>
          <div class="subtitle-content-text">Atividade com a meta atingida fora do prazo definido no guia ou estipulado na plataforma.</div>
        </div>
        <div class="subtitle-content-item">
          <div class="subtitle-content-ball subtitle-content-ball-red"></div>
          <div class="subtitle-content-text">O prazo desta atividade se esgotou e o município ainda não entregou ou não conseguiu atingir a meta estipulado pelo guia.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DotsLegends",
  data() {
    return {};
  }
};
</script>

<style lang="sass">

.subtitle
  margin-top: 80px
  margin: 80px auto 0
  @media screen and (max-width: 1006px)
    max-width: 600px

.subtitle-title
  font-size: 12px
  margin-bottom: 20px

.subtitle-body
  display: flex
  justify-content: center

.subtitle-content
  display: flex
  flex-wrap: wrap
  gap: 20px
  justify-content: space-between

.subtitle-content-item
  display: flex
  width: 220px

.subtitle-content-ball
  border-radius: 50%
  display: block
  margin-right: 8px
  max-height: 20px
  max-width: 20px
  min-height: 20px
  min-width: 20px

.subtitle-content-ball-grey
  background-color: #c9c9c9
  border: 1px solid #c9c9c9
  &::after
    color: #ffffff
    content: ""
    font-weight: 600
    margin-left: 3px

.subtitle-content-ball-blue
  background-color: #1cabe2
  border: 1px solid #1cabe2
  &::after
    color: #ffffff
    content: "✓"
    font-weight: 600
    margin-left: 3px

.subtitle-content-ball-orange
  background-color: #f26a21
  border: 1px solid #f26a21
  &::after
    color: #ffffff
    content: "✓"
    font-weight: 600
    margin-left: 3px

.subtitle-content-ball-red
  background-color: #e2231a
  border: 1px solid #e2231a
  &::after
    color: #ffffff
    content: "✕"
    font-weight: 600
    margin-left: 3px

.subtitle-content-text
  font-size: 12px
</style>
