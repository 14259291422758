<template>
  <div class="card-component">
    <div class="card-tab">
      <div class="card-tab-text" :class="this.tabStyle.color">{{ this.tabStyle.text }}</div>
    </div>

    <div class="card-body card-loading" :class="cardStyle">
      <div class="card-body-header">
        <h1 class="card-body-header-title">{{ cardTitle }}</h1>

        <div v-if="isParticipacaoCidada" class="card-body-header-points" :class="squarePoints">
          <div v-if="loading" class="card-body-header-points-point">0</div>
          <div v-if="!loading" class="card-body-header-points-point">{{ cardPoints }}</div>
          <div class="card-body-header-points-text">Pontos</div>
        </div>
      </div>

      <div class="card-body-primary-text">
        <div class="card-body-primary-text-first-block">{{ cardPrimaryTextFirstBlock }}</div>
        <div class="card-body-primary-text-second-block">{{ cardPrimaryTextSecondBlock }}</div>
        <div class="card-body-primary-text-third-block">{{ cardPrimaryTextThirdBlock }}</div>
      </div>

      <div v-if="!loading">
        <div v-if="evaluationsCardType" class="evaluations">
          <div v-if="evaluationGoalUm" class="evaluation">
            <div class="evaluation-number" :class="evaluations.styleBox.um">{{ evaluationValueUm + evaluationValueQuatro }}/{{ evaluationGoalUm }}</div>
            <div class="evaluation-text" :class="evaluations.styleText.um">{{ evaluationTextUm }}</div>
          </div>
          <div v-if="evaluationGoalDois" class="evaluation">
            <div class="evaluation-number" :class="evaluations.styleBox.dois" >{{ evaluationValueDois + evaluationValueCinco }}/{{ evaluationGoalDois }}</div>
            <div class="evaluation-text" :class="evaluations.styleText.dois">{{ evaluationTextDois }}</div>
          </div>
          <div v-if="evaluationGoalTres" class="evaluation">
            <div class="evaluation-number" :class="evaluations.styleBox.tres" >{{ evaluationValueTres + evaluationValueSeis }}/{{ evaluationGoalTres }}</div>
            <div class="evaluation-text" :class="evaluations.styleText.tres">{{ evaluationTextTres }}</div>
          </div>
        </div>
      </div>

      <div v-if="!loading">
        <div v-if="checkpointsCardType" class="checkpoints">
          <div v-if="checkpointTextDotUm" class="checkpoint-container">
            <span class="checkpoint-icon" :class="checkpoints.style.um"></span>
            <div class="checkpoint-text">
              <span class="checkpoint-main-data">{{ checkpointTextDotUm }}</span>
            </div>
          </div>

          <div v-if="checkpointTextDotDois" class="checkpoint-container">
            <span class="checkpoint-icon" :class="checkpoints.style.dois"></span>
            <div class="checkpoint-text">
              <span class="checkpoint-main-data">{{ checkpointTextDotDois }}</span>
            </div>
          </div>

          <div v-if="checkpointTextDotTres" class="checkpoint-container">
            <span class="checkpoint-icon" :class="checkpoints.style.tres"></span>
            <div class="checkpoint-text">
              <span class="checkpoint-main-data">{{ checkpointTextDotTres }}</span>
            </div>
          </div>

          <div v-if="checkpointTextDotQuatro" class="checkpoint-container">
            <span class="checkpoint-icon" :class="checkpoints.style.quatro"></span>
            <div class="checkpoint-text">
              <span class="checkpoint-main-data">{{ checkpointTextDotQuatro }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!loading" class="card-progress-content">
        <div v-if="percentCardType" class="card-progress">
          <span class="card-progress-percentage" :class="percentage.styleNumber">{{ percentageValueLimit }}%</span>
          <span class="card-progress-bar">
              <div class="card-progress-bar-mark" :class="[percentage.styleBar, percentageGoal]" :style="{ width: percentageValueLimit + '%' }"></div>
          </span>
        </div>

        <div v-if="percentCardType && percentageScoreGoals !== null" class="item-goal">
          <span :class="itemGoalStyle">
            <!-- <span>{{ percentageScoreCurrent }} / {{ percentageScoreGoals }} {{ percentageScoreItem }}</span> -->
          </span>
        </div>
      </div>

      <div class="card-body-secondary-text">{{ cardSecondaryText }}</div>

      <div v-if="!loading" class="card-body-footer">
        <div v-if="progressUpdatedIn" class="footer-text">Progresso atualizado {{ progressUpdatedIn }}</div>
        <div class="footer-text-deadline" :class="deadLineStyle">Prazo: {{ dateLimitCard }}</div>
      </div>
    </div>

    <div class="row-links">
      <div v-if="linkTitleUmCard" class="link-card">
        <a target="_blank" :href="linkUmCard">{{linkTitleUmCard}}</a>
        <span v-if="!linkTitleUmCard.includes('Curso')" class="material-icons md-18">north_east</span>
      </div>

      <div v-if="linkTitleDoisCard" class="link-card">
        <a target="_blank" :href="linkDoisCard">{{linkTitleDoisCard}}</a>
        <span v-if="!linkTitleDoisCard.includes('Curso')" class="material-icons md-18">north_east</span>
      </div>

      <div v-if="linkTitleTresCard" class="link-card">
        <a target="_blank" :href="linkTresCard">{{linkTitleTresCard}}</a>
        <span v-if="!linkTitleTresCard.includes('Curso')" class="material-icons md-18">north_east</span>
      </div>

      <div v-if="linkTitleQuatroCard" class="link-card">
        <a target="_blank" :href="linkQuatroCard">{{linkTitleQuatroCard}}</a>
        <span v-if="!linkTitleQuatroCard.includes('Curso')" class="material-icons md-18">north_east</span>
      </div>

      <div v-if="linkTitleCincoCard" class="link-card">
        <a target="_blank" :href="linkCincoCard">{{linkTitleCincoCard}}</a>
        <span v-if="!linkTitleCincoCard.includes('Curso')" class="material-icons md-18">north_east</span>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralsUtil from "../../../utils/generals-util";

export default {
  name: "CardResultadoSistemico",
  components: {},
  props: [
    // card-type
    "percentCardType",
    "evaluationsCardType",
    "checkpointsCardType",

    "isParticipacaoCidada",

    // card-data
    "cardPoints",
    "cardDate",
    "cardTitle",
    "cardPrimaryTextFirstBlock",
    "cardPrimaryTextSecondBlock",
    "cardPrimaryTextThirdBlock",
    "progressUpdatedIn",
    "updatedDaily",

    "linkTitleUmCard",
    "linkUmCard",

    "linkTitleDoisCard",
    "linkDoisCard",

    "linkTitleTresCard",
    "linkTresCard",

    "linkTitleQuatroCard",
    "linkQuatroCard",

    "linkTitleCincoCard",
    "linkCincoCard",

    // percentage
    "percentageValue",
    "percentageScoreCurrent",
    "percentageGoalProps",
    "percentageScoreGoals",
    "percentageScoreItem",

    // evaluations
    "evaluationValueUm",
    "evaluationValueDois",
    "evaluationValueTres",
    "evaluationValueQuatro",
    "evaluationValueCinco",
    "evaluationValueSeis",

    "evaluationGoalUm",
    "evaluationGoalDois",
    "evaluationGoalTres",

    "evaluationTextUm",
    "evaluationTextDois",
    "evaluationTextTres",

    "evaluationDateLimitUm",
    "evaluationDateLimitDois",
    "evaluationDateLimitTres",

    // checkpoints
    "checkpointPointsDotUm",
    "checkpointPointsDotDois",
    "checkpointPointsDotTres",
    "checkpointPointsDotQuatro",

    "checkpointTextDotUm",
    "checkpointTextDotDois",
    "checkpointTextDotTres",
    "checkpointTextDotQuatro",

    "checkpointDateLimitDotUm",
    "checkpointDateLimitDotDois",
    "checkpointDateLimitDotTres",
    "checkpointDateLimitDotQuatro",
  ],
  data() {
    return {
      loading: true,
      dateLimitCard: null,
      cardStyle: null,
      squarePoints: null,
      percentageGoal: null,
      percentageValueLimit: null,
      itemGoalStyle: null,
      cardSecondaryText: null,
      deadLineStyle: null,
      pointNumber: 0,

      tabStyle: {
        color: "card-tab-text-loading",
        text: "Carregando status",
      },

      percentage: {
        styleNumber: "percentage-grey",
        styleBar: null
      },

      evaluations: {
        dataCardOnTime: {
          education: null,
          health: null,
          social: null,
        },
        dataCardOutsideTime: {
          education: null,
          health: null,
          social: null,
        },
        styleBox: {
          um: null,
          dois: null,
          tres: null,
        },
        styleText: {
          um: null,
          dois: null,
          tres: null,
        },
      },

      checkpoints: {
        isOverDateDotUm: false,
        isOverDateDotDois: false,
        isOverDateDotTres: false,
        isOverDateDotQuatro: false,

        style: {
          um: null,
          dois: null,
          tres: null,
          quatro: null,
        },
      }
    };
  },
  watch: {
    cardPoints: {
      immediate: true, 
      handler (value) {
        if (value !== null) {
          this.pointNumber = value;
          this.dateLimitCard = GeneralsUtil.monthFull(this.cardDate);
          this.cardStyle = this.setCardStyle(this.pointNumber);
          this.squarePoints = this.setPointsSquareStyle(this.pointNumber);
          this.percentageValueLimit = this.percentageValue > 100 ? 100 : this.percentageValue;
          this.percentageGoal = this.setPercentageGoal();
          this.itemGoalStyle = this.setItemGoalStyle(this.pointNumber);
          this.cardSecondaryText = this.setSecondaryText(this.pointNumber);
          this.deadLineStyle = this.setDeadlineStyle(this.pointNumber);

          this.setTabStyle(this.pointNumber);

          this.percentCardType ? this.setPencentageBarStyle(this.pointNumber) : null;
          this.evaluationsCardType ? this.setEvaluationsData() : null;
          this.checkpointsCardType ? this.setCheckpointsStyle() : null;

          this.loading = false;
        }
      }
    }
  },
  methods: {
    setTabStyle(points) {
      if (points == 2) {
        this.tabStyle.color = "card-tab-text-concluido-prazo";
        this.tabStyle.text = "Concluído no prazo";
      }

      if (points == 1) {
        this.tabStyle.color = "card-tab-text-fora-prazo";
        this.tabStyle.text = "Concluído fora do prazo";
      }

      if (points == 0 && !GeneralsUtil.isDateOver(this.cardDate)) {
        this.tabStyle.color = "card-tab-text-em-progresso";
        this.tabStyle.text = "Em progresso";
      }

      if (points == 0 && GeneralsUtil.isDateOver(this.cardDate)) {
        this.tabStyle.color = "card-tab-text-em-atraso";
        this.tabStyle.text = "Em atraso";
      }
    },

    setCardStyle(points) {
      if (points == 2) return "card-blue";
      if (points == 1) return "card-orange";
      if (points == 0 && GeneralsUtil.isDateOver(this.cardDate) == false) return "card-yellow";
      if (points == 0 && GeneralsUtil.isDateOver(this.cardDate) == true) return "card-red";
    },

    setPointsSquareStyle(points) {
      if (points == 2) return "card-body-header-points-blue";
      if (points == 1) return "card-body-header-points-orange";
      if (points == 0 && GeneralsUtil.isDateOver(this.cardDate) == false) return "card-body-header-points-yellow";
      if (points == 0 && GeneralsUtil.isDateOver(this.cardDate) == true) return "card-body-header-points-red";
    },


    setPencentageBarStyle(points) {
      if (points == 2) {
        this.percentage.styleNumber = "card-progress-percentage-blue";
        this.percentage.styleBar = "card-progress-bar-blue";
      }

      if (points == 1) {
        this.percentage.styleNumber = "card-progress-percentage-orange";
        this.percentage.styleBar = "card-progress-bar-orange";
      }

      if (points == 0 && !GeneralsUtil.isDateOver(this.cardDate)) {
        this.percentage.styleNumber = "card-progress-percentage-yellow";
        this.percentage.styleBar = "card-progress-bar-yellow";
      }

      if (points == 0 && GeneralsUtil.isDateOver(this.cardDate)) {
        this.percentage.styleNumber = "card-progress-percentage-red";
        this.percentage.styleBar = "card-progress-bar-red";
      }
    },

    setPercentageGoal() {
      let goal = null;

      switch (this.percentageGoalProps) {
        case 5: goal = "card-progress-bar-mark-5"; break;
        case 10: goal = "card-progress-bar-mark-10"; break;
        case 15: goal = "card-progress-bar-mark-15"; break;
        case 20: goal = "card-progress-bar-mark-20"; break;
        case 25: goal = "card-progress-bar-mark-25"; break;
        case 30: goal = "card-progress-bar-mark-30"; break;
        case 35: goal = "card-progress-bar-mark-35"; break;
        case 40: goal = "card-progress-bar-mark-40"; break;
        case 45: goal = "card-progress-bar-mark-45"; break;
        case 50: goal = "card-progress-bar-mark-50"; break;
        case 55: goal = "card-progress-bar-mark-55"; break;
        case 60: goal = "card-progress-bar-mark-60"; break;
        case 65: goal = "card-progress-bar-mark-65"; break;
        case 70: goal = "card-progress-bar-mark-70"; break;
        case 75: goal = "card-progress-bar-mark-75"; break;
        case 80: goal = "card-progress-bar-mark-80"; break;
        case 85: goal = "card-progress-bar-mark-85"; break;
        case 90: goal = "card-progress-bar-mark-90"; break;
        case 95: goal = "card-progress-bar-mark-95"; break;

        default:
          break;
      }

      return goal;
    },

    setItemGoalStyle(points) {
      if (points == 2) return "item-goal-blue";
      if (points == 1) return "item-goal-orange";
      if (points == 0 && !GeneralsUtil.isDateOver(this.cardDate)) return "item-goal-yellow";
      if (points == 0 && GeneralsUtil.isDateOver(this.cardDate)) return "item-goal-red";
    },

    setEvaluationsData() {
      this.evaluations.styleBox.um = this.evaluationValueUm != undefined ? this.setEvaluationsStyleBox(this.evaluationValueUm, this.evaluationValueQuatro, this.evaluationGoalUm, this.evaluationDateLimitUm) : null;
      this.evaluations.styleBox.dois = this.evaluationValueDois != undefined ? this.setEvaluationsStyleBox(this.evaluationValueDois, this.evaluationValueCinco, this.evaluationGoalDois, this.evaluationDateLimitDois) : null;
      this.evaluations.styleBox.tres = this.evaluationValueTres != undefined ? this.setEvaluationsStyleBox(this.evaluationValueTres, this.evaluationValueSeis, this.evaluationGoalTres, this.evaluationDateLimitTres) : null;

      this.evaluations.styleText.um = this.evaluationValueUm != undefined ? this.setEvaluationsStyleText(this.evaluationValueUm, this.evaluationValueQuatro, this.evaluationGoalUm, this.evaluationDateLimitUm) : null;
      this.evaluations.styleText.dois = this.evaluationValueDois != undefined ? this.setEvaluationsStyleText(this.evaluationValueDois, this.evaluationValueCinco, this.evaluationGoalDois, this.evaluationDateLimitDois) : null;
      this.evaluations.styleText.tres = this.evaluationValueTres != undefined ? this.setEvaluationsStyleText(this.evaluationValueTres, this.evaluationValueSeis, this.evaluationGoalTres, this.evaluationDateLimitTres) : null;
    },

    setEvaluationsStyleBox(valueUm, valueDois, goal, dateLimit) {
      const isDateOver = GeneralsUtil.isDateOver(dateLimit);

      if (valueUm + valueDois >= goal) return "evaluation-box-blue";
      if (valueUm + valueDois >= goal && isDateOver) return "evaluation-box-orange";
      if (valueUm + valueDois < goal && !isDateOver) return "evaluation-box-yellow";
      if (valueUm + valueDois < goal && isDateOver) return "evaluation-box-red";
    },

    setEvaluationsStyleText(valueUm, valueDois, goal, dateLimit) {
      const isDateOver = GeneralsUtil.isDateOver(dateLimit);

      if (valueUm + valueDois >= goal) return "evaluation-text-blue";
      if (valueUm + valueDois >= goal && isDateOver) return "evaluation-text-orange";
      if (valueUm + valueDois < goal && !isDateOver) return "evaluation-text-yellow";
      if (valueUm + valueDois < goal && isDateOver) return "evaluation-text-red";
    },

    setCheckpointsStyle() {
      if (this.checkpointPointsDotUm == 2) this.checkpoints.style.um = "checkpoint-icon-blue";
      if (this.checkpointPointsDotUm == 1) this.checkpoints.style.um = "checkpoint-icon-orange";
      if (this.checkpointPointsDotUm == 0 && !GeneralsUtil.isDateOver(this.checkpointDateLimitDotUm)) this.checkpoints.style.um = "checkpoint-icon-grey";
      if (this.checkpointPointsDotUm == 0 && GeneralsUtil.isDateOver(this.checkpointDateLimitDotUm)) this.checkpoints.style.um = "checkpoint-icon-red";
      if (this.checkpointPointsDotUm == null) this.checkpoints.style.um = "checkpoint-icon-grey";

      if (this.checkpointPointsDotDois == 2) this.checkpoints.style.dois = "checkpoint-icon-blue";
      if (this.checkpointPointsDotDois == 1) this.checkpoints.style.dois = "checkpoint-icon-orange";
      if (this.checkpointPointsDotDois == 0 && !GeneralsUtil.isDateOver(this.checkpointDateLimitDotDois)) this.checkpoints.style.dois = "checkpoint-icon-grey";
      if (this.checkpointPointsDotDois == 0 && GeneralsUtil.isDateOver(this.checkpointDateLimitDotDois)) this.checkpoints.style.dois = "checkpoint-icon-red";
      if (this.checkpointPointsDotDois == null) this.checkpoints.style.dois = "checkpoint-icon-grey";

      if (this.checkpointPointsDotTres == 2) this.checkpoints.style.tres = "checkpoint-icon-blue";
      if (this.checkpointPointsDotTres == 1) this.checkpoints.style.tres = "checkpoint-icon-orange";
      if (this.checkpointPointsDotTres == 0 && !GeneralsUtil.isDateOver(this.checkpointDateLimitDotTres)) this.checkpoints.style.tres = "checkpoint-icon-grey";
      if (this.checkpointPointsDotTres == 0 && GeneralsUtil.isDateOver(this.checkpointDateLimitDotTres)) this.checkpoints.style.tres = "checkpoint-icon-red";
      if (this.checkpointPointsDotTres == null) this.checkpoints.style.tres = "checkpoint-icon-grey";

      if (this.checkpointPointsDotQuatro == 2) this.checkpoints.style.quatro = "checkpoint-icon-blue";
      if (this.checkpointPointsDotQuatro == 1) this.checkpoints.style.quatro = "checkpoint-icon-orange";
      if (this.checkpointPointsDotQuatro == 0 && !GeneralsUtil.isDateOver(this.checkpointDateLimitDotQuatro)) this.checkpoints.style.quatro = "checkpoint-icon-grey";
      if (this.checkpointPointsDotQuatro == 0 && GeneralsUtil.isDateOver(this.checkpointDateLimitDotQuatro)) this.checkpoints.style.quatro = "checkpoint-icon-red";
      if (this.checkpointPointsDotQuatro == null) this.checkpoints.style.quatro = "checkpoint-icon-grey";
    },

    setSecondaryText(points) {
      if (points === 2) return "A meta para esta atividades foi atingida. Uma atuação constante impacta um número maior de meninas e meninos.";
      if (points === 1) return "Meta alcançada com atraso. Continuar atuando é importante para todas as meninas e meninos.";
      if (points === 0 && !GeneralsUtil.isDateOver(this.cardDate)) return "Esta atividade está em progresso. A meta deve ser alcançada dentro do prazo.";
      if (points === 0 && GeneralsUtil.isDateOver(this.cardDate)) return "O prazo ja esgotou mas é necessário concluir todas as atividades para alcançar o certificado do Selo.";
    },

    setDeadlineStyle(points) {
      if (points === 0 && GeneralsUtil.isDateOver(this.cardDate)) return "footer-em-atraso";
    }
  }
};
</script>

<style lang="sass" scoped>

.card-component
  margin: 24px 10px 0 0
  max-width: var(--max-width)
  width: 310px

//////////////////// card-tab style begins

.card-tab
  font-size: 13px
  text-align: right

.card-tab-text
  border-radius: 4px 4px 0px 0px
  display: inline-block
  padding: 2px 10px

.card-tab-text-loading
  background-color: lightgrey
  color: #707070

.card-tab-text-concluido-prazo
  background-color: #1cabe2
  color: #ffffff

.card-tab-text-fora-prazo
  background-color: #f26a21
  color: #ffffff

.card-tab-text-em-progresso
  background-color: #ffc20e
  color: #4a4a4a

.card-tab-text-em-atraso
  background-color: #e2231a
  color: #ffffff

//////////////////// card-body style begins

.card-body
  background-color: #ffffff
  background-repeat: no-repeat
  background-size: 360px
  border-radius: 4px 0 6px 4px
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px
  line-height: 20px !important
  min-height: 260px
  padding: 20px 20px 0

.card-loading
  background-image: none
  border: 1px solid lightgrey

.card-blue
  background-image: url("../../../assets/indicadores-sociais/bgs/bg-blue.svg")
  border: 1px solid #1cabe2

.card-orange
  background-image: url("../../../assets/indicadores-sociais/bgs/bg-orange.svg")
  border: 1px solid #f26a21

.card-yellow
  background-image: url("../../../assets/indicadores-sociais/bgs/bg-yellow.svg")
  border: 1px solid #ffc20e

.card-red
  background-image: url("../../../assets/indicadores-sociais/bgs/bg-red.svg")
  border: 1px solid #e2231a

.card-body-header
  align-items: center
  display: flex
  gap: 8px
  justify-content: space-between
  margin-bottom: 15px
  min-height: 78px

.card-body-header-title
  align-items: center
  color: #4a4a4a
  display: flex
  font-size: 16px
  font-weight: 500
  justify-content: space-between

.card-body-header-points
  align-items: center
  background-color: lightgrey
  border-radius: 10px
  box-shadow: 0px 3px 6px #00000029
  color: #000000
  display: flex
  flex-direction: column
  gap: 4px
  justify-content: center
  max-width: 78px
  max-height: 78px
  min-width: 78px
  min-height: 78px

.card-body-header-points-blue
  background-color: #1cabe2
  color: #ffffff

.card-body-header-points-orange
  background-color: #f26a21

.card-body-header-points-yellow
  background-color: #ffc20e

.card-body-header-points-red
  background-color: #e2231a
  color: #ffffff

.card-body-header-points-point
  font-size: 36px
  font-weight: 500

.card-body-header-points-text
  font-size: 18px
  font-weight: 500

.card-body-primary-text
  color: #000000
  font-size: 14px
  margin-bottom: 32px

.card-body-primary-text-first-block,
.card-body-primary-text-second-block,
.card-body-primary-text-third-block
  margin-bottom: 32px

.card-body-secondary-text
  color: #4a4a4a
  font-size: 12px
  margin: 0 0 32px

//////////////////// card-progress style begin

.card-progress-content
  margin-bottom: 32px

.card-progress
  align-items: center
  display: flex
  justify-content: space-between

.card-progress-percentage
  display: inline-block
  font-size: 20px

.card-progress-percentage-blue
  color: #1cabe2

.card-progress-percentage-orange
  color: #f26a21

.card-progress-percentage-yellow
  color: #ffc20e

.card-progress-percentage-red
  color: #e2231a

.card-progress-percentage-grey
  color: grey

.card-progress-bar
  background-color: lightgray
  border-radius: 18px
  display: inline-block
  margin-left: 20px
  width: 200px

.card-progress-bar > div
  border-radius: 10px
  height: 6px !important

.card-progress-bar-mark
  &::before
    background: #f9f9f9
    border: 2px solid #f9f9f9
    content: ""
    display: inline-block
    height: 9px
    position: relative
    top: -7px

.card-progress-bar-mark-5
  &::before
    left: 10px

.card-progress-bar-mark-10
  &::before
    left: 20px

.card-progress-bar-mark-15
  &::before
    left: 30px

.card-progress-bar-mark-20
  &::before
    left: 40px

.card-progress-bar-mark-25
  &::before
    left: 50px

.card-progress-bar-mark-30
  &::before
    left: 60px

.card-progress-bar-mark-35
  &::before
    left: 70px

.card-progress-bar-mark-40
  &::before
    left: 80px

.card-progress-bar-mark-45
  &::before
    left: 90px

.card-progress-bar-mark-50
  &::before
    left: 100px


.card-progress-bar-mark-55
  &::before
    left: 110px

.card-progress-bar-mark-60
  &::before
    left: 120px

.card-progress-bar-mark-65
  &::before
    left: 130px

.card-progress-bar-mark-70
  &::before
    left: 140px

.card-progress-bar-mark-75
  &::before
    left: 150px

.card-progress-bar-mark-80
  &::before
    left: 160px

.card-progress-bar-mark-85
  &::before
    left: 170px

.card-progress-bar-mark-90
  &::before
    left: 180px

.card-progress-bar-mark-95
  &::before
    left: 190px

.card-progress-bar-mark-100
  &::before
    left: 200px

.card-progress-bar-blue
  background-color: #1cabe2

.card-progress-bar-orange
  background-color: #f26a21

.card-progress-bar-yellow
  background-color: #ffc20e

.card-progress-bar-red
  background-color: #e2231a

//////////////////// scholls-goal begin

.item-goal
  font-size: 12px
  margin-bottom: 32px
  text-align: right

.item-goal-blue
  color: #1cabe2

.item-goal-orange
  color: #f26a21

.item-goal-yellow
  color: #4a4a4a

.item-goal-red
  color: #e2231a

//////////////////// evaluations begin

.evaluations
  display: flex
  justify-content: center
  margin: 32px 0

.evaluation
  align-items: center
  display: flex
  flex-direction: column
  text-align: center

.evaluation-number
  align-items: center
  border: 2px solid #000
  border-radius: 4px
  display: flex
  font-size: 24px
  height: 45px
  justify-content: center
  min-width: 60px
  padding: 4px

.evaluation-box-blue
  background-color: #e8f6fc
  border: 2px solid #1cabe2
  color: #1cabe2
  text-color: #1cabe2

.evaluation-box-yellow
  background-color: #f6e9c1
  border: 2px solid #ffc20e
  color: #4a4a4a
  text-color: #4a4a4a

.evaluation-box-orange
  background-color: #f6d7c7
  border: 2px solid #f26a21
  color: #f26a21
  text-color: #f26a21

.evaluation-box-red
  background-color: #f3cace
  border: 2px solid #e2231a
  color: #e2231a
  text-color: #e2231a

.evaluation-text
  font-size: 10px
  line-height: 1.5
  margin-top: 8px
  width: 60px
  word-break: break-word

.evaluation-text-blue
  color: #1cabe2

.evaluation-text-yellow
  color: #4a4a4a

.evaluation-text-orange
  color: #f26a21

.evaluation-text-red
  color: #e2231a

//////////////////// checkpoints begins

.checkpoints
  margin-bottom: 16px

.checkpoint-container
  align-items: center
  display: flex
  font-size: 12px
  margin-top: 38px

.checkpoint-icon
  border-radius: 50%
  display: block
  margin-right: 8px
  max-height: 20px
  max-width: 20px
  min-height: 20px
  min-width: 20px

.checkpoint-icon-blue
  background-color: #1cabe2
  border: 1px solid #1cabe2
  &::after
    color: #ffffff
    content: "✓"
    font-weight: 600
    margin-left: 4px

.checkpoint-icon-orange
  background-color: #f26a21
  border: 1px solid #f26a21
  &::after
    color: #ffffff
    content: "✓"
    font-weight: 600
    margin-left: 4px

.checkpoint-icon-grey
  background-color: #c9c9c9
  border: 1px solid #c9c9c9
  &::after
    color: #ffffff
    content: ""
    font-weight: 600
    margin-left: 4px

.checkpoint-icon-red
  background-color: #e2231a
  border: 1px solid #e2231a
  &::after
    color: #ffffff
    content: "✕"
    font-weight: 600
    margin-left: 4px

.checkpoint-main-data
  margin-right: 4px

//////////////////// card-body-footer begins

.card-body-footer
  color: #4a4a4a
  font-size: 10px
  text-align: right

.footer-text
  line-height: 1

.footer-text-deadline
  border-bottom-right-radius: 4px
  left: 100px
  margin-top: 6px
  padding: 2px 20px
  position: relative
  width: 188px

.footer-em-atraso
  background: linear-gradient(90deg, rgba(249,249,249,1) 0%, rgba(226,35,26,1) 20%)
  color: #ffffff

.points-white
  color: #ffffff

.points-black
  color: #000000

//////////////////// link-card begins

.row-links
  display: flex
  gap: 8px
  max-width: 310px

.link-card
  align-items: center
  background-color: #e9e9e9
  border-bottom-left-radius: 4px
  border-bottom-right-radius: 4px
  box-shadow: #e9e9e9 0px 2px 8px 0px
  display: flex
  font-size: 12px
  height: 24px
  padding: 0 6.5px

.link-card > a
  color: #1cabe2
  cursor: pointer
  display: inline
  line-height: 2
  white-space: nowrap

.link-card > span
  color: #1cabe2
  font-size: 12px
  margin-left: 8px

.link-card-disabled
  opacity: 0.5
  pointer-events: none
</style>
