<template>
  <div class="resumo-page">
    <div class="resumo-top">
      <div class="resumo-top-text">
        <div v-if="showTitle" class="content-cidade">
          <span class="cidade"> {{ this.municipio }} </span>
        </div>

        <h1 class="resumo-top-title">Resumo</h1>

        <h1 class="resumo-top-text-um">
          Situação do município nos eixos de <span class="strong-text">Participação Cidadã</span> e <span class="strong-text">Gestão por Resultados</span> e <span class="strong-text">Resultados Sistêmicos</span>.
        </h1>
        <h1 class="resumo-top-text-dois">
          A realização das atividades do SELO UNICEF contribui para o alcance dos seguintes <span class="strong-text">Objetivos de Desenvolvimento Sustentável:</span>
        </h1>
      </div>
    </div>

    <ObjetivosDesenvolvimentoSustentavel
      :showTitle="false"
      :um="objetivosList.um"
      :dois="objetivosList.dois"
      :tres="objetivosList.tres"
      :quatro="objetivosList.quatro"
      :cinco="objetivosList.cinco"
      :seis="objetivosList.seis"
      :sete="objetivosList.sete"
      :oito="objetivosList.oito"
      :nove="objetivosList.nove"
      :dez="objetivosList.dez"
      :onze="objetivosList.onze"
      :doze="objetivosList.doze"
      :treze="objetivosList.treze"
      :quatorze="objetivosList.quatorze"
      :quinze="objetivosList.quinze"
      :dezesseis="objetivosList.dezesseis"
      :dezessete="objetivosList.dezessete"
    />

    <BlocoResumo
      :resumoData="resumoData"
      :type="blocos.um.type"
      :title="blocos.um.title"
      :points="blocos.um.points"
      :pointsMeta="blocos.um.pointsMeta"
      :pointsTotal="blocos.um.pointsTotal"
      :text="blocos.um.text"
    />

    <BlocoResumo
      :resumoData="resumoData"
      :type="blocos.dois.type"
      :title="blocos.dois.title"
      :points="blocos.dois.points"
      :pointsMeta="blocos.dois.pointsMeta"
      :pointsTotal="blocos.dois.pointsTotal"
      :text="blocos.dois.text"
    />

    <!-- @to-do: Descomentar para reabilitar INDICADORES-SOCIAIS -->
    <!-- <BlocoResumo
      :resumoData="resumoData"
      :type="blocos.tres.type"
      :title="blocos.tres.title"
      :points="blocos.tres.points"
      :pointsMeta="blocos.tres.pointsMeta"
      :pointsTotal="blocos.tres.pointsTotal"
      :text="blocos.tres.text"
    /> -->
  </div>
</template>

<script>
import axios from "axios";
import Endpoints from "../enums/endpoints.enum";

import ObjetivosDesenvolvimentoSustentavel from "./../components/ObjetivosDesenvolvimentoSustentavel.vue";
import BlocoResumo from "../components/resumo/BlocoResumo.vue"

export default {
  name: "Resumo",
  metaInfo: {
    title: 'SELO UNICEF - Painel - Resumo',
    meta: [
      { property: 'og:url', content: 'https://paineis.selounicef.org.br/resumo' },
      { property: 'og:title', content: 'SELO UNICEF - Painel - Resumo' },
      { property: 'og:description', content: 'Promovendo os direitos de crianças e adolescentes e contribuindo para alcançar os Objetivos do Desenvolvimento Sustentável' },
      { property: 'og:image', content: 'https://paineis.selounicef.org.br/img/painel-selo-og.jpg' }
    ]
  },
  components: {
    ObjetivosDesenvolvimentoSustentavel,
    BlocoResumo
  },
  data() {
    return {
      urlParams: this.$route.query,
      municipios: null,
      resumoData: null,
      pointsSystemicResults: null,
      showTitle: null,
      municipio: null,

      objetivosList: {
        um: false,
        dois: false,
        tres: false,
        quatro: false,
        cinco: false,
        seis: false,
        sete: false,
        oito: false,
        nove: false,
        dez: false,
        onze: false,
        doze: false,
        treze: false,
        quatorze: false,
        quinze: false,
        dezesseis: false,
        dezessete: false,
      },

      blocos: {
        um: {
          type: null,
          title: null,
          points: 0,
          pointsMeta: null,
          pointsTotal: null,
          text: null,
        },
        dois: {
          type: null,
          title: null,
          points: 0,
          pointsMeta: null,
          pointsTotal: null,
          text: null,
        },
        tres: {
          type: null,
          title: null,
          points: 0,
          pointsMeta: null,
          pointsTotal: null,
          text: null,
        }
      }
    };
  },
  watch:{
    $route (to){
      this.showTitle = to.path === "/" ? false : true;
    }
  },
  async mounted() {
    await this.getMunicipios(this.urlParams.uf);

    const rotaAtual = this.$router.currentRoute.path.toString();
    this.showTitle = rotaAtual === "/" ? false : true;

    setTimeout(() => {
      this.setObjetivosList();
      this.setDadosBlocos();
    }, 1000);
  },
  methods: {
    setObjetivosList() {
      this.objetivosList = {
        um: true,
        dois: true,
        tres: true,
        quatro: true,
        cinco: true,
        seis: true,
        sete: false,
        oito: true,
        nove: false,
        dez: true,
        onze: false,
        doze: false,
        treze: true,
        quatorze: false,
        quinze: false,
        dezesseis: true,
        dezessete: false,
      };
    },
    setDadosBlocos() {
      this.resumoData?.systemic_results?.forEach(systemicResults => {
        this.pointsSystemicResults =+ systemicResults.points;
      });

      this.blocos = {
        um: {
          type: 1,
          title: "Participação Cidadã e Gestão por Resultado",
          points: this.resumoData?.citizen_participation?.points,
          pointsMeta: 6,
          pointsTotal: 8,
          text: "Este eixo prevê que o município realize as atividades relacionadas aos Conselhos Municipais dos Direitos de Crianças e Adolescentes (CMDCA), incluindo os Fóruns Comunitários e as reuniões de acompanhamento; o funcionamento regular do Conselho Tutelar e a participação de adolescentes por meio dos Núcleos de Cidadania de Adolescentes (NUCA), um Plano de Participação Cidadã de Adolescentes e o desenvolvimento de ações nos temas de mudanças climáticas; prevenção da gravidez na adolescência; enfrentamento ao racismo e às violências; e o empoderamento de meninas e igualdade de gênero.",
        },
        dois: {
          type: 2,
          title: "Resultados Sistêmicos",
          points: this.pointsSystemicResults,
          pointsMeta: 11,
          pointsTotal: 14,
          text: "Composto por um conjunto de resultados e ações propostas pelo UNICEF para fortalecer políticas públicas para crianças e adolescentes. Os municípios pontuam em cada resultado sistêmico mediante a realização e comprovação dessas ações ou pelo alcance de uma meta estabelecida, e são avaliados conforme o número de resultados alcançados no final da edição.",
        },
        tres: {
          type: 3,
          title: "Indicadores Sociais",
          points: 7,
          pointsMeta: 8,
          pointsTotal: 14,
          text: "Um conjunto de indicadores construídos com dados oficiais para o diagnóstico, monitoramento e avaliação do impacto das políticas públicas voltadas às crianças e adolescentes no município. Espera-se que as ações desenvolvidas no âmbito do Selo UNICEF gerem um efeito positivo nos indicadores sociais e, por conseguinte, na vida das pessoas. A avaliação do eixo de Impacto Social é feita por meio da comparação do desempenho dos municípios em cada indicador, entre o ano inicial e o ano final da estratégia. Para pontuar nos indicadores, os municípios terão que melhorar (ou, pelo menos, manter) o desempenho identificado no ano inicial.",
        }
      };
    },
    async getDataCard(city_id) {
      const url = `${Endpoints.RESUMO}/${city_id}`;
      const { data: { data } } = await axios.get(url);
      this.resumoData = data;
    },
    fixMunicipioName(municipioName) {
      return municipioName.toLowerCase().replaceAll(' ', '-').normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    },
    async getMunicipios(uf) {
      const url = `${Endpoints.MUNICIPIOS}?uf=${uf}`;
      let municipioSelected = '';

      const { data: { data } } = await axios.get(url);
      this.municipios = Object.values(data);

      data.forEach((item) => {
        if (this.fixMunicipioName(item.name) == this.fixMunicipioName(this.urlParams.municipio)) {
          municipioSelected = item;
          this.municipio = municipioSelected.name ? `${municipioSelected.name} - ${municipioSelected.uf}` : "Selecione um município";
        }
      });

      localStorage.setItem("municipioId", municipioSelected.id);
      localStorage.setItem("municipioName", municipioSelected.name.toLowerCase().replaceAll(' ', '-').normalize('NFD').replace(/[\u0300-\u036f]/g, ""));
      localStorage.setItem("municipioUf", municipioSelected.uf.toLowerCase());

      await this.getDataCard(municipioSelected.id);
    },
  }
};
</script>

<style lang="sass" scoped>
.resumo-page
  color: #4a4a4a
  margin: auto
  max-width: var(--max-width)
  min-height: 50rem
  padding: 0 1rem

.resumo-top
  display: flex
  justify-content: space-between

.resumo-top-text
  margin-bottom: 42px
  // Descomentar para reexibir a bandeira
  // width: 65%
  @media screen and (max-width: 767px)
    width: 100%

.resumo-top-title
  color: #1cabe2
  font-size: 32px !important
  font-weight: 500
  margin: 20px 0 36px

.resumo-top-text-um,
.resumo-top-text-dois
  color: #4a4a4a
  font-size: 16px
  font-weight: 400

.strong-text
  font-weight: 500

.resumo-top-text-um
  margin-bottom: 32px

.resumo-top-bandeira
    display: flex
    justify-content: center
    width: 35%
    @media screen and (max-width: 767px)
      width: 0
.selo-municipio-participante
  height: 124px
  width: 134px
  @media screen and (max-width: 767px)
    display: none

.content-cidade
  margin-top: 55px
  
.cidade
  font-family: 'Roboto', sans-serif
  font-size: 32px !important
  font-weight: 500
  line-height: 1.2
  @media screen and (max-width: 768px)
    margin-left: 30px
  @media screen and (max-width: 375px)
    font-size: 35px
</style>
