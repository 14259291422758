<template>
  <div class="atividades-relacionadas-page">
    <div class="atividades-relacionadas">
      <h1 class="atividades-relacionadas-title">Atividades relacionadas</h1>

      <div class="cards">
        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="true"

          :isParticipacaoCidada="true"

          :linkTitleUmCard="cardUm.linkTitleUm"
          :linkUmCard="cardUm.linkUm"

          :cardPoints="cardUm.cardPoints"
          :cardTitle="cardUm.cardTitle"
          :cardPrimaryTextFirstBlock="cardUm.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardUm.progressUpdatedIn"
          :cardDate="cardUm.cardDate"

          :checkpointPointsDotUm="cardUm.checkpointPointsDotUm"
          :checkpointPointsDotDois="cardUm.checkpointPointsDotDois"
          :checkpointPointsDotTres="cardUm.checkpointPointsDotTres"

          :checkpointTextDotUm="cardUm.checkpointTextDotUm"
          :checkpointTextDotDois="cardUm.checkpointTextDotDois"
          :checkpointTextDotTres="cardUm.checkpointTextDotTres"

          :checkpointDateLimitDotUm="cardUm.checkpointDateLimitDotUm"
          :checkpointDateLimitDotDois="cardUm.checkpointDateLimitDotDois"
          :checkpointDateLimitDotTres="cardUm.checkpointDateLimitDotTres"
        />

        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :isParticipacaoCidada="true"

          :linkTitleUmCard="cardUm.linkTitleUm"
          :linkUmCard="cardUm.linkUm"

          :cardPoints="cardDois.cardPoints"
          :cardTitle="cardDois.cardTitle"
          :cardPrimaryTextFirstBlock="cardDois.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardDois.progressUpdatedIn"
          :cardDate="cardDois.cardDate"
        />

        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="true"

          :isParticipacaoCidada="true"

          :linkTitleUmCard="cardUm.linkTitleUm"
          :linkUmCard="cardUm.linkUm"

          :cardPoints="cardTres.cardPoints"
          :cardTitle="cardTres.cardTitle"
          :cardPrimaryTextFirstBlock="cardTres.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardTres.progressUpdatedIn"
          :cardDate="cardTres.cardDate"

          :checkpointPointsDotUm="cardTres.checkpointPointsDotUm"
          :checkpointPointsDotDois="cardTres.checkpointPointsDotDois"
          :checkpointPointsDotTres="cardTres.checkpointPointsDotTres"

          :checkpointTextDotUm="cardTres.checkpointTextDotUm"
          :checkpointTextDotDois="cardTres.checkpointTextDotDois"
          :checkpointTextDotTres="cardTres.checkpointTextDotTres"

          :checkpointDateLimitDotUm="cardTres.checkpointDateLimitDotUm"
          :checkpointDateLimitDotDois="cardTres.checkpointDateLimitDotDois"
          :checkpointDateLimitDotTres="cardTres.checkpointDateLimitDotTres"
        />

        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="true"

          :isParticipacaoCidada="true"

          :linkTitleUmCard="cardUm.linkTitleUm"
          :linkUmCard="cardUm.linkUm"

          :cardPoints="cardQuatro.cardPoints"
          :cardTitle="cardQuatro.cardTitle"
          :cardPrimaryTextFirstBlock="cardQuatro.cardPrimaryTextFirstBlock"
          :cardPrimaryTextSecondBlock="cardQuatro.cardPrimaryTextSecondBlock"
          :progressUpdatedIn="cardQuatro.progressUpdatedIn"
          :cardDate="cardQuatro.cardDate"

          :checkpointPointsDotUm="cardQuatro.checkpointPointsDotUm"
          :checkpointPointsDotDois="cardQuatro.checkpointPointsDotDois"

          :checkpointTextDotUm="cardQuatro.checkpointTextDotUm"
          :checkpointTextDotDois="cardQuatro.checkpointTextDotDois"

          :checkpointDateLimitDotUm="cardQuatro.checkpointDateLimitDotUm"
          :checkpointDateLimitDotDois="cardQuatro.checkpointDateLimitDotDois"
        />
      </div>

      <DotsLegends />
    </div>
  </div>
</template>

<script>
import CardResultadoSistemico from "./resultados-sistemicos/CardResultadoSistemico.vue"
import DotsLegends from "./dots-legends.vue"

export default {
  name: "AtividadesRelacionadasParticipacaoCidada",
  props: ["dataCards"],
  components: { CardResultadoSistemico, DotsLegends },
  data() {
    return {
      dataCard: null,

      cardUm: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,

        linkTitleUm: null,
        linkUm: null,

        checkpointPointsDotUm: null,
        checkpointPointsDotDois: null,
        checkpointPointsDotTres: null,

        checkpointTextDotUm: null,
        checkpointTextDotDois: null,
        checkpointTextDotTres: null,

        checkpointDateLimitDotUm: null,
        checkpointDateLimitDotDois: null,
        checkpointDateLimitDotTres: null,
      },

      cardDois: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,

        linkTitleUm: null,
        linkUm: null,
      },

      cardTres: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,

        linkTitleUm: null,
        linkUm: null,

        checkpointPointsDotUm: null,
        checkpointPointsDotDois: null,
        checkpointPointsDotTres: null,

        checkpointTextDotUm: null,
        checkpointTextDotDois: null,
        checkpointTextDotTres: null,

        checkpointDateLimitDotUm: null,
        checkpointDateLimitDotDois: null,
        checkpointDateLimitDotTres: null,
      },

      cardQuatro: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        cardPrimaryTextSecondBlock: null,
        progressUpdatedIn: null,
        cardDate: null,

        linkTitleUm: null,
        linkUm: null,

        checkpointPointsDotUm: null,
        checkpointPointsDotDois: null,

        checkpointTextDotUm: null,
        checkpointTextDotDois: null,

        checkpointDateLimitDotUm: null,
        checkpointDateLimitDotDois: null,
      },
    };
  },
  watch: {
    dataCards: {
      immediate: true, 
      handler (value) {
        if (value) {
          this.dataCard = value;
          this.setDataCardUm();
          this.setDataCardDois();
          this.setDataCardTres();
          this.setDataCardQuatro();
        }
      }
    }
  },
  methods: {
    setDataCardUm() {
      this.cardUm.cardPoints = this.dataCard?.general_data?.cmdca.points;
      this.cardUm.cardTitle = "Atuação do Conselho Municipal dos Direitos da Criança e do Adolescente (CMDCA)";
      this.cardUm.cardPrimaryTextFirstBlock = "Realizar reuniões do CMDCA, em 2022 e 2023, para o acompanhamento e avaliação do Plano de Ação Municipal pelos Direitos de Crianças e Adolescentes e monitoramento dos indicadores de impacto social.";
      this.cardUm.progressUpdatedIn = "diariamente";
      this.cardUm.cardDate = "30/06/2024";

      this.cardUm.linkTitleUm = "Abrir Atividade";
      this.cardUm.linkUm = "https://classroom.google.com";

      this.cardUm.checkpointPointsDotUm = this.dataCard?.general_data?.cmdca.first.points;
      this.cardUm.checkpointPointsDotDois = this.dataCard?.general_data?.cmdca.second.points;
      this.cardUm.checkpointPointsDotTres = this.dataCard?.general_data?.cmdca.regular_functioning.points;

      this.cardUm.checkpointTextDotUm = "1ª Reunião do CMDCA - 2022";
      this.cardUm.checkpointTextDotDois = "2ª Reunião do CMDCA - 2023";
      this.cardUm.checkpointTextDotTres = "Funcionamento regular do CMDCA";

      this.cardUm.checkpointDateLimitDotUm = "31/03/2023";
      this.cardUm.checkpointDateLimitDotDois = "31/03/2024";
      this.cardUm.checkpointDateLimitDotTres = "12/01/2024";
    },

    setDataCardDois() {
      this.cardDois.cardPoints = this.dataCard?.general_data?.council_regular_functioning.points;
      this.cardDois.cardTitle = "Atuação do Conselho Tutelar";
      this.cardDois.cardPrimaryTextFirstBlock = "Comprovar o funcionamento regular do Conselho Tutelar por meio de: eleição, existência de uma estrutura para local de atendimento e disponibilidade dos conselheiros para o atendimento da população.";
      this.cardDois.progressUpdatedIn = "diariamente";
      this.cardDois.cardDate = "30/06/2024";

      this.cardDois.linkTitleUm = "Abrir Atividade";
      this.cardDois.linkUm = "https://classroom.google.com";
    },

    setDataCardTres() {
      this.cardTres.cardPoints = this.dataCard?.general_data?.creation_and_functioning_of_nuca.points;
      this.cardTres.cardTitle = "Criação e funcionamento do Núcleo de Cidadania de Adolescentes (NUCA)";
      this.cardTres.cardPrimaryTextFirstBlock = "Constituir o NUCA e garantir a participação efetiva dos adolescentes, conforme previsto no Guia de Participação Cidadã e dentro dos quatro temas propostos: mudanças climáticas; prevenção da gravidez na adolescência e a promoção dos direitos à saúde sexual e saúde reprodutiva; enfrentamento ao racismo, xenofobia e às violências contra crianças e adolescentes; e empoderamento de meninas e de promoção da igualdade de gênero.";
      this.cardTres.progressUpdatedIn = "diariamente";
      this.cardTres.cardDate = "30/06/2024";

      this.cardTres.linkTitleUm = "Abrir Atividade";
      this.cardTres.linkUm = "https://classroom.google.com";

      this.cardTres.checkpointPointsDotUm = this.dataCard?.general_data?.creation_and_functioning_of_nuca.nuca_creation.points;
      this.cardTres.checkpointPointsDotDois = this.dataCard?.general_data?.creation_and_functioning_of_nuca.add_nuca_to_municipal_plan.points;
      this.cardTres.checkpointPointsDotTres = this.dataCard?.general_data?.creation_and_functioning_of_nuca.monitor_nuca_activities.points;

      this.cardTres.checkpointTextDotUm = "Criar o NUCA com pelo menos 16 adolescentes (sendo 8 meninas e 8 meninos e/ou outros gêneros)";
      this.cardTres.checkpointTextDotDois = "Garantir que as atividades do Plano de Participação Cidadã de Adolescentes estejam contempladas no Plano de Ação Municipal";
      this.cardTres.checkpointTextDotTres = "Implementar as 04 atividades obrigatórias do NUCA previstas no Plano de Participação Cidadã de Adolescentes";

      this.cardTres.checkpointDateLimitDotUm = "30/06/2022";
      this.cardTres.checkpointDateLimitDotDois = "30/06/2022";
      this.cardTres.checkpointDateLimitDotTres = "30/06/2024";
    },

    setDataCardQuatro() {
      this.cardQuatro.cardPoints = this.dataCard?.general_data?.accomplish_community_forums.points;
      this.cardQuatro.cardTitle = "Realizar os Fóruns Comunitários";
      this.cardQuatro.cardPrimaryTextFirstBlock = "Realizar dois Fóruns Comunitários que garantam a participação da comunidade na análise da situação das crianças e dos adolescentes, na definição ações pela garantia dos seus direitos e no monitoramento e avaliação do impacto dos projetos, programas e políticas sociais voltadas à melhoria das condições de vida dessa população";
      this.cardQuatro.cardPrimaryTextSecondBlock = "Como produtos dos fóruns, espera-se a elaboração do Plano de Ação Municipal e o relatório de avaliação da implementação das ações previstas no plano.";
      this.cardQuatro.progressUpdatedIn = "diariamente";
      this.cardQuatro.cardDate = "30/06/2024";

      this.cardQuatro.linkTitleUm = "Abrir Atividade";
      this.cardQuatro.linkUm = "https://classroom.google.com";

      this.cardQuatro.checkpointPointsDotUm = this.dataCard?.general_data?.accomplish_community_forums.first_community_forum.points;
      this.cardQuatro.checkpointPointsDotDois = this.dataCard?.general_data?.accomplish_community_forums.second_community_forum.points;

      this.cardQuatro.checkpointTextDotUm = "1º Fórum Comunitário (2022) - Elaboração do Plano de Ação Municipal";
      this.cardQuatro.checkpointTextDotDois = " 2º Fórum Comunitário (2024) - Acompanhamento da implementação do Plano de Ação Municipal";

      this.cardQuatro.checkpointDateLimitDotUm = "30/06/2022";
      this.cardQuatro.checkpointDateLimitDotDois = "30/06/2024";
    },
  }
};
</script>

<style lang="sass">
.atividades-relacionadas-page
  margin: 3rem auto
  max-width: var(--max-width)

.atividades-relacionadas-title
  border-bottom: 3px solid #1cabe2
  color: #1cabe2
  display: inline-block
  font-size: 20px
  font-weight: 500

.cards
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  margin-top: 32px
  @media screen and (max-width: 1008px)
    display: flex
    justify-content: center

// subtitle begins

.subtitle
  margin-top: 80px
  margin: 80px auto 0
  @media screen and (max-width: 1006px)
    max-width: 600px

.subtitle-title
  font-size: 12px
  margin-bottom: 20px

.subtitle-body
  display: flex
  justify-content: center

.subtitle-content
  display: flex
  flex-wrap: wrap
  gap: 20px
  justify-content: space-between

.subtitle-content-item
  display: flex
  width: 220px

.subtitle-content-ball
  border-radius: 50%
  display: block
  margin-right: 8px
  max-height: 20px
  max-width: 20px
  min-height: 20px
  min-width: 20px

.subtitle-content-ball-grey
  background-color: #c9c9c9
  border: 1px solid #c9c9c9
  &::after
    color: #ffffff
    content: ""
    font-weight: 600
    margin-left: 3px

.subtitle-content-ball-blue
  background-color: #1cabe2
  border: 1px solid #1cabe2
  &::after
    color: #ffffff
    content: "✓"
    font-weight: 600
    margin-left: 3px

.subtitle-content-ball-orange
  background-color: #f26a21
  border: 1px solid #f26a21
  &::after
    color: #ffffff
    content: "✓"
    font-weight: 600
    margin-left: 3px

.subtitle-content-ball-red
  background-color: #e2231a
  border: 1px solid #e2231a
  &::after
    color: #ffffff
    content: "✕"
    font-weight: 600
    margin-left: 3px

.subtitle-content-text
  font-size: 12px
</style>
