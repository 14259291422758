<template>
  <div class="card-expand" :class="styleByType">
    <div class="figura" v-on:click="expandRestrict()" >
      <div v-if="type === 'desenvolvimentoInfantil'" class="figura-img figura-desenvolvimento-infantil"></div>
      <div v-if="type === 'educacaoQualidade'" class="figura-img figura-educacao-qualidade"></div>
      <div v-if="type === 'habitosHigiene'" class="figura-img figura-habitos-higiene"></div>
      <div v-if="type === 'oportunidadesEducacao'" class="figura-img figura-oportunidades-educacao"></div>
      <div v-if="type === 'desenvolvimentoIntegral'" class="figura-img figura-desenvolvimento-integral"></div>
      <div v-if="type === 'prevencaoResposta'" class="figura-img figura-prevencao-resposta"></div>
      <div v-if="type === 'protecaoSocial'" class="figura-img figura-protecao-social"></div>
    </div>

    <div v-if="cardOverDate" class="orelha orelha-em-atraso">Em atraso</div>

    <div
        class="expand-content"
        :class="styleByType"
        v-on:click="expandRestrict()"
    >
      <div class="expand-text">{{ cardName }}</div>
      <div class="expand-numbers">
        <div class="expand-numbers-number">{{ pointNumber }}</div>
        <div class="expand-numbers-text">pontos</div>
      </div>
    </div>

    <div
        v-if="!expanded"
        class="restricted-footer"
        :class="[restrictFooter, borderFooter]"
    >
      <span v-if="amountActivities > 1" class="empty"></span>
      <span v-if="amountActivities > 0">
        {{ completeActivitiesCard }}/{{ amountActivities }} atividades foram realizadas
      </span>
      <span
          v-if="amountActivities > 1"
          class="material-icons md-18"
          v-on:click="expandRestrict()"
      >
        keyboard_arrow_down
      </span>

      <div>
        <span v-if="amountActivities < 1">Indicador abaixo da meta</span>
      </div>
    </div>

    <div v-if="expanded" class="expanded-footer">
      <div v-if="itemUmTitle" class="item-um">
        <div class="item-title">{{ itemUmTitle }}</div>
        <div class="item-data">
          <div class="item-data-message" :class="labelStatusStyle.itemUm">{{ labelStatus.itemUm }}</div>
          <div class="item-data-prazo">Prazo: {{ datas.itemUm }}</div>
        </div>
      </div>

      <div v-if="itemDoisTitle" class="item-dois">
        <div class="item-title">{{ itemDoisTitle }}</div>
        <div class="item-data">
          <div class="item-data-message" :class="labelStatusStyle.itemDois">{{ labelStatus.itemDois }}</div>
          <div class="item-data-prazo">Prazo: {{ datas.itemDois }}</div>
        </div>
      </div>

      <div v-if="itemTresTitle" class="item-tres">
        <div class="item-title">{{ itemTresTitle }}</div>
        <div class="item-data">
          <div class="item-data-message" :class="labelStatusStyle.itemTres">{{ labelStatus.itemTres }}</div>
          <div class="item-data-prazo">Prazo: {{ datas.itemTres }}</div>
        </div>
      </div>

      <div v-if="itemQuatroTitle" class="item-quatro">
        <div class="item-title">{{ itemQuatroTitle }}</div>
        <div class="item-data">
          <div class="item-data-message" :class="labelStatusStyle.itemQuatro">{{ labelStatus.itemQuatro }}</div>
          <div class="item-data-prazo">Prazo: {{ datas.itemQuatro }}</div>
        </div>
      </div>

      <div v-if="itemCincoTitle" class="item-cinco">
        <div class="item-title">{{ itemCincoTitle }}</div>
        <div class="item-data">
          <div class="item-data-message" :class="labelStatusStyle.itemCinco">{{ labelStatus.itemCinco }}</div>
          <div class="item-data-prazo">Prazo: {{ datas.itemCinco }}</div>
        </div>
      </div>

      <div v-if="itemSeisTitle" class="item-seis">
        <div class="item-title">{{ itemSeisTitle }}</div>
        <div class="item-data">
          <div class="item-data-message" :class="labelStatusStyle.itemSeis">{{ labelStatus.itemSeis }}</div>
          <div class="item-data-prazo">Prazo: {{ datas.itemSeis }}</div>
        </div>
      </div>

      <div v-if="itemSeteTitle" class="item-sete">
        <div class="item-title">{{ itemSeteTitle }}</div>
        <div class="item-data">
          <div class="item-data-message" :class="labelStatusStyle.itemSete">{{ labelStatus.itemSete }}</div>
          <div class="item-data-prazo">Prazo: {{ datas.itemSete }}</div>
        </div>
      </div>

      <div v-if="itemOitoTitle" class="item-oito">
        <div class="item-title">{{ itemOitoTitle }}</div>
        <div class="item-data">
          <div class="item-data-message" :class="labelStatusStyle.itemOito">{{ labelStatus.itemOito }}</div>
          <div class="item-data-prazo">Prazo: {{ datas.itemOito }}</div>
        </div>
      </div>

      <div v-if="itemNoveTitle" class="item-nove">
        <div class="item-title">{{ itemNoveTitle }}</div>
        <div class="item-data">
          <div class="item-data-message" :class="labelStatusStyle.itemNove">{{ labelStatus.itemNove }}</div>
          <div class="item-data-prazo">Prazo: {{ datas.itemNove }}</div>
        </div>
      </div>

      <div v-if="itemDezTitle" class="item-dez">
        <div class="item-title">{{ itemDezTitle }}</div>
        <div class="item-data">
          <div class="item-data-message" :class="labelStatusStyle.itemDez">{{ labelStatus.itemDez }}</div>
          <div class="item-data-prazo">Prazo: {{ datas.itemDez }}</div>
        </div>
      </div>

      <div v-if="itemOnzeTitle" class="item-onze">
        <div class="item-title">{{ itemOnzeTitle }}</div>
        <div class="item-data">
          <div class="item-data-message" :class="labelStatusStyle.itemOnze">{{ labelStatus.itemOnze }}</div>
          <div class="item-data-prazo">Prazo: {{ datas.itemOnze }}</div>
        </div>
      </div>

      <div class="arrow-restrict" v-on:click="expandRestrict()">
        <span class="material-icons md-18">expand_less</span>
      </div>
    </div>

    <div class="bg-expanded" v-if="expanded"></div>
  </div>
</template>

<script>
import GeneralsUtil from "./../../utils/generals-util"

export default {
  name: "CardExpandResultadoSistemico",
  props: [
    "type", "cardName", "cardPoints", "completeActivities", "amountActivities",

    "itemUmPoints", "itemUmTitle", "itemUmStatus", "itemUmDate",
    "itemDoisPoints", "itemDoisTitle", "itemDoisStatus", "itemDoisDate",
    "itemTresPoints", "itemTresTitle", "itemTresStatus", "itemTresDate",
    "itemQuatroPoints", "itemQuatroTitle", "itemQuatroStatus", "itemQuatroDate",
    "itemCincoPoints", "itemCincoTitle", "itemCincoStatus", "itemCincoDate",
    "itemSeisPoints", "itemSeisTitle", "itemSeisStatus", "itemSeisDate",
    "itemSetePoints", "itemSeteTitle", "itemSeteStatus", "itemSeteDate",
    "itemOitoPoints", "itemOitoTitle", "itemOitoStatus", "itemOitoDate",
    "itemNovePoints", "itemNoveTitle", "itemNoveStatus", "itemNoveDate",
    "itemDezPoints", "itemDezTitle", "itemDezStatus", "itemDezDate",
    "itemOnzePoints", "itemOnzeTitle", "itemOnzeStatus", "itemOnzeDate",
  ],
  components: {},
  data() {
    return {
      expanded: false,
      restrictFooter: null,
      pointNumber: 0,

      styleByType: null,
      borderFooter: null,
      styleLabelDatas: null,
      completeActivitiesCard: 0,

      cardOverDate: false,

      datas: {
        itemUm: null,
        itemDois: null,
        itemTres: null,
        itemQuatro: null,
        itemCinco: null,
        itemSeis: null,
        itemSete: null,
        itemOito: null,
        itemNove: null,
        itemDez: null,
        itemOnze: null,
      },

      labelDatas: {
        itemUm: null,
        itemDois: null,
        itemTres: null,
        itemQuatro: null,
        itemCinco: null,
        itemSeis: null,
        itemSete: null,
        itemOito: null,
        itemNove: null,
        itemDez: null,
        itemOnze: null,
      },

      labelStatusStyle: {
        itemUm: null,
        itemDois: null,
        itemTres: null,
        itemQuatro: null,
        itemCinco: null,
        itemSeis: null,
        itemSete: null,
        itemOito: null,
        itemNove: null,
        itemDez: null,
        itemOnze: null,
      },

      labelStatus: {
        itemUm: null,
        itemDois: null,
        itemTres: null,
        itemQuatro: null,
        itemCinco: null,
        itemSeis: null,
        itemSete: null,
        itemOito: null,
        itemNove: null,
        itemDez: null,
        itemOnze: null,
      },

      orelhaAtraso: {
        itemUm: null,
        itemDois: null,
        itemTres: null,
        itemQuatro: null,
        itemCinco: null,
        itemSeis: null,
        itemSete: null,
        itemOito: null,
        itemNove: null,
        itemDez: null,
        itemOnze: null,
      }
    };
  },
  mounted() {
    this.pointNumber = this.cardPoints == null ? 0 : this.cardPoints;
    this.setCardsStyle();
    this.datasPorExtenso();
    this.setLabelDataStyle();
    this.checkAtraso();
    this.setAmountComplete();
  },
  methods: {
    expandRestrict() {
      if (this.amountActivities == 1) return;

      this.expanded = !this.expanded;
      this.restrictFooter = this.expanded ? "restricted-footer-hide" : this.restrictFooter = "";
    },
    setCardsStyle() {
      if (this.type === "desenvolvimentoInfantil") {
        this.styleByType = "card-desenvolvimento-infantil";
        this.borderFooter = "border-desenvolvimento-infantil";
      }

      if (this.type === "educacaoQualidade") {
        this.styleByType = "card-educacao-qualidade";
        this.borderFooter = "border-educacao-qualidade";
      }

      if (this.type === "habitosHigiene") {
        this.styleByType = "card-habitos-higiene";
        this.borderFooter = "border-habitos-higiene";
      }

      if (this.type === "oportunidadesEducacao") {
        this.styleByType = "card-oportunidades-educacao";
        this.borderFooter = "border-oportunidades-educacao";
      }

      if (this.type === "desenvolvimentoIntegral") {
        this.styleByType = "card-desenvolvimento-integral";
        this.borderFooter = "border-desenvolvimento-integral";
      }

      if (this.type === "prevencaoResposta") {
        this.styleByType = "card-prevencao-resposta";
        this.borderFooter = "border-prevencao-resposta";
      }

      if (this.type === "protecaoSocial") {
        this.styleByType = "card-protecao-social";
        this.borderFooter = "border-protecao-social";
      }
    },
    checkAtraso() {
      if (
          this.labelStatus.itemUm == "Em atraso" ||
          this.labelStatus.itemDois == "Em atraso" ||
          this.labelStatus.itemTres == "Em atraso" ||
          this.labelStatus.itemQuatro == "Em atraso" ||
          this.labelStatus.itemCinco == "Em atraso" ||
          this.labelStatus.itemSeis == "Em atraso" ||
          this.labelStatus.itemSete == "Em atraso" ||
          this.labelStatus.itemOito == "Em atraso" ||
          this.labelStatus.itemNove == "Em atraso" ||
          this.labelStatus.itemDez == "Em atraso" ||
          this.labelStatus.itemOnze == "Em atraso"
      ) {
        this.cardOverDate = true;
      }
    },
    datasPorExtenso() {
      this.datas.itemUm = this.itemUmDate ? GeneralsUtil.monthFull(this.itemUmDate) : null;
      this.datas.itemDois = this.itemDoisDate ? GeneralsUtil.monthFull(this.itemDoisDate) : null;
      this.datas.itemTres = this.itemTresDate ? GeneralsUtil.monthFull(this.itemTresDate) : null;
      this.datas.itemQuatro = this.itemQuatroDate ? GeneralsUtil.monthFull(this.itemQuatroDate) : null;
      this.datas.itemCinco = this.itemCincoDate ? GeneralsUtil.monthFull(this.itemCincoDate) : null;
      this.datas.itemSeis = this.itemSeisDate ? GeneralsUtil.monthFull(this.itemSeisDate) : null;
      this.datas.itemSete = this.itemSeteDate ? GeneralsUtil.monthFull(this.itemSeteDate) : null;
      this.datas.itemOito = this.itemOitoDate ? GeneralsUtil.monthFull(this.itemOitoDate) : null;
      this.datas.itemNove = this.itemNoveDate ? GeneralsUtil.monthFull(this.itemNoveDate) : null;
      this.datas.itemDez = this.itemDezDate ? GeneralsUtil.monthFull(this.itemDezDate) : null;
      this.datas.itemOnze = this.itemOnzeDate ? GeneralsUtil.monthFull(this.itemOnzeDate) : null;
    },
    setLabelDataStyle() {
      // UM
      if (this.itemUmDate) {
        if (this.itemUmPoints == 2) {
          this.labelStatusStyle.itemUm = "item-data-message-sucesso";
          this.labelStatus.itemUm = "Concluído com sucesso";
        }

        if (this.itemUmPoints == 1) {
          this.labelStatusStyle.itemUm = "item-data-message-concluido-fora-prazo";
          this.labelStatus.itemUm = "Realizado fora do prazo";
        }

        if (this.itemUmPoints == 0 && !GeneralsUtil.isDateOver(this.itemUmDate)) {
          this.labelStatusStyle.itemUm = "item-data-message-progresso";
          this.labelStatus.itemUm = "Em progresso";
        }

        if (this.itemUmPoints == 0 && GeneralsUtil.isDateOver(this.itemUmDate)) {
          this.labelStatusStyle.itemUm = "item-data-message-atraso";
          this.labelStatus.itemUm = "Em atraso";
        }
      }

      // DOIS
      if (this.itemDoisDate) {
        if (this.itemDoisPoints == 2) {
          this.labelStatusStyle.itemDois = "item-data-message-sucesso";
          this.labelStatus.itemDois = "Concluído com sucesso";
        }

        if (this.itemDoisPoints == 1) {
          this.labelStatusStyle.itemDois = "item-data-message-concluido-fora-prazo";
          this.labelStatus.itemDois = "Realizado fora do prazo";
        }

        if (this.itemDoisPoints == 0 && !GeneralsUtil.isDateOver(this.itemDoisDate)) {
          this.labelStatusStyle.itemDois = "item-data-message-progresso";
          this.labelStatus.itemDois = "Em progresso";
        }

        if (this.itemDoisPoints == 0 && GeneralsUtil.isDateOver(this.itemDoisDate)) {
          this.labelStatusStyle.itemDois = "item-data-message-atraso";
          this.labelStatus.itemDois = "Em atraso";
        }
      }

      // TRES
      if (this.itemTresDate) {
        if (this.itemTresPoints == 2) {
          this.labelStatusStyle.itemTres = "item-data-message-sucesso";
          this.labelStatus.itemTres = "Concluído com sucesso";
        }

        if (this.itemTresPoints == 1) {
          this.labelStatusStyle.itemTres = "item-data-message-concluido-fora-prazo";
          this.labelStatus.itemTres = "Realizado fora do prazo";
        }

        if (this.itemTresPoints == 0 && !GeneralsUtil.isDateOver(this.itemTresDate)) {
          this.labelStatusStyle.itemTres = "item-data-message-progresso";
          this.labelStatus.itemTres = "Em progresso";
        }

        if (this.itemTresPoints == 0 && GeneralsUtil.isDateOver(this.itemTresDate)) {
          this.labelStatusStyle.itemTres = "item-data-message-atraso";
          this.labelStatus.itemTres = "Em atraso";
        }
      }

      // QUATRO
      if (this.itemQuatroDate) {
        if (this.itemQuatroPoints == 2) {
          this.labelStatusStyle.itemQuatro = "item-data-message-sucesso";
          this.labelStatus.itemQuatro = "Concluído com sucesso";
        }

        if (this.itemQuatroPoints == 1) {
          this.labelStatusStyle.itemQuatro = "item-data-message-concluido-fora-prazo";
          this.labelStatus.itemQuatro = "Realizado fora do prazo";
        }

        if (this.itemQuatroPoints == 0 && !GeneralsUtil.isDateOver(this.itemQuatroDate)) {
          this.labelStatusStyle.itemQuatro = "item-data-message-progresso";
          this.labelStatus.itemQuatro = "Em progresso";
        }

        if (this.itemQuatroPoints == 0 && GeneralsUtil.isDateOver(this.itemQuatroDate)) {
          this.labelStatusStyle.itemQuatro = "item-data-message-atraso";
          this.labelStatus.itemQuatro = "Em atraso";
        }
      }

      // CINCO
      if (this.itemCincoDate) {
        if (this.itemCincoPoints == 2) {
          this.labelStatusStyle.itemCinco = "item-data-message-sucesso";
          this.labelStatus.itemCinco = "Concluído com sucesso";
        }

        if (this.itemCincoPoints == 1) {
          this.labelStatusStyle.itemCinco = "item-data-message-concluido-fora-prazo";
          this.labelStatus.itemCinco = "Realizado fora do prazo";
        }

        if (this.itemCincoPoints == 0 && !GeneralsUtil.isDateOver(this.itemCincoDate)) {
          this.labelStatusStyle.itemCinco = "item-data-message-progresso";
          this.labelStatus.itemCinco = "Em progresso";
        }

        if (this.itemCincoPoints == 0 && GeneralsUtil.isDateOver(this.itemCincoDate)) {
          this.labelStatusStyle.itemCinco = "item-data-message-atraso";
          this.labelStatus.itemCinco = "Em atraso";
        }
      }

      // SEIS
      if (this.itemSeisDate) {
        if (this.itemSeisPoints == 2) {
          this.labelStatusStyle.itemSeis = "item-data-message-sucesso";
          this.labelStatus.itemSeis = "Concluído com sucesso";
        }

        if (this.itemSeisPoints == 1) {
          this.labelStatusStyle.itemSeis = "item-data-message-concluido-fora-prazo";
          this.labelStatus.itemSeis = "Realizado fora do prazo";
        }

        if (this.itemSeisPoints == 0 && !GeneralsUtil.isDateOver(this.itemSeisDate)) {
          this.labelStatusStyle.itemSeis = "item-data-message-progresso";
          this.labelStatus.itemSeis = "Em progresso";
        }

        if (this.itemSeisPoints == 0 && GeneralsUtil.isDateOver(this.itemSeisDate)) {
          this.labelStatusStyle.itemSeis = "item-data-message-atraso";
          this.labelStatus.itemSeis = "Em atraso";
        }
      }

      // SETE
      if (this.itemSeteDate) {
        if (this.itemSetePoints == 2) {
          this.labelStatusStyle.itemSete = "item-data-message-sucesso";
          this.labelStatus.itemSete = "Concluído com sucesso";
        }

        if (this.itemSetePoints == 1) {
          this.labelStatusStyle.itemSete = "item-data-message-concluido-fora-prazo";
          this.labelStatus.itemSete = "Realizado fora do prazo";
        }

        if (this.itemSetePoints == 0 && !GeneralsUtil.isDateOver(this.itemSeteDate)) {
          this.labelStatusStyle.itemSete = "item-data-message-progresso";
          this.labelStatus.itemSete = "Em progresso";
        }

        if (this.itemSetePoints == 0 && GeneralsUtil.isDateOver(this.itemSeteDate)) {
          this.labelStatusStyle.itemSete = "item-data-message-atraso";
          this.labelStatus.itemSete = "Em atraso";
        }
      }

      // OITO
      if (this.itemOitoDate) {
        if (this.itemOitoPoints == 2) {
          this.labelStatusStyle.itemOito = "item-data-message-sucesso";
          this.labelStatus.itemOito = "Concluído com sucesso";
        }

        if (this.itemOitoPoints == 1) {
          this.labelStatusStyle.itemOito = "item-data-message-concluido-fora-prazo";
          this.labelStatus.itemOito = "Realizado fora do prazo";
        }

        if (this.itemOitoPoints == 0 && !GeneralsUtil.isDateOver(this.itemOitoDate)) {
          this.labelStatusStyle.itemOito = "item-data-message-progresso";
          this.labelStatus.itemOito = "Em progresso";
        }

        if (this.itemOitoPoints == 0 && GeneralsUtil.isDateOver(this.itemOitoDate)) {
          this.labelStatusStyle.itemOito = "item-data-message-atraso";
          this.labelStatus.itemOito = "Em atraso";
        }
      }

      // NOVE
      if (this.itemNoveDate) {
        if (this.itemNovePoints == 2) {
          this.labelStatusStyle.itemNove = "item-data-message-sucesso";
          this.labelStatus.itemNove = "Concluído com sucesso";
        }

        if (this.itemNovePoints == 1) {
          this.labelStatusStyle.itemNove = "item-data-message-concluido-fora-prazo";
          this.labelStatus.itemNove = "Realizado fora do prazo";
        }

        if (this.itemNovePoints == 0 && !GeneralsUtil.isDateOver(this.itemNoveDate)) {
          this.labelStatusStyle.itemNove = "item-data-message-progresso";
          this.labelStatus.itemNove = "Em progresso";
        }

        if (this.itemNovePoints == 0 && GeneralsUtil.isDateOver(this.itemNoveDate)) {
          this.labelStatusStyle.itemNove = "item-data-message-atraso";
          this.labelStatus.itemNove = "Em atraso";
        }
      }

      // DEZ
      if (this.itemDezDate) {
        if (this.itemDezPoints == 2) {
          this.labelStatusStyle.itemDez = "item-data-message-sucesso";
          this.labelStatus.itemDez = "Concluído com sucesso";
        }

        if (this.itemDezPoints == 1) {
          this.labelStatusStyle.itemDez = "item-data-message-concluido-fora-prazo";
          this.labelStatus.itemDez = "Realizado fora do prazo";
        }

        if (this.itemDezPoints == 0 && !GeneralsUtil.isDateOver(this.itemDezDate)) {
          this.labelStatusStyle.itemDez = "item-data-message-progresso";
          this.labelStatus.itemDez = "Em progresso";
        }

        if (this.itemDezPoints == 0 && GeneralsUtil.isDateOver(this.itemDezDate)) {
          this.labelStatusStyle.itemDez = "item-data-message-atraso";
          this.labelStatus.itemDez = "Em atraso";
        }
      }

      // ONZE
      if (this.itemOnzeDate) {
        if (this.itemOnzePoints == 2) {
          this.labelStatusStyle.itemOnze = "item-data-message-sucesso";
          this.labelStatus.itemOnze = "Concluído com sucesso";
        }

        if (this.itemOnzePoints == 1) {
          this.labelStatusStyle.itemOnze = "item-data-message-concluido-fora-prazo";
          this.labelStatus.itemOnze = "Realizado fora do prazo";
        }

        if (this.itemOnzePoints == 0 && !GeneralsUtil.isDateOver(this.itemOnzeDate)) {
          this.labelStatusStyle.itemOnze = "item-data-message-progresso";
          this.labelStatus.itemOnze = "Em progresso";
        }

        if (this.itemNovePoints == 0 && GeneralsUtil.isDateOver(this.itemNoveDate)) {
          this.labelStatusStyle.itemNove = "item-data-message-atraso";
          this.labelStatus.itemNove = "Em atraso";
        }
      }
    },
    setAmountComplete() {
      Object.values(this.labelStatus).forEach(status => {
        if (status == "Concluído com sucesso" || status == "Realizado fora do prazo") {
          this.completeActivitiesCard = ++this.completeActivitiesCard;
        }
      });
    }
  },
};
</script>

<style lang="sass" scoped>
.card-expand
  box-shadow: 0px 3px 6px #00000029
  border-radius: 4px 0 4px 4px
  color: #1cabe2
  height: 100%
  margin-top: 40px
  width: 308px

.expand-content
  align-items: center
  border-radius: 4px
  color: #ffffff
  cursor: pointer
  display: flex
  height: 60px
  justify-content: space-between
  padding: 10px
  user-select: none
  -moz-user-select: none
  -khtml-user-select: none
  -webkit-user-select: none
  -o-user-select: none

.expand-text
  font-size: 16px
  margin-bottom: 60px
  max-width: 220px

.expand-numbers
  margin-bottom: 60px
  text-align: right

.expand-numbers-number
  font-size: 50px
  margin-bottom: 8px

.expand-numbers-text
  font-size: 12px
  line-height: 2
  text-transform: uppercase

.restricted-footer
  align-items: center
  background-color: #ffffff
  border: 2px solid #1cabe2
  border-radius: 4px
  color: #4a4a4a
  display: flex
  height: 40px
  justify-content: center

.restricted-footer-hide
  display: none

.empty,
.material-icons
  cursor: pointer
  font-weight: 600
  width: 27px

.expanded-footer
  align-items: center
  background-color: #9c9c9c
  border-radius: 0 0 4px 4px
  display: flex
  flex-direction: column
  padding: 10px 10px 0 10px
  position: absolute
  width: 308px
  z-index: 2

.item-um,
.item-dois,
.item-tres,
.item-quatro,
.item-cinco,
.item-seis,
.item-sete,
.item-oito,
.item-nove,
.item-dez,
.item-onze
  background-color: #ffffff
  border-radius: 4px
  color: #4a4a4a
  display: flex
  flex-direction: column
  min-height: 70px
  justify-content: space-between
  margin-bottom: 10px
  width: 100%

.item-title
  align-items: center
  color: #4a4a4a
  display: flex
  font-size: 12px
  font-weight: 500
  justify-content: center
  min-height: 45px
  padding: 0 9px
  text-align: center
  width: 100%

.item-data
  display: flex
  height: 26px

.item-data-message
  align-items: center
  background-color: #1cabe2
  border: 1px solid #ffffff
  border-radius: 4px
  color: #ffffff
  display: flex
  font-size: 9px
  justify-content: center
  width: 50%

.item-data-message-sucesso
  background-color: #1cabe2

.item-data-message-progresso
  background-color: #ffc20e
  color: #000000

.item-data-message-concluido-fora-prazo
  background-color: #f26a21

.item-data-message-atraso
  background-color: #e2231a

.item-data-prazo
  align-items: center
  display: flex
  font-size: 9px
  justify-content: center
  width: 50%

.arrow-restrict
  align-items: center
  background-color: #ffffff
  border-radius: 50%
  box-shadow: 0px 3px 6px #00000029
  color: #4a4a4a
  cursor: pointer
  display: flex
  height: 37px
  justify-content: center
  position: relative
  top: 15px
  width: 37px

.arrow-restrict .material-icons
  align-items: center
  display: flex
  font-size: 35px
  font-weight: 400
  justify-content: center

.bg-expanded
  padding-bottom: 40px

.card-desenvolvimento-infantil
  background-color: #df5d4e

.card-educacao-qualidade
  background-color: #bd7353

.card-habitos-higiene
  background-color: #d58a4c

.card-oportunidades-educacao
  background-color: #b99c3e

.card-desenvolvimento-integral
  background-color: #5b8f88

.card-prevencao-resposta
  background-color: #1c8076

.card-protecao-social
  background-color: #50a9c4

.border-desenvolvimento-infantil
  border: 2px solid #df5d4e

.border-educacao-qualidade
  border: 2px solid #bd7353

.border-habitos-higiene
  border: 2px solid #d58a4c

.border-oportunidades-educacao
  border: 2px solid #b99c3e

.border-desenvolvimento-integral
  border: 2px solid #5b8f88

.border-prevencao-resposta
  border: 2px solid #1c8076

.border-protecao-social
  border: 2px solid #50a9c4

.orelha
  align-items: center
  border-radius: 4px 4px 0 0
  display: flex
  justify-content: center
  left: 194px
  position: relative
  top: -100px
  width: 114px

.orelha-em-atraso
  background-color: #e2231a
  color: #ffffff

.figura
  cursor: pointer
  height: 80px
  margin: 0 auto
  position: relative
  top: -40px
  width: 80px

.figura-img
  background-repeat: no-repeat
  border: 3px solid #ffffff
  border-radius: 50%
  height: 80px
  width: 80px

.figura-desenvolvimento-infantil
  background-color: #df5d4e
  background-image: url("../../assets/titles/resultados_sistemicos_01.svg")

.figura-educacao-qualidade
  background-color: #bd7353
  background-image: url("../../assets/titles/resultados_sistemicos_02.svg")

.figura-habitos-higiene
  background-color: #d58a4c
  background-image: url("../../assets/titles/resultados_sistemicos_03.svg")

.figura-oportunidades-educacao
  background-color: #b99c3e
  background-image: url("../../assets/titles/resultados_sistemicos_04.svg")

.figura-desenvolvimento-integral
  background-color: #5b8f88
  background-image: url("../../assets/titles/resultados_sistemicos_05.svg")

.figura-prevencao-resposta
  background-color: #1c8076
  background-image: url("../../assets/titles/resultados_sistemicos_06.svg")

.figura-protecao-social
  background-color: #50a9c4
  background-image: url("../../assets/titles/resultados_sistemicos_07.svg")
</style>
