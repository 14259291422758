<template>
  <div class="atividades-relacionadas-page">
    <div class="atividades-relacionadas">
      <h1 class="atividades-relacionadas-title">Atividades relacionadas</h1>

      <div class="cards">
        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="true"

          :cardPoints="cardUm.cardPoints"
          :cardTitle="cardUm.cardTitle"
          :cardPrimaryTextFirstBlock="cardUm.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardUm.progressUpdatedIn"
          :cardDate="cardUm.cardDate"
          
          :linkTitleUmCard="cardUm.linkTitleUm"
          :linkUmCard="cardUm.linkUm"

          :linkTitleDoisCard="cardUm.linkTitleDois"
          :linkDoisCard="cardUm.linkDois"

          :checkpointPointsDotUm="cardUm.checkpointPointsDotUm"
          :checkpointPointsDotDois="cardUm.checkpointPointsDotDois"

          :checkpointTextDotUm="cardUm.checkpointTextDotUm"
          :checkpointTextDotDois="cardUm.checkpointTextDotDois"

          :checkpointDateLimitDotUm="cardUm.checkpointDateLimitDotUm"
          :checkpointDateLimitDotDois="cardUm.checkpointDateLimitDotDois"
        />

        <CardResultadoSistemico
          :percentCardType="true"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardDois.cardPoints"
          :cardTitle="cardDois.cardTitle"
          :cardPrimaryTextFirstBlock="cardDois.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardDois.progressUpdatedIn"
          :cardDate="cardDois.cardDate"

          :linkTitleUmCard="cardDois.linkTitleUm"
          :linkUmCard="cardDois.linkUm"

          :percentageValue="cardDois.percentageValue"
          :percentageScoreCurrent="cardDois.percentageScoreCurrent"
          :percentageGoalProps="cardDois.percentageGoalProps"
          :percentageScoreGoals="cardDois.percentageScoreGoals"
          :percentageScoreItem="cardDois.percentageScoreItem"
        />

        <CardResultadoSistemico
          :percentCardType="cardTres.hasPercent"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardTres.cardPoints"
          :cardTitle="cardTres.cardTitle"
          :cardPrimaryTextFirstBlock="cardTres.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardTres.progressUpdatedIn"
          :cardDate="cardTres.cardDate"

          :linkTitleUmCard="cardTres.linkTitleUm"
          :linkUmCard="cardTres.linkUm"

          :percentageValue="cardTres.percentageValue"
          :percentageScoreCurrent="cardTres.percentageScoreCurrent"
          :percentageGoalProps="cardTres.percentageGoalProps"
          :percentageScoreGoals="cardTres.percentageScoreGoals"
          :percentageScoreItem="cardTres.percentageScoreItem"
        />

        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="true"
          :checkpointsCardType="false"

          :cardPoints="cardSete.cardPoints"
          :cardTitle="cardSete.cardTitle"
          :cardPrimaryTextFirstBlock="cardSete.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardSete.progressUpdatedIn"
          :cardDate="cardSete.cardDate"

          :linkTitleUmCard="cardSete.linkTitleUm"
          :linkUmCard="cardSete.linkUm"
          
          :linkTitleDoisCard="cardSete.linkTitleDois"
          :linkDoisCard="cardSete.linkDois"

          :linkTitleTresCard="cardSete.linkTitleTres"
          :linkTresCard="cardSete.linkTres"

          :linkTitleQuatroCard="cardSete.linkTitleQuatro"
          :linkQuatroCard="cardSete.linkQuatro"

          :evaluationValueUm="cardSete.evaluationValueUm"
          :evaluationValueQuatro="cardSete.evaluationValueQuatro"
          :evaluationGoalUm="cardSete.evaluationGoalUm"
          :evaluationTextUm="cardSete.evaluationTextUm"
          :evaluationDateLimitUm="cardSete.evaluationDateLimitUm"
        />

        <CardResultadoSistemico
          :percentCardType="true"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardQuatro.cardPoints"
          :cardTitle="cardQuatro.cardTitle"
          :cardPrimaryTextFirstBlock="cardQuatro.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardQuatro.progressUpdatedIn"
          :cardDate="cardQuatro.cardDate"

          :linkTitleUmCard="cardQuatro.linkTitleUm"
          :linkUmCard="cardQuatro.linkUm"

          :percentageValue="cardQuatro.percentageValue"
          :percentageScoreCurrent="cardQuatro.percentageScoreCurrent"
          :percentageGoalProps="cardQuatro.percentageGoalProps"
          :percentageScoreGoals="cardQuatro.percentageScoreGoals"
          :percentageScoreItem="cardQuatro.percentageScoreItem"
        />

        <CardResultadoSistemico
          :percentCardType="true"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardOito.cardPoints"
          :cardTitle="cardOito.cardTitle"
          :cardPrimaryTextFirstBlock="cardOito.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardOito.progressUpdatedIn"
          :cardDate="cardOito.cardDate"

          :linkTitleUmCard="cardOito.linkTitleUm"
          :linkUmCard="cardOito.linkUm"

          :percentageValue="cardOito.percentageValue"
          :percentageScoreCurrent="cardOito.percentageScoreCurrent"
          :percentageGoalProps="cardOito.percentageGoalProps"
          :percentageScoreGoals="cardOito.percentageScoreGoals"
          :percentageScoreItem="cardOito.percentageScoreItem"
        />

        <CardResultadoSistemico
          :percentCardType="true"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardNove.cardPoints"
          :cardTitle="cardNove.cardTitle"
          :cardPrimaryTextFirstBlock="cardNove.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardNove.progressUpdatedIn"
          :cardDate="cardNove.cardDate"

          :linkTitleUmCard="cardNove.linkTitleUm"
          :linkUmCard="cardNove.linkUm"

          :percentageValue="cardNove.percentageValue"
          :percentageScoreCurrent="cardNove.percentageScoreCurrent"
          :percentageGoalProps="cardNove.percentageGoalProps"
          :percentageScoreGoals="cardNove.percentageScoreGoals"
          :percentageScoreItem="cardNove.percentageScoreItem"
        />

        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardDez.cardPoints"
          :cardTitle="cardDez.cardTitle"
          :cardPrimaryTextFirstBlock="cardDez.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardDez.progressUpdatedIn"
          :cardDate="cardDez.cardDate"

          :linkTitleUmCard="cardDez.linkTitleUm"
          :linkUmCard="cardDez.linkUm"
        />

        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardSeis.cardPoints"
          :cardTitle="cardSeis.cardTitle"
          :cardPrimaryTextFirstBlock="cardSeis.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardSeis.progressUpdatedIn"
          :cardDate="cardSeis.cardDate"

          :linkTitleUmCard="cardSeis.linkTitleUm"
          :linkUmCard="cardSeis.linkUm"
        />
      </div>

      <div>
        <DotsLegends />
      </div>
    </div>
  </div>
</template>

<script>
import CardResultadoSistemico from "./resultados-sistemicos/CardResultadoSistemico.vue"
import DotsLegends from "./dots-legends.vue"
import GeneralsUtil from '../../utils/generals-util';

export default {
  name: "AtividadesRelacionadasRS01",
  props: ["dataCards"],
  components: { CardResultadoSistemico, DotsLegends },
  data() {
    return {
      dataCard: null,

      cardUm: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,

        linkTitleDois: null,
        linkDois: null,

        checkpointPointsDotUm: null,
        checkpointPointsDotDois: null,

        checkpointTextDotUm: null,
        checkpointTextDotDois: null,

        checkpointDateLimitDotUm: null,
        checkpointDateLimitDotDois: null,
      },

      cardDois: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,

        linkTitleUm: null,
        linkUm: null,

        percentageValue: null,
        percentageScoreCurrent: null,
        percentageGoalProps: null,
        percentageScoreGoals: null,
        percentageScoreItem: null,
      },

      cardTres: {
        hasPercent: false,
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,

        linkTitleUm: null,
        linkUm: null,

        percentageValue: null,
        percentageScoreCurrent: null,
        percentageGoalProps: null,
        percentageScoreGoals: null,
        percentageScoreItem: null,
      },

      cardQuatro: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,

        linkTitleUm: null,
        linkUm: null,

        percentageValue: null,
        percentageScoreCurrent: null,
        percentageGoalProps: null,
        percentageScoreGoals: null,
        percentageScoreItem: null,
      },

      cardSeis: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,

        linkTitleUm: null,
        linkUm: null,
      },

      cardSete: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,

        linkTitleUm: null,
        linkUm: null,

        linkTitleDois: null,
        linkDois: null,

        linkTitleTres: null,
        linkTres: null,

        linkTitleQuatro: null,
        linkQuatro: null,

        evaluationValueUm: null,
        evaluationValueQuatro: null,
        evaluationGoalUm: null,
        evaluationTextUm: null,
        evaluationDateLimitUm: null,
      },

      cardOito: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,

        percentageValue: null,
        percentageScoreCurrent: null,
        percentageGoalProps: null,
        percentageScoreGoals: null,
        percentageScoreItem: null,
      },

      cardNove: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,

        percentageValue: null,
        percentageScoreCurrent: null,
        percentageGoalProps: null,
        percentageScoreGoals: null,
        percentageScoreItem: null,
      },

      cardDez: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,
        
        linkTitleUm: null,
        linkUm: null,
      }
    };
  },
  watch: {
    dataCards: {
      immediate: true, 
      handler (value) {
        if (value) {
          this.dataCard = value;
          this.setDataCardUm();
          this.setDataCardDois();
          this.setDataCardTres();
          this.setDataCardQuatro();
          this.setDataCardSeis();
          this.setDataCardSete();
          this.setDataCardOito();
          this.setDataCardNove();
          this.setDataCardDez();
        }
      }
    }
  },
  methods: {
    setDataCardUm() {
      this.cardUm.cardPoints = this.dataCard?.general_data?.join_bav?.points;
      this.cardUm.cardTitle = "Adesão à estratégia Busca Ativa Vacinal (BAV)";
      this.cardUm.cardPrimaryTextFirstBlock = null;
      this.cardUm.progressUpdatedIn = "diariamente";
      this.cardUm.cardDate = "30/06/2024";

      this.cardUm.linkTitleUm = "Atividade 1";
      this.cardUm.linkUm = "https://buscaativavacinal.org.br";

      this.cardUm.linkTitleDois = "Atividade 2";
      this.cardUm.linkDois = "https://ava.unicef.org.br/course/view.php?id=35";

      this.cardUm.checkpointPointsDotUm = GeneralsUtil.valueToNumber(this.dataCard?.general_data?.join_bav?.registered);
      this.cardUm.checkpointPointsDotDois = (this.dataCard?.general_data?.join_bav?.points === 1 && this.dataCard?.general_data?.join_bav?.ava_course)
          ? 1
          : GeneralsUtil.valueToNumber(this.dataCard?.general_data?.join_bav?.ava_course);

      this.cardUm.checkpointTextDotUm = "Adesão do Prefeito à estratégia BAV realizada pela plataforma";
      this.cardUm.checkpointTextDotDois = "Gestor(a) da Política Pública Municipal e Coordenador(a) Operacional Municipal da BAV certificados nos cursos da Busca Ativa Vacinal.";

      this.cardUm.checkpointDateLimitDotUm = "30/06/2024";
      this.cardUm.checkpointDateLimitDotDois = "30/06/2024";
    },

    setDataCardDois() {
      this.cardDois.cardPoints = this.dataCard?.general_data?.first_step.points;
      this.cardDois.cardTitle = "Unidades de Educação Infantil ofertando práticas educacionais de qualidade (1ª medição - 2023)";
      this.cardDois.cardPrimaryTextFirstBlock = "Pelo menos 30% de bebês e crianças matriculadas em Unidades de Educação Infantil beneficiadas por práticas educacionais de qualidade.";
      this.cardDois.progressUpdatedIn = "diariamente";
      this.cardDois.cardDate = "30/06/2023";
      
      this.cardDois.linkTitleUm = "Abrir Atividade";
      this.cardDois.linkUm = "https://pesquisas.unicef.org.br/";

      this.cardDois.percentageValue = this.dataCard?.general_data?.first_step.percentage.toFixed(0);
      this.cardDois.percentageScoreCurrent = "946";
      this.cardDois.percentageGoalProps = 30;
      this.cardDois.percentageScoreGoals = "1.245";
      this.cardDois.percentageScoreItem = "escolas";
    },

    setDataCardTres() {
      const isSmall = this.dataCard?.general_data?.cras_assistance_services?.cras_total_count <= 5
      const cardPrimaryTextFirstBlock = isSmall
        ? 'Pelo menos 01 CRAS deve oferecer serviços de atenção à primeira Infância - municípios com até 05 CRAS (Pequeno Porte)'
        : '20% dos CRAS com serviços de atenção à primeira Infância - municípios com mais de 05 CRAS (Médio e Grande porte)';

      this.cardTres.hasPercent = !isSmall
      this.cardTres.cardPoints = this.dataCard?.general_data?.cras_assistance_services?.points;
      this.cardTres.cardTitle = "CRAS ofertando serviços de atenção à Primeira Infância (Censo SUAS)";
      this.cardTres.cardPrimaryTextFirstBlock = cardPrimaryTextFirstBlock;
      this.cardTres.progressUpdatedIn = 'diariamente';
      this.cardTres.cardDate = "12/01/2024";

      this.cardTres.linkTitleUm = null;
      this.cardTres.linkUm = null;

      this.cardTres.percentageValue = this.dataCard?.general_data?.cras_assistance_services?.percentage.toFixed(0) ?? 0;
      this.cardTres.percentageScoreCurrent = "0";
      this.cardTres.percentageGoalProps = 20;
      this.cardTres.percentageScoreGoals = "0";
      this.cardTres.percentageScoreItem = "CRAS";
    },

    setDataCardQuatro() {
      this.cardQuatro.cardPoints = this.dataCard?.general_data?.second_step.points;
      this.cardQuatro.cardTitle = "Unidades de Educação Infantil ofertando práticas educacionais de qualidade (2ª medição - 2024)";
      this.cardQuatro.cardPrimaryTextFirstBlock = "Pelo menos 30% de bebês e crianças matriculadas em Unidades de Educação Infantil beneficiadas por práticas educacionais de qualidade.";
      this.cardQuatro.progressUpdatedIn = "diariamente";
      this.cardQuatro.cardDate = "30/06/2024";

      this.cardQuatro.linkTitleUm = "Abrir Atividade";
      this.cardQuatro.linkUm = "https://pesquisas.unicef.org.br/";

      this.cardQuatro.percentageValue = this.dataCard?.general_data?.second_step.percentage.toFixed(0);
      this.cardQuatro.percentageScoreCurrent = "946";
      this.cardQuatro.percentageGoalProps = 30;
      this.cardQuatro.percentageScoreGoals = "1.245";
      this.cardQuatro.percentageScoreItem = "escolas";
    },

    setDataCardSeis() {
      this.cardSeis.cardPoints = this.dataCard?.general_data?.implemented_pmpi?.points;
      this.cardSeis.cardTitle = "Elaboração do Plano Municipal pela Primeira Infância - (PMPI) implementado";
      this.cardSeis.cardPrimaryTextFirstBlock = "Plano Municipal Pela Primeira Infância (PMPI) implementado.";
      this.cardSeis.progressUpdatedIn = "diariamente";
      this.cardSeis.cardDate = "31/03/2024";

      this.cardSeis.linkTitleUm = "Abrir Atividade";
      this.cardSeis.linkUm = "https://pesquisas.unicef.org.br/pesquisa/32";
    },

    setDataCardSete() {
      this.cardSete.cardPoints = this.dataCard?.general_data?.certified_professionals?.points;
      this.cardSete.cardTitle = "Profissionais certificados em pelo menos 1 dos 4 cursos sobre Educação Infantil";
      this.cardSete.cardPrimaryTextFirstBlock = "3 profissionais concluindo um dos 4 cursos disponíveis sobre Educação Infantil (Práticas de Qualidade na Educação Infantil; Espaços, materiais, tempos e relações de qualidade na Educação Infantil; Gestão de Qualidade na Educação Infantil; Avaliação do atendimento na Educação Infantil)";
      this.cardSete.progressUpdatedIn = 'diariamente';
      this.cardSete.cardDate = "30/06/2024";

      this.cardSete.linkTitleUm = "Curso 1";
      this.cardSete.linkUm = "https://ava.unicef.org.br/course/view.php?id=31";

      this.cardSete.linkTitleDois = "Curso 2";
      this.cardSete.linkDois = "https://ava.unicef.org.br/course/view.php?id=30";

      this.cardSete.linkTitleTres = "Curso 3";
      this.cardSete.linkTres = "https://ava.unicef.org.br/course/view.php?id=25";

      this.cardSete.linkTitleQuatro = "Curso 4";
      this.cardSete.linkQuatro = "https://ava.unicef.org.br/course/view.php?id=22";

      this.cardSete.evaluationValueUm = this.dataCard?.general_data?.certified_professionals?.total ?? 0;
      this.cardSete.evaluationValueQuatro = 0; // @to-do: Aplicar quando receber do endpoint.

      this.cardSete.evaluationGoalUm = 3;

      this.cardSete.evaluationTextUm = "Certificados";

      this.cardSete.evaluationDateLimitUm = "30/06/2024";
    },

    setDataCardOito() {
      this.cardOito.cardPoints = this.dataCard?.general_data?.first_indicate.points;
      this.cardOito.cardTitle = "INDIQUE: autoavaliação da qualidade das Unidades de Educação Infantil (1ª medição - 2023)";
      this.cardOito.cardPrimaryTextFirstBlock = "Realizar a primeira autoavaliação da qualidade da Educação Infantil - INDIQUE.";
      this.cardOito.progressUpdatedIn = "diariamente";
      this.cardOito.cardDate = "30/06/2023";

      this.cardOito.linkTitleUm = "Abrir Atividade";
      this.cardOito.linkUm = "https://pesquisas.unicef.org.br/";

      this.cardOito.percentageValue = this.dataCard?.general_data?.first_indicate.percentage.toFixed(0);
      this.cardOito.percentageScoreCurrent = "0"; // @to-do: Aplicar quando receber do endpoint.
      this.cardOito.percentageGoalProps = 30;
      this.cardOito.percentageScoreGoals = "1.000";
      this.cardOito.percentageScoreItem = "crianças";
    },

    setDataCardNove() {
      this.cardNove.cardPoints = this.dataCard?.general_data?.second_indicate.points;
      this.cardNove.cardTitle = "INDIQUE: autoavaliação da qualidade das Unidades de Educação Infantil (2ª medição - 2024)";
      this.cardNove.cardPrimaryTextFirstBlock = "Realizar a segunda autoavaliação da qualidade da Educação Infantil - INDIQUE.";
      this.cardNove.progressUpdatedIn = 'diariamente';
      this.cardNove.cardDate = "30/06/2024";

      this.cardNove.linkTitleUm = "Abrir Atividade";
      this.cardNove.linkUm = "https://pesquisas.unicef.org.br/";

      this.cardNove.percentageValue = this.dataCard?.general_data?.second_indicate.percentage.toFixed(0);
      this.cardNove.percentageScoreCurrent = "0"; // @to-do: Aplicar quando receber do endpoint.
      this.cardNove.percentageGoalProps = 30;
      this.cardNove.percentageScoreGoals = "1.000";
      this.cardNove.percentageScoreItem = "crianças";
    },

    setDataCardDez() {
      this.cardDez.cardPoints = this.dataCard?.general_data?.municipal_plan_for_early_childhood.points;
      this.cardDez.cardTitle = "Elaboração do Plano Municipal pela Primeira Infância - (PMPI)";
      this.cardDez.cardPrimaryTextFirstBlock = "PMPI elaborado e publicado na Plataforma Crescendo Juntos (PCJ) pelo(a) articulador(a) do Selo UNICEF.";
      this.cardDez.progressUpdatedIn ="diariamente";
      this.cardDez.cardDate = "31/03/2023";

      this.cardDez.linkTitleUm = "Abrir Atividade";
      this.cardDez.linkUm = "https://classroom.google.com/";
    }
  }
};
</script>

<style lang="sass">
.atividades-relacionadas-page
  margin: 3rem auto
  max-width: var(--max-width)

.atividades-relacionadas-title
  border-bottom: 3px solid #1cabe2
  color: #1cabe2
  display: inline-block
  font-size: 20px
  font-weight: 500

.cards
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  margin-top: 32px
  @media screen and (max-width: 1008px)
    display: flex
    justify-content: center
</style>
