<template>
  <div class="pagina-nao-encontrada">
    <h1 class="pagina-nao-encontrada-title">Página não encontrada</h1>

    <div>
      <p class="">A página solicitada não pôde ser encontrada.</p>
      <p class="">
        Verifique o endereço digitado ou se a página ainda se encontra
        disponível.
      </p>
    </div>

    <div class="erro">
      <p class="">Erro: 404 not found.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginaNaoEncontrada",
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="sass" scoped>
.pagina-nao-encontrada
  color: #4a4a4a
  margin: auto
  margin-top: 2rem
  max-width: var(--max-width)
  min-height: 50rem
  padding: 64px

.pagina-nao-encontrada-title
  font-weight: 500
  margin-bottom: 3rem

.erro
  font-size: 1.5rem
  margin-top: 5rem
</style>
