<template>
  <div class="atividades-relacionadas-page">
    <div class="atividades-relacionadas">
      <h1 class="atividades-relacionadas-title">Atividades relacionadas</h1>

      <div class="cards">
         <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardUm.cardPoints"
          :cardTitle="cardUm.cardTitle"
          :cardPrimaryTextFirstBlock="cardUm.cardPrimaryTextFirstBlock"
          :cardDate="cardUm.cardDate"

          :linkTitleUmCard="cardUm.linkTitleUm"
          :linkUmCard="cardUm.linkUm"
        />

        <CardResultadoSistemico
          :percentCardType="true"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardDois.cardPoints"
          :cardTitle="cardDois.cardTitle"
          :cardPrimaryTextFirstBlock="cardDois.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardDois.progressUpdatedIn"
          :cardDate="cardDois.cardDate"

          :linkTitleUmCard="cardDois.linkTitleUm"
          :linkUmCard="cardDois.linkUm"

          :percentageValue="cardDois.percentageValue"
          :percentageScoreCurrent="cardDois.percentageScoreCurrent"
          :percentageGoalProps="cardDois.percentageGoalProps"
          :percentageScoreGoals="cardDois.percentageScoreGoals"
          :percentageScoreItem="cardDois.percentageScoreItem"
        />

        <CardResultadoSistemico
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardTres.cardPoints"
          :cardTitle="cardTres.cardTitle"
          :cardPrimaryTextFirstBlock="cardTres.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardTres.progressUpdatedIn"
          :cardDate="cardTres.cardDate"

          :linkTitleUmCard="cardTres.linkTitleUm"
          :linkUmCard="cardTres.linkUm"
        />

        <CardResultadoSistemico
          :percentCardType="true"
          :evaluationsCardType="false"
          :checkpointsCardType="false"

          :cardPoints="cardQuatro.cardPoints"
          :cardTitle="cardQuatro.cardTitle"
          :cardPrimaryTextFirstBlock="cardQuatro.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardQuatro.progressUpdatedIn"
          :cardDate="cardQuatro.cardDate"

          :linkTitleUmCard="cardQuatro.linkTitleUm"
          :linkUmCard="cardQuatro.linkUm"

          :percentageValue="cardQuatro.percentageValue"
          :percentageScoreCurrent="cardQuatro.percentageScoreCurrent"
          :percentageGoalProps="cardQuatro.percentageGoalProps"
          :percentageScoreGoals="cardQuatro.percentageScoreGoals"
          :percentageScoreItem="cardQuatro.percentageScoreItem"
        />

        <CardResultadoSistemico
          :percentCardType="false"
          :evaluationsCardType="true"
          :checkpointsCardType="false"

          :cardPoints="cardCinco.cardPoints"
          :cardTitle="cardCinco.cardTitle"
          :cardPrimaryTextFirstBlock="cardCinco.cardPrimaryTextFirstBlock"
          :progressUpdatedIn="cardCinco.progressUpdatedIn"
          :cardDate="cardCinco.cardDate"

          :linkTitleUmCard="cardCinco.linkTitleUm"
          :linkUm="cardCinco.linkUm"

          :linkTitleDoisCard="cardCinco.linkTitleDois"
          :linkDois="cardCinco.linkDois"

          :linkTitleTresCard="cardCinco.linkTitleTres"
          :linkTres="cardCinco.linkTres"

          :linkTitleQuatroCard="cardCinco.linkTitleQuatro"
          :linkQuatro="cardCinco.linkQuatro"

          :linkTitleCincoCard="cardCinco.linkTitleCinco"
          :linkCinco="cardCinco.linkCinco"

          :evaluationValueUm="cardCinco.evaluationValueUm"
          :evaluationValueQuatro="cardCinco.evaluationValueQuatro"
          :evaluationGoalUm="cardCinco.evaluationGoalUm"
          :evaluationTextUm="cardCinco.evaluationTextUm"
          :evaluationDateLimitUm="cardCinco.evaluationDateLimitUm"
        />
      </div>

      <div>
        <DotsLegends />
      </div>
    </div>
  </div>
</template>

<script>
import CardResultadoSistemico from "./resultados-sistemicos/CardResultadoSistemico.vue"
import DotsLegends from "./dots-legends.vue"

export default {
  name: "AtividadesRelacionadasRS02",
  props: ["dataCards"],
  components: { CardResultadoSistemico, DotsLegends },
  data() {
    return {
      dataCard: null,

      cardUm: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,

        linkTitleUm: null,
        linkUm: null,

        percentageValue: null,
        percentageScoreCurrent: null,
        percentageGoalProps: null,
        percentageScoreGoals: null,
        percentageScoreItem: null,
      },

      cardDois: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,

        linkTitleUm: null,
        linkUm: null,

        percentageValue: null,
        percentageScoreCurrent: null,
        percentageGoalProps: null,
        percentageScoreGoals: null,
        percentageScoreItem: null,
      },

      cardTres: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,

        linkTitleUm: null,
        linkUm: null,

        percentageValue: null,
        percentageScoreCurrent: null,
        percentageGoalProps: null,
        percentageScoreGoals: null,
        percentageScoreItem: null,
      },

      cardQuatro: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,

        linkTitleUm: null,
        linkUm: null,

        percentageValue: null,
        percentageScoreCurrent: null,
        percentageGoalProps: null,
        percentageScoreGoals: null,
        percentageScoreItem: null,
      },

      cardCinco: {
        cardPoints: null,
        cardTitle: null,
        cardPrimaryTextFirstBlock: null,
        progressUpdatedIn: null,
        cardDate: null,

        linkTitleUm: null,
        linkUm: null,

        linkTitleDois: null,
        linkDois: null,

        linkTitleTres: null,
        linkTres: null,

        linkTitleQuatro: null,
        linkQuatro: null,

        linkTitleCinco: null,
        linkCinco: null,


        evaluationValueQuatro: null,
        evaluationValueUm: null,
        evaluationGoalUm: null,
        evaluationTextUm: null,
        evaluationDateLimitUm: null,
      }
    };
  },
  watch: {
    dataCards: {
      immediate: true,
      handler (value) {
        if (value) {
          this.dataCard = value;
          this.setDataCardUm();
          this.setDataCardDois();
          this.setDataCardTres();
          this.setDataCardQuatro();
          this.setDataCardCinco();
        }
      }
    }
  },
  methods: {
    setDataCardUm() {
      this.cardUm.cardPoints = this.dataCard?.general_data?.census_2019.points;
      this.cardUm.cardTitle = "Alcance das metas de rematrícula por meio da estratégia Busca Ativa Escolar (BAE) (1ª medição - 2023)";
      this.cardUm.cardPrimaryTextFirstBlock = "Rematrícular o equivalente a 40% do número de crianças e adolescentes da Educação Básica (Rede Pública) em situação de abandono escolar no Censo Escolar em 2019.";
      this.cardUm.progressUpdatedIn = "diariamente";
      this.cardUm.cardDate = "06/06/2023";

      this.cardUm.linkTitleUm = "Abrir Atividade";
      this.cardUm.linkUm = "https://plataforma.buscaativaescolar.org.br/";
    },

    setDataCardDois() {
      this.cardDois.cardPoints = this.dataCard?.general_data?.first_step.points;
      this.cardDois.cardTitle = "Realização de práticas de enfrentamento à cultura do fracasso escolar no Ensino Fundamental (1ª medição - 2023)";
      this.cardDois.cardPrimaryTextFirstBlock = "Beneficiar pelo menos 30% das crianças e adolescentes matriculadas no Ensino Fundamental da Rede Municipal, conforme os dados do Censo Escolar 2020, com ações de desenvolvimento de competências e habilidades da BNCC.";
      this.cardDois.progressUpdatedIn = "diariamente";
      this.cardDois.cardDate = "30/06/2023";

      this.cardDois.linkTitleUm = "Abrir Atividade";
      this.cardDois.linkUm = "https://pesquisas.unicef.org.br/";

      this.cardDois.percentageValue = this.dataCard?.general_data?.first_step.percentage.toFixed(0);
      this.cardDois.percentageScoreCurrent = null;
      this.cardDois.percentageGoalProps = 30;
      this.cardDois.percentageScoreGoals = null;
      this.cardDois.percentageScoreItem = null;
    },

    setDataCardTres() {
      this.cardTres.cardPoints = this.dataCard?.general_data?.census_2022.points;
      this.cardTres.cardTitle = "Alcance das metas de rematrícula por meio da estratégia Busca Ativa Escolar (BAE) (2ª medição - 2024)";
      this.cardTres.cardPrimaryTextFirstBlock = "Rematrícular o equivalente a 40% do número de crianças e adolescentes da Educação Básica (Rede Pública) em situação de abandono escolar no Censo Escolar em 2022.";
      this.cardTres.progressUpdatedIn = "diariamente";
      this.cardTres.cardDate = "30/06/2024";

      this.cardTres.linkTitleUm = "Abrir Atividade";
      this.cardTres.linkUm = "https://plataforma.buscaativaescolar.org.br/";
    },

    setDataCardQuatro() {
      this.cardQuatro.cardPoints = this.dataCard?.general_data?.second_step?.points;
      this.cardQuatro.cardTitle = "Realização de práticas de enfrentamento à cultura do fracasso escolar no Ensino Fundamental (2ª medição - 2024)";
      this.cardQuatro.cardPrimaryTextFirstBlock = "Beneficiar pelo menos 30% das crianças e adolescentes matriculadas no Ensino Fundamental da Rede Municipal, conforme os dados do Censo Escolar 2022, com ações de desenvolvimento de competências e habilidades da BNCC.";
      this.cardQuatro.progressUpdatedIn = 'diariamente';
      this.cardQuatro.cardDate = "30/06/2024";

      this.cardQuatro.linkTitleUm = "Abrir Atividade";
      this.cardQuatro.linkUm = "https://pesquisas.unicef.org.br/";

      this.cardQuatro.percentageValue = this.dataCard?.general_data?.second_step?.percentage?.toFixed(0) ?? 0;
      this.cardQuatro.percentageScoreCurrent = null;
      this.cardQuatro.percentageGoalProps = 30;
      this.cardQuatro.percentageScoreGoals = null;
      this.cardQuatro.percentageScoreItem = "null";
    },

    setDataCardCinco() {
      this.cardCinco.cardPoints = this.dataCard?.general_data?.certified_professionals.points;
      this.cardCinco.cardTitle = "Profissionais certificados em pelo menos 1 dos 5 cursos sobre Ensino Fundamental";
      this.cardCinco.cardPrimaryTextFirstBlock = "3 profissionais concluindo um dos 5 cursos disponíveis sobre Ensino Fundamental (Práticas de Alfabetização em Contexto de Multiletramentos; Gestão Escolar e Professores na Rede; Gestão Educacional na Rede; Currículo Integrado; Desenvolvimento de Competência para o Enfrentamento da Cultura do Fracasso Escolar).";
      this.cardCinco.progressUpdatedIn = 'diariamente';
      this.cardCinco.cardDate = "30/06/2024";

      this.cardCinco.linkTitleUm = "Curso 1";
      this.cardCinco.linkUm = "https://ava.unicef.org.br/course/view.php?id=24";

      this.cardCinco.linkTitleDois = "Curso 2";
      this.cardCinco.linkDois = "https://ava.unicef.org.br/course/view.php?id=23";

      this.cardCinco.linkTitleTres = "Curso 3";
      this.cardCinco.linkTres = "https://ava.unicef.org.br/course/view.php?id=18";

      this.cardCinco.linkTitleQuatro = "Curso 4";
      this.cardCinco.linkQuatro = "https://ava.unicef.org.br/course/view.php?id=91";

      this.cardCinco.linkTitleCinco = "Curso 5";
      this.cardCinco.linkCinco = "https://ava.unicef.org.br/course/view.php?id=45";

      this.cardCinco.evaluationValueQuatro = 0;
      this.cardCinco.evaluationValueUm = this.dataCard?.general_data?.certified_professionals.total;
      this.cardCinco.evaluationGoalUm = 3;
      this.cardCinco.evaluationTextUm = "Certificados";
      this.cardCinco.evaluationDateLimitUm = "30/06/2024";
    }
  }
};
</script>

<style lang="sass">
.atividades-relacionadas-page
  margin: 3rem auto
  max-width: var(--max-width)

.atividades-relacionadas-title
  border-bottom: 3px solid #1cabe2
  color: #1cabe2
  display: inline-block
  font-size: 20px
  font-weight: 500

.cards
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  margin-top: 32px
  @media screen and (max-width: 1008px)
    display: flex
    justify-content: center
</style>
